import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddAddressTypeMaster,
  actionAddAreaMaster,
  actionAddBeatGradeMaster,
  actionAddBeatMaster,
  actionAddBusinessPartnerTypeMaster,
  actionAddDepartment,
  actionAddExpenseCategoryMaster,
  actionAddIndustryMaster,
  actionAddLeadRatingMaster,
  actionAddLeadSourceMaster,
  actionAddLeadStatusMaster,
  actionAddMaterialGroupMaster,
  actionAddOpportunityStageMaster,
  actionGetZoneMaster,
  actionAddPaymentMethodMaster,
  actionAddTaskStatusMaster,
  actionAddTypeOfCompanyMaster,
  actionAddZoneMaster,
  actionDeleteAddressTypeMaster,
  actionDeleteAreaMaster,
  actionDeleteBeatGradeMaster,
  actionDeleteBeatMaster,
  actionDeleteBusinessPartnerTypeMaster,
  actionDeleteDepartment,
  actionDeleteExpenseCategoryMaster,
  actionDeleteIndustryMaster,
  actionDeleteLeadRatingMaster,
  actionDeleteLeadSourceMaster,
  actionDeleteLeadStatusMaster,
  actionDeleteMaterialGroupMaster,
  actionDeleteOpportunityStageMaster,
  actionDeletePaymentMethodMaster,
  actionDeleteTaskStatusMaster,
  actionDeleteTypeOfCompanyMaster,
  actionDeleteZoneMaster,
  actionEditAddressTypeMaster,
  actionEditAreaMasterMaster,
  actionEditBeatGradeMaster,
  actionEditBeatMaster,
  actionEditBusinessPartnerTypeMaster,
  actionEditDepartment,
  actionEditExpenseCategoryMaster,
  actionEditIndustryMaster,
  actionEditLeadRatingMaster,
  actionEditLeadSourceMaster,
  actionEditLeadStatusMaster,
  actionEditMaterialGroupMaster,
  actionEditOpportunityStageMaster,
  actionEditPaymentMethodMaster,
  actionEditTaskStatusMaster,
  actionEditTypeOfCompanyMaster,
  actionEditUnitConversionMaster,
  actionEditZoneMasterMaster,
  actionGetAddressTypeMaster,
  actionGetAreaMaster,
  actionGetBeatGradeMaster,
  actionGetBeatMaster,
  actionGetBusinessPartnerTypeMaster,
  actionGetExchangeCurrencyMaster,
  actionGetDepartment,
  actionGetExpenseCategoryMaster,
  actionGetIndustryMaster,
  actionGetLeadRatingMaster,
  actionGetLeadSourceMaster,
  actionGetLeadStatusMaster,
  actionGetMaterialGroupMaster,
  actionGetOpportunityStageMaster,
  actionGetPaymentMethodMaster,
  actionGetSingleAddressTypeMaster,
  actionGetSingleAreaMaster,
  actionGetSingleBeatGradeMaster,
  actionGetSingleBeatMaster,
  actionGetSingleBusinessPartnerTypeMaster,
  actionGetSingleDepartment,
  actionGetSingleExpenseCategoryMaster,
  actionGetSingleIndustryMaster,
  actionGetSingleLeadRatingMaster,
  actionGetSingleLeadSourceMaster,
  actionGetSingleLeadStatusMaster,
  actionGetSingleMaterialGroupMaster,
  actionGetSingleOpportunityStageMaster,
  actionGetSinglePaymentMethodMaster,
  actionGetSingleTypeOfCompanyMaster,
  actionGetSingleUnitConversionMaster,
  actionGetSingleZoneMaster,
  actionGetTaskStatusMaster,
  actionGetTaskStatusMasterDetail,
  actionGetTypeOfCompanyMaster,
  actionGetUnitConversionMaster,
  actionAddUnitConversionMaster,
  actionDeleteUnitConversionMaster,
  //exchange currency
  actionGetExchageCurrencyMaster,
  actionSyncExchangeCurrencyMaster,
  actionGetSingleExchangeCurrencyMaster,
  actionDeleteExchangeCurrencyMaster,
  actionEditExchangeCurrencyMaster,
  actionGetAllExchageCurrencyMaster,
} from "../services/generalMasterService";

const generalMastersSlice = createSlice({
  name: "lead",
  initialState: {
    //TODO: Lead Status
    getLeadStatusLoader: false,
    getSingleLeadStatusLoader: false,
    addLeadStatusLoader: false,
    getSingleLeadStatusData: false,
    deleteLeadStatusLoader: {},
    updateLeadStatusLoader: {},
    leadStatusData: [],

    //TODO: lead Source
    addLeadSourceLoader: false,
    getSingleLeadSourceLoader: false,
    getLeadSourceLoader: false,
    getSingleLeadSourceData: false,
    deleteLeadSourceLoader: {},
    updateLeadSourceLoader: {},
    leadSourceData: [],

    //TODO: lead rating
    getLeadRatingLoader: false,
    getSingleLeadRatingLoader: false,
    getSingleLeadRatingData: false,
    addLeadRatingLoader: false,
    deleteLeadRatingLoader: {},
    updateLeadRatingLoader: {},
    leadRatingData: [],

    //TODO: industry
    getIndustryLoader: false,
    addIndustryLoader: false,
    getIndustryDetailLoader: false,
    getIndustryDetail: false,
    deleteIndustryMasterLoader: false,
    updateIndustryMasterLoader: {},
    industryData: [],

    getOpportunityStageLoader: false,
    opportunityStageData: [],
    addOpportunityStageLoader: false,
    deleteOpportunityStageLoader: false,
    updateOpportunityStageLoader: {},
    getOpportunityStageDetailLoader: {},
    opportunityStageDetail: "",

    getBusinessPartnerTypeLoader: false,
    addBusinessPartnerTypeLoader: false,
    getBusinessPartnerTypeDetailLoader: false,
    deleteBusinessPartnerTypeLoader: false,
    updateBusinessPartnerTypeMasterLoader: {},
    businessPartnerTypeData: [],
    businessPartnerTypeDetail: "",

    getBeatGradeMasterLoader: false,
    addBeatGradeMasterLoader: false,
    getBeatGradeMasterDetailLoader: false,
    getSingleBeatGradeData: false,
    deleteBeatGradeMasterLoader: false,
    updateBeatGradeMasterLoader: {},
    beatGradeMasterData: [],
    beatGradeMasterDetail: "",

    //TODO: Department
    getDepartmentLoader: false,
    departmentData: [],
    addDepartmentLoader: false,
    getDepartmentDetailLoader: false,
    departmentDetail: "",
    deleteDepartmentLoader: false,
    updateDepartmentLoader: {},

    //TODO:Type of company
    getTypeOfCompanyLoader: false,
    addTypeOfCompanyLoader: false,
    deleteTypeOfCompanyLoader: false,
    getSingleTypeOfCompanyData: false,
    getSingleTypeOfCompanyLoader: false,
    updateTypeOfCompanyLoader: {},
    typeOfCompanyData: [],
    //TODO:beat master
    getBeatMasterLoader: false,
    addBeatMasterLoader: false,
    deleteBeatMasterLoader: false,
    getSingleBeatMasterData: false,
    getSingleBeatMasterLoader: false,
    updateBeatMasterLoader: {},
    BeatMasterData: [],
    //TODO:zone
    getZoneMasterLoader: false,
    addZoneMasterLoader: false,
    getSingleZoneMasterLoader: false,
    getSingleZoneMasterData: false,
    deleteZoneMasterLoader: {},
    updateZoneMasterLoader: {},
    zoneMasterData: [],
    //TODO:area
    getAreaMasterLoader: false,
    addAreaMasterLoader: false,
    getSingleAreaMasterData: {},
    deleteAreaMasterLoader: false,
    getSingleAreaMasterLoader: {},
    updateAreaMasterLoader: {},
    areaMasterData: [],

    // task status
    getTaskStatusMasterLoader: false,
    taskStatusMasterData: [],
    addTaskStatusMasterLoader: false,
    taskStatusMasterDetail: {},
    deleteTaskStatusMasterLoader: {},
    getTaskStatusMasterDetailLoader: {},
    updateTaskStatusMasterLoader: {},

    //material group
    getMaterialGroupLoader: false,
    addMaterialGroupMasterLoader: false,
    getSingleMaterialGroupMasterData: {},
    deleteMaterialGroupMasterLoader: {},
    getSingleMaterialGroupMasterLoader: {},
    updateMaterialGroupMasterLoader: {},
    materialGroupMasterData: [],

    //payment method
    getPaymentMethodLoader: false,
    addPaymentMethodMasterLoader: false,
    getSinglePaymentMethodMasterData: {},
    deletePaymentMethodMasterLoader: {},
    getSinglePaymentMethodMasterLoader: {},
    updatePaymentMethodMasterLoader: {},
    paymentMethodMasterData: [],

    //Address type
    getAddressTypeLoader: false,
    addAddressTypeMasterLoader: false,
    getSingleAddressTypeMasterData: {},
    deleteAddressTypeMasterLoader: {},
    getSingleAddressTypeMasterLoader: {},
    updateAddressTypeMasterLoader: {},
    addressTypeMasterData: [],

    //Expense categor
    getExpenseCategoryLoader: false,
    addExpenseCategoryMasterLoader: false,
    getSingleExpenseCategoryMasterData: {},
    deleteExpenseCategoryMasterLoader: {},
    getSingleExpenseCategoryMasterLoader: {},
    updateExpenseCategoryMasterLoader: {},
    expenseCategoryMasterData: [],

    //unit conversaion
    getUnitConversionLoader: false,
    addUnitConversionMasterLoader: false,
    getSingleUnitConversionMasterData: {},
    deleteUnitConversionMasterLoader: {},
    getSingleUnitConversionMasterLoader: {},
    updateUnitConversionMasterLoader: false,
    unitConversionMasterData: [],

    //exchange currency
    getExchangeCurrencyLoader: false,
    syncExchangeCurrencyMasterLoader: false,
    getSingleExchangeCurrencyMasterData: {},
    deleteExchangeCurrencyMasterLoader: {},
    getSingleExchangeCurrencyMasterLoader: {},
    updateExchangeCurrencyMasterLoader: {},
    exchangeCurrencyMasterData: [],
    exchangeCurrencyMasterDataCount: 0,

    allCurrencyMasterData: [],
    getAllExchangeCurrencyMasterLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //TODO: Leads Masters Ends Here
      // lead status start here
      // get lead status
      .addCase(actionGetLeadStatusMaster.pending, (state) => {
        state.getLeadStatusLoader = true;
      })
      .addCase(actionGetLeadStatusMaster.fulfilled, (state, action) => {
        state.getLeadStatusLoader = false;
        state.leadStatusData = action.payload.data;
      })
      .addCase(actionGetLeadStatusMaster.rejected, (state) => {
        state.getLeadStatusLoader = false;
      })

      //get single lead status
      .addCase(actionGetSingleLeadStatusMaster.pending, (state) => {
        state.getSingleLeadStatusLoader = true;
      })
      .addCase(actionGetSingleLeadStatusMaster.fulfilled, (state, action) => {
        state.getSingleLeadStatusData = action.payload.data;
        state.getSingleLeadStatusLoader = false;
      })
      .addCase(actionGetSingleLeadStatusMaster.rejected, (state) => {
        state.getSingleLeadStatusLoader = false;
      })

      // add lead status
      .addCase(actionAddLeadStatusMaster.pending, (state) => {
        state.addLeadStatusLoader = true;
      })
      .addCase(actionAddLeadStatusMaster.fulfilled, (state, action) => {
        state.addLeadStatusLoader = false;
        if (action.payload) {
          if (!state.leadStatusData || !Array.isArray(state.leadStatusData)) {
            state.leadStatusData = [action.payload];
          } else {
            state.leadStatusData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddLeadStatusMaster.rejected, (state) => {
        state.addLeadStatusLoader = false;
      })

      //delete lead status
      .addCase(actionDeleteLeadStatusMaster.pending, (state, action) => {
        state.deleteLeadStatusLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteLeadStatusMaster.fulfilled, (state, action) => {
        state.leadStatusData = state.leadStatusData.filter(
          (type) => type.lead_status_master_id !== action.payload
        );
        state.deleteLeadStatusLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteLeadStatusMaster.rejected, (state, action) => {
        state.deleteLeadStatusLoader[action.meta.arg.id] = false;
      })

      //update lead status
      .addCase(actionEditLeadStatusMaster.pending, (state, action) => {
        state.updateLeadStatusLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditLeadStatusMaster.fulfilled, (state, action) => {
        state.updateLeadStatusLoader[action.meta.arg.id] = false;
        state.leadStatusData = state.leadStatusData.map((type) =>
          type.lead_status_master_id === action.payload.lead_status_master_id
            ? {
                ...type,
                lead_status: action.payload.lead_status,
                // description: action.payload.description,
              }
            : type
        );
      })
      .addCase(actionEditLeadStatusMaster.rejected, (state, action) => {
        state.updateLeadStatusLoader[action.meta.arg.id] = false;
      })
      // lead status ends here

      // lead source start here
      // get lead source
      .addCase(actionGetLeadSourceMaster.pending, (state) => {
        state.getLeadSourceLoader = true;
      })
      .addCase(actionGetLeadSourceMaster.fulfilled, (state, action) => {
        state.getLeadSourceLoader = false;
        state.leadSourceData = action.payload.data;
      })
      .addCase(actionGetLeadSourceMaster.rejected, (state) => {
        state.getLeadSourceLoader = false;
      })

      //get single lead source
      .addCase(actionGetSingleLeadSourceMaster.pending, (state) => {
        state.getSingleLeadSourceLoader = true;
      })
      .addCase(actionGetSingleLeadSourceMaster.fulfilled, (state, action) => {
        state.getSingleLeadSourceData = action.payload.data;
        state.getSingleLeadSourceLoader = false;
      })
      .addCase(actionGetSingleLeadSourceMaster.rejected, (state) => {
        state.getSingleLeadSourceLoader = false;
      })

      // add lead source
      .addCase(actionAddLeadSourceMaster.pending, (state) => {
        state.addLeadSourceLoader = true;
      })
      .addCase(actionAddLeadSourceMaster.fulfilled, (state, action) => {
        state.addLeadSourceLoader = false;
        if (action.payload) {
          if (!state.leadSourceData || !Array.isArray(state.leadSourceData)) {
            state.leadSourceData = [action.payload];
          } else {
            state.leadSourceData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddLeadSourceMaster.rejected, (state) => {
        state.addLeadSourceLoader = false;
      })

      //delete lead source
      .addCase(actionDeleteLeadSourceMaster.pending, (state, action) => {
        state.deleteLeadSourceLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteLeadSourceMaster.fulfilled, (state, action) => {
        state.leadSourceData = state.leadSourceData.filter(
          (type) => type.lead_source_master_id !== action.payload
        );
        state.deleteLeadSourceLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteLeadSourceMaster.rejected, (state, action) => {
        state.deleteLeadSourceLoader[action.meta.arg.id] = false;
      })

      //update lead source
      .addCase(actionEditLeadSourceMaster.pending, (state, action) => {
        state.updateLeadSourceLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditLeadSourceMaster.fulfilled, (state, action) => {
        state.updateLeadSourceLoader[action.meta.arg.id] = false;
        state.leadSourceData = state.leadSourceData.map((type) =>
          type.lead_source_master_id === action.payload.lead_source_master_id
            ? {
                ...type,
                lead_source: action.payload.lead_source,
              }
            : type
        );
      })
      .addCase(actionEditLeadSourceMaster.rejected, (state, action) => {
        state.updateLeadSourceLoader[action.meta.arg.id] = false;
      })
      // lead source ends here

      // lead rating  start here
      .addCase(actionGetLeadRatingMaster.pending, (state) => {
        state.getLeadRatingLoader = true;
      })
      .addCase(actionGetLeadRatingMaster.fulfilled, (state, action) => {
        state.getLeadRatingLoader = false;
        state.leadRatingData = action.payload.data;
      })
      .addCase(actionGetLeadRatingMaster.rejected, (state) => {
        state.getLeadRatingLoader = false;
      })

      // add lead rating
      .addCase(actionAddLeadRatingMaster.pending, (state) => {
        state.addLeadRatingLoader = true;
      })
      .addCase(actionAddLeadRatingMaster.fulfilled, (state, action) => {
        state.addLeadRatingLoader = false;
        if (action.payload) {
          if (!state.leadRatingData || !Array.isArray(state.leadRatingData)) {
            state.leadRatingData = [action.payload];
          } else {
            state.leadRatingData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddLeadRatingMaster.rejected, (state) => {
        state.addLeadRatingLoader = false;
      })
      // lead rating  ends here

      // industry  start here
      .addCase(actionGetIndustryMaster.pending, (state) => {
        state.getIndustryLoader = true;
      })
      .addCase(actionGetIndustryMaster.fulfilled, (state, action) => {
        state.getIndustryLoader = false;
        state.industryData = action.payload.data;
      })
      .addCase(actionGetIndustryMaster.rejected, (state) => {
        state.getIndustryLoader = false;
      })

      // add lead industry
      .addCase(actionAddIndustryMaster.pending, (state) => {
        state.addIndustryLoader = true;
      })
      .addCase(actionAddIndustryMaster.fulfilled, (state, action) => {
        state.addIndustryLoader = false;
        if (action.payload) {
          if (!state.industryData || !Array.isArray(state.industryData)) {
            state.industryData = [action.payload];
          } else {
            state.industryData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddIndustryMaster.rejected, (state) => {
        state.addIndustryLoader = false;
      })
      // industry ends here

      // Get single lead rating
      .addCase(actionGetSingleLeadRatingMaster.pending, (state) => {
        state.getSingleLeadRatingLoader = true;
      })
      .addCase(actionGetSingleLeadRatingMaster.fulfilled, (state, action) => {
        state.getSingleLeadRatingData = action.payload.data;
        state.getSingleLeadRatingLoader = false;
      })
      .addCase(actionGetSingleLeadRatingMaster.rejected, (state) => {
        state.getSingleLeadRatingLoader = false;
      })

      // Delete lead rating
      .addCase(actionDeleteLeadRatingMaster.pending, (state, action) => {
        state.deleteLeadRatingLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteLeadRatingMaster.fulfilled, (state, action) => {
        state.leadRatingData = state.leadRatingData.filter(
          (type) => type.lead_rating_master_id !== action.payload
        );
        state.deleteLeadRatingLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteLeadRatingMaster.rejected, (state, action) => {
        state.deleteLeadRatingLoader[action.meta.arg.id] = false;
      })

      // Update lead rating
      .addCase(actionEditLeadRatingMaster.pending, (state, action) => {
        state.updateLeadRatingLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditLeadRatingMaster.fulfilled, (state, action) => {
        state.updateLeadRatingLoader[action.meta.arg.id] = false;
        state.leadRatingData = state.leadRatingData.map((type) =>
          type.lead_rating_master_id === action.payload.lead_rating_master_id
            ? {
                ...type,
                lead_rating: action.payload.lead_rating,
              }
            : type
        );
      })
      .addCase(actionEditLeadRatingMaster.rejected, (state, action) => {
        state.updateLeadRatingLoader[action.meta.arg.id] = false;
      })
      // industry ends here

      // get Opportunity stage
      .addCase(actionGetOpportunityStageMaster.pending, (state) => {
        state.getOpportunityStageLoader = true;
      })
      .addCase(actionGetOpportunityStageMaster.fulfilled, (state, action) => {
        state.getOpportunityStageLoader = false;
        state.opportunityStageData = action.payload.data;
      })
      .addCase(actionGetOpportunityStageMaster.rejected, (state) => {
        state.getOpportunityStageLoader = false;
      })

      // add  Opportunity stage
      .addCase(actionAddOpportunityStageMaster.pending, (state) => {
        state.addOpportunityStageLoader = true;
      })
      .addCase(actionAddOpportunityStageMaster.fulfilled, (state, action) => {
        state.addOpportunityStageLoader = false;
        if (action.payload) {
          if (
            !state.opportunityStageData ||
            !Array.isArray(state.opportunityStageData)
          ) {
            state.opportunityStageData = [action.payload];
          } else {
            state.opportunityStageData = [
              action.payload,
              ...state.opportunityStageData,
            ];
          }
        }
      })
      .addCase(actionAddOpportunityStageMaster.rejected, (state) => {
        state.addOpportunityStageLoader = false;
      })

      // get single Opportunity stage
      .addCase(actionGetSingleOpportunityStageMaster.pending, (state) => {
        state.getOpportunityStageDetailLoader = true;
      })
      .addCase(
        actionGetSingleOpportunityStageMaster.fulfilled,
        (state, action) => {
          state.getOpportunityStageDetailLoader = false;
          state.opportunityStageDetail = action.payload.data;
        }
      )
      .addCase(actionGetSingleOpportunityStageMaster.rejected, (state) => {
        state.getOpportunityStageDetailLoader = false;
      })

      //delete Opportunity Stage
      .addCase(actionDeleteOpportunityStageMaster.pending, (state, action) => {
        state.deleteOpportunityStageLoader = true;
      })
      .addCase(
        actionDeleteOpportunityStageMaster.fulfilled,
        (state, action) => {
          state.opportunityStageData = state.opportunityStageData.filter(
            (type) => type.opportunity_stage_master_id !== action.payload
          );
          state.deleteOpportunityStageLoader = false;
        }
      )
      .addCase(actionDeleteOpportunityStageMaster.rejected, (state, action) => {
        state.deleteOpportunityStageLoader = false;
      })

      .addCase(actionEditOpportunityStageMaster.pending, (state, action) => {
        state.updateOpportunityStageLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditOpportunityStageMaster.fulfilled, (state, action) => {
        state.updateOpportunityStageLoader[action.meta.arg.id] = false;
        state.opportunityStageData = state.opportunityStageData.map((type) =>
          type.opportunity_stage_master_id ===
          action.payload.opportunity_stage_master_id
            ? {
                ...type,
                opportunity_stage: action.payload.opportunity_stage,
              }
            : type
        );
      })
      .addCase(actionEditOpportunityStageMaster.rejected, (state, action) => {
        state.updateOpportunityStageLoader[action.meta.arg.id] = false;
      })

      // Industry

      // get single industry stage
      .addCase(actionGetSingleIndustryMaster.pending, (state) => {
        state.getIndustryDetailLoader = true;
      })
      .addCase(actionGetSingleIndustryMaster.fulfilled, (state, action) => {
        state.getIndustryDetailLoader = false;
        state.getIndustryDetail = action.payload.data;
      })
      .addCase(actionGetSingleIndustryMaster.rejected, (state) => {
        state.getIndustryDetailLoader = false;
      })

      //delete Opportunity Stage
      .addCase(actionDeleteIndustryMaster.pending, (state, action) => {
        state.deleteIndustryMasterLoader = true;
      })
      .addCase(actionDeleteIndustryMaster.fulfilled, (state, action) => {
        state.industryData = state.industryData.filter(
          (type) => type.industry_master_id !== action.payload
        );
        state.deleteIndustryMasterLoader = false;
      })
      .addCase(actionDeleteIndustryMaster.rejected, (state, action) => {
        state.deleteIndustryMasterLoader = false;
      })

      .addCase(actionEditIndustryMaster.pending, (state, action) => {
        state.updateIndustryMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditIndustryMaster.fulfilled, (state, action) => {
        state.updateIndustryMasterLoader[action.meta.arg.id] = false;
        state.industryData = state.industryData.map((type) =>
          type.industry_master_id === action.payload.industry_master_id
            ? {
                ...type,
                industry: action.payload.industry,
              }
            : type
        );
      })
      .addCase(actionEditIndustryMaster.rejected, (state, action) => {
        state.updateIndustryMasterLoader[action.meta.arg.id] = false;
      })

      //get business partner type
      .addCase(actionGetBusinessPartnerTypeMaster.pending, (state) => {
        state.getBusinessPartnerTypeLoader = true;
      })
      .addCase(
        actionGetBusinessPartnerTypeMaster.fulfilled,
        (state, action) => {
          state.getBusinessPartnerTypeLoader = false;
          state.businessPartnerTypeData = action.payload.data;
        }
      )
      .addCase(actionGetBusinessPartnerTypeMaster.rejected, (state) => {
        state.getBusinessPartnerTypeLoader = false;
      })

      // add businesspartner type
      .addCase(actionAddBusinessPartnerTypeMaster.pending, (state) => {
        state.addBusinessPartnerTypeLoader = true;
      })
      .addCase(
        actionAddBusinessPartnerTypeMaster.fulfilled,
        (state, action) => {
          state.addBusinessPartnerTypeLoader = false;
          if (action.payload) {
            if (
              !state.businessPartnerTypeData ||
              !Array.isArray(state.businessPartnerTypeData)
            ) {
              state.businessPartnerTypeData = [action.payload];
            } else {
              state.businessPartnerTypeData.unshift(action.payload);
            }
          }
        }
      )

      .addCase(actionAddBusinessPartnerTypeMaster.rejected, (state) => {
        state.addBusinessPartnerTypeLoader = false;
      })

      //get single business partner type
      .addCase(actionGetSingleBusinessPartnerTypeMaster.pending, (state) => {
        state.getBusinessPartnerTypeDetailLoader = true;
      })
      .addCase(
        actionGetSingleBusinessPartnerTypeMaster.fulfilled,
        (state, action) => {
          state.getBusinessPartnerTypeDetailLoader = false;
          state.businessPartnerTypeDetail = action.payload.data;
        }
      )
      .addCase(actionGetSingleBusinessPartnerTypeMaster.rejected, (state) => {
        state.getBusinessPartnerTypeDetailLoader = false;
      })

      //delete business partner type
      .addCase(
        actionDeleteBusinessPartnerTypeMaster.pending,
        (state, action) => {
          state.deleteBusinessPartnerTypeLoader = true;
        }
      )
      .addCase(
        actionDeleteBusinessPartnerTypeMaster.fulfilled,
        (state, action) => {
          state.businessPartnerTypeData = state.businessPartnerTypeData.filter(
            (type) => type.business_partner_type_master_id !== action.payload
          );
          state.deleteBusinessPartnerTypeLoader = false;
        }
      )
      .addCase(
        actionDeleteBusinessPartnerTypeMaster.rejected,
        (state, action) => {
          state.deleteBusinessPartnerTypeLoader = false;
        }
      )

      //edit bussiness partner type
      .addCase(actionEditBusinessPartnerTypeMaster.pending, (state, action) => {
        state.updateBusinessPartnerTypeMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(
        actionEditBusinessPartnerTypeMaster.fulfilled,
        (state, action) => {
          state.updateBusinessPartnerTypeMasterLoader[
            action.meta.arg.id
          ] = false;
          state.businessPartnerTypeData = state.businessPartnerTypeData.map(
            (type) =>
              type.business_partner_type_master_id ===
              action.payload.business_partner_type_master_id
                ? {
                    ...type,
                    business_partner_type_name:
                      action.payload.business_partner_type_name,
                    description: action.payload.description,
                  }
                : type
          );
        }
      )
      .addCase(
        actionEditBusinessPartnerTypeMaster.rejected,
        (state, action) => {
          state.updateBusinessPartnerTypeMasterLoader[
            action.meta.arg.id
          ] = false;
        }
      )

      // get beat grade master
      .addCase(actionGetBeatGradeMaster.pending, (state) => {
        state.getBeatGradeMasterLoader = true;
      })
      .addCase(actionGetBeatGradeMaster.fulfilled, (state, action) => {
        state.getBeatGradeMasterLoader = false;
        state.beatGradeMasterData = action.payload.data;
      })
      .addCase(actionGetBeatGradeMaster.rejected, (state) => {
        state.getBeatGradeMasterLoader = false;
      })

      // add beat grade master
      .addCase(actionAddBeatGradeMaster.pending, (state) => {
        state.addBeatGradeMasterLoader = true;
      })
      .addCase(actionAddBeatGradeMaster.fulfilled, (state, action) => {
        state.addBeatGradeMasterLoader = false;
        if (action.payload) {
          if (
            !state.beatGradeMasterData ||
            !Array.isArray(state.beatGradeMasterData)
          ) {
            state.beatGradeMasterData = [action.payload];
          } else {
            state.beatGradeMasterData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddBeatGradeMaster.rejected, (state) => {
        state.addBeatGradeMasterLoader = false;
      })

      // get single beat grade master
      .addCase(actionGetSingleBeatGradeMaster.pending, (state) => {
        state.getBeatGradeMasterDetailLoader = true;
      })
      .addCase(actionGetSingleBeatGradeMaster.fulfilled, (state, action) => {
        state.getBeatGradeMasterDetailLoader = false;
        state.beatGradeMasterDetail = action.payload.data;
      })
      .addCase(actionGetSingleBeatGradeMaster.rejected, (state) => {
        state.getBeatGradeMasterDetailLoader = false;
      })

      // delete beat grade master
      .addCase(actionDeleteBeatGradeMaster.pending, (state, action) => {
        state.deleteBeatGradeMasterLoader = true;
      })
      .addCase(actionDeleteBeatGradeMaster.fulfilled, (state, action) => {
        state.beatGradeMasterData = state.beatGradeMasterData.filter(
          (type) => type.beat_grade_master_id !== action.payload
        );
        state.deleteBeatGradeMasterLoader = false;
      })
      .addCase(actionDeleteBeatGradeMaster.rejected, (state, action) => {
        state.deleteBeatGradeMasterLoader = false;
      })

      // edit beat grade master
      .addCase(actionEditBeatGradeMaster.pending, (state, action) => {
        state.updateBeatGradeMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditBeatGradeMaster.fulfilled, (state, action) => {
        state.updateBeatGradeMasterLoader[action.meta.arg.id] = false;
        state.beatGradeMasterData = state.beatGradeMasterData.map((type) =>
          type.beat_grade_master_id === action.payload.beat_grade_master_id
            ? {
                ...type,
                beat_grade: action.payload.beat_grade,
              }
            : type
        );
      })
      .addCase(actionEditBeatGradeMaster.rejected, (state, action) => {
        state.updateBeatGradeMasterLoader[action.meta.arg.id] = false;
      })

      // get Department
      .addCase(actionGetDepartment.pending, (state) => {
        state.getDepartmentLoader = true;
      })
      .addCase(actionGetDepartment.fulfilled, (state, action) => {
        state.getDepartmentLoader = false;
        state.departmentData = action.payload.data;
      })
      .addCase(actionGetDepartment.rejected, (state) => {
        state.getDepartmentLoader = false;
      })

      //add Department
      .addCase(actionAddDepartment.pending, (state) => {
        state.addDepartmentLoader = true;
      })
      .addCase(actionAddDepartment.fulfilled, (state, action) => {
        state.addDepartmentLoader = false;
        if (action.payload) {
          if (!state.departmentData || !Array.isArray(state.departmentData)) {
            state.departmentData = [action.payload];
          } else {
            state.departmentData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddDepartment.rejected, (state) => {
        state.addDepartmentLoader = false;
      })

      //get single Department
      .addCase(actionGetSingleDepartment.pending, (state) => {
        state.getDepartmentDetailLoader = true;
      })
      .addCase(actionGetSingleDepartment.fulfilled, (state, action) => {
        state.getDepartmentDetailLoader = false;
        state.departmentDetail = action.payload.data;
      })
      .addCase(actionGetSingleDepartment.rejected, (state) => {
        state.getDepartmentDetailLoader = false;
      })

      // delete Department
      .addCase(actionDeleteDepartment.pending, (state, action) => {
        state.deleteDepartmentLoader = true;
      })
      .addCase(actionDeleteDepartment.fulfilled, (state, action) => {
        state.departmentData = state.departmentData.filter(
          (department) => department.department_master_id !== action.payload
        );
        state.deleteDepartmentLoader = false;
      })
      .addCase(actionDeleteDepartment.rejected, (state, action) => {
        state.deleteDepartmentLoader = false;
      })

      //update Department
      .addCase(actionEditDepartment.pending, (state, action) => {
        state.updateDepartmentLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditDepartment.fulfilled, (state, action) => {
        state.updateDepartmentLoader[action.meta.arg.id] = false;
        state.departmentData = state.departmentData.map((department) =>
          department.department_master_id ===
          action.payload.department_master_id
            ? {
                ...department,
                department_name: action.payload.department_name,
              }
            : department
        );
      })
      .addCase(actionEditDepartment.rejected, (state, action) => {
        state.updateDepartmentLoader[action.meta.arg.id] = false;
      })

      //type of company start here
      //get all type of company start here
      .addCase(actionGetTypeOfCompanyMaster.pending, (state) => {
        state.getTypeOfCompanyLoader = true;
      })
      .addCase(actionGetTypeOfCompanyMaster.fulfilled, (state, action) => {
        state.getTypeOfCompanyLoader = false;
        state.typeOfCompanyData = action.payload.data;
      })
      .addCase(actionGetTypeOfCompanyMaster.rejected, (state) => {
        state.getTypeOfCompanyLoader = false;
      })

      //Add type of company
      .addCase(actionAddTypeOfCompanyMaster.pending, (state) => {
        state.addTypeOfCompanyLoader = true;
      })
      .addCase(actionAddTypeOfCompanyMaster.fulfilled, (state, action) => {
        state.addTypeOfCompanyLoader = false;
        if (action.payload) {
          if (
            !state.typeOfCompanyData ||
            !Array.isArray(state.typeOfCompanyData)
          ) {
            state.typeOfCompanyData = [action.payload];
          } else {
            state.typeOfCompanyData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddTypeOfCompanyMaster.rejected, (state) => {
        state.addTypeOfCompanyLoader = false;
      })

      //delete type of company
      .addCase(actionDeleteTypeOfCompanyMaster.pending, (state, action) => {
        state.deleteTypeOfCompanyLoader = true;
      })
      .addCase(actionDeleteTypeOfCompanyMaster.fulfilled, (state, action) => {
        state.typeOfCompanyData = state.typeOfCompanyData.filter(
          (type) => type.type_of_company_id !== action.payload
        );
        state.deleteTypeOfCompanyLoader = false;
      })
      .addCase(actionDeleteTypeOfCompanyMaster.rejected, (state, action) => {
        state.deleteTypeOfCompanyLoader = false;
      })

      //get single type of company
      .addCase(actionGetSingleTypeOfCompanyMaster.pending, (state) => {
        state.getSingleTypeOfCompanyLoader = true;
      })
      .addCase(
        actionGetSingleTypeOfCompanyMaster.fulfilled,
        (state, action) => {
          state.getSingleTypeOfCompanyData = action.payload.data;
          state.getSingleTypeOfCompanyLoader = false;
        }
      )
      .addCase(actionGetSingleTypeOfCompanyMaster.rejected, (state) => {
        state.getSingleTypeOfCompanyLoader = false;
      })

      //edit type of company
      .addCase(actionEditTypeOfCompanyMaster.pending, (state, action) => {
        state.updateTypeOfCompanyLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditTypeOfCompanyMaster.fulfilled, (state, action) => {
        state.updateTypeOfCompanyLoader[action.meta.arg.id] = false;
        state.typeOfCompanyData = state.typeOfCompanyData.map((type) =>
          type.type_of_company_id === action.payload.type_of_company_id
            ? {
                ...type,
                company_type: action.payload.company_type,
              }
            : type
        );
      })
      .addCase(actionEditTypeOfCompanyMaster.rejected, (state, action) => {
        state.updateTypeOfCompanyLoader[action.meta.arg.id] = false;
      })

      //get all beat master
      .addCase(actionGetBeatMaster.pending, (state) => {
        state.getBeatMasterLoader = true;
      })
      .addCase(actionGetBeatMaster.fulfilled, (state, action) => {
        state.getBeatMasterLoader = false;
        state.BeatMasterData = action.payload.data;
      })
      .addCase(actionGetBeatMaster.rejected, (state) => {
        state.getBeatMasterLoader = false;
      })

      //add beat master
      .addCase(actionAddBeatMaster.pending, (state) => {
        state.addBeatMasterLoader = true;
      })
      .addCase(actionAddBeatMaster.fulfilled, (state, action) => {
        state.addBeatMasterLoader = false;
        if (action.payload) {
          if (!state.BeatMasterData || !Array.isArray(state.BeatMasterData)) {
            state.BeatMasterData = [action.payload];
          } else {
            state.BeatMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddBeatMaster.rejected, (state) => {
        state.addBeatMasterLoader = false;
      })

      //delete beat master
      .addCase(actionDeleteBeatMaster.pending, (state, action) => {
        state.deleteBeatMasterLoader = true;
      })
      .addCase(actionDeleteBeatMaster.fulfilled, (state, action) => {
        state.BeatMasterData = state.BeatMasterData.filter(
          (type) => type.beat_master_id !== action.payload
        );
        state.deleteBeatMasterLoader = false;
      })
      .addCase(actionDeleteBeatMaster.rejected, (state, action) => {
        state.deleteBeatMasterLoader = false;
      })

      //get single beat Master
      .addCase(actionGetSingleBeatMaster.pending, (state) => {
        state.getSingleBeatMasterLoader = true;
      })
      .addCase(actionGetSingleBeatMaster.fulfilled, (state, action) => {
        state.getSingleBeatMasterData = action.payload.data;
        state.getSingleBeatMasterLoader = false;
      })
      .addCase(actionGetSingleBeatMaster.rejected, (state) => {
        state.getSingleBeatMasterLoader = false;
      })

      //edit beat master
      .addCase(actionEditBeatMaster.pending, (state, action) => {
        state.updateBeatMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditBeatMaster.fulfilled, (state, action) => {
        state.updateBeatMasterLoader[action.meta.arg.id] = false;
        state.BeatMasterData = state.BeatMasterData.map((type) =>
          type.beat_master_id === action.payload.beat_master_id
            ? {
                ...type,
                beat_grade: {
                  ...type.beat_grade,
                  beat_grade: action.payload.beat_grade?.beat_grade,
                },
                zone: {
                  ...type.zone,
                  zone_name: action.payload.zone?.zone_name,
                },
                area: {
                  ...type.area,
                  area_name: action.payload.area?.area_name,
                },
                beat_name: action.payload.beat_name,
                city: action.payload.city,
              }
            : type
        );
      })
      .addCase(actionEditBeatMaster.rejected, (state, action) => {
        state.updateBeatMasterLoader[action.meta.arg.id] = false;
      })

      //zone master start here
      //get all zone master start here
      .addCase(actionGetZoneMaster.pending, (state) => {
        state.getZoneMasterLoader = true;
      })
      .addCase(actionGetZoneMaster.fulfilled, (state, action) => {
        state.getZoneMasterLoader = false;
        state.zoneMasterData = action.payload.data;
      })
      .addCase(actionGetZoneMaster.rejected, (state) => {
        state.getZoneMasterLoader = false;
      })

      //Add zone slice start here
      .addCase(actionAddZoneMaster.pending, (state) => {
        state.addZoneMasterLoader = true;
      })
      .addCase(actionAddZoneMaster.fulfilled, (state, action) => {
        state.addZoneMasterLoader = false;
        if (action.payload) {
          if (!state.zoneMasterData || !Array.isArray(state.zoneMasterData)) {
            state.zoneMasterData = [action.payload];
          } else {
            state.zoneMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddZoneMaster.rejected, (state) => {
        state.addZoneMasterLoader = false;
      })

      //delete zone slice start here
      .addCase(actionDeleteZoneMaster.pending, (state, action) => {
        state.deleteZoneMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteZoneMaster.fulfilled, (state, action) => {
        state.zoneMasterData = state.zoneMasterData.filter(
          (type) => type.zone_master_id !== action.payload
        );
        state.deleteZoneMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteZoneMaster.rejected, (state, action) => {
        state.deleteZoneMasterLoader[action.meta.arg.id] = false;
      })

      //single zone slice start here
      .addCase(actionGetSingleZoneMaster.pending, (state) => {
        state.getSingleZoneMasterLoader = true;
      })
      .addCase(actionGetSingleZoneMaster.fulfilled, (state, action) => {
        state.getSingleZoneMasterData = action.payload.data;
        state.getSingleZoneMasterLoader = false;
      })
      .addCase(actionGetSingleZoneMaster.rejected, (state) => {
        state.getSingleZoneMasterLoader = false;
      })

      //update zone slice start here
      .addCase(actionEditZoneMasterMaster.pending, (state, action) => {
        state.updateZoneMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditZoneMasterMaster.fulfilled, (state, action) => {
        state.updateZoneMasterLoader[action.meta.arg.id] = false;
        state.zoneMasterData = state.zoneMasterData.map((type) =>
          type.zone_master_id === action.payload.zone_master_id
            ? {
                ...type,
                zone_name: action.payload.zone_name,
              }
            : type
        );
      })
      .addCase(actionEditZoneMasterMaster.rejected, (state, action) => {
        state.updateZoneMasterLoader[action.meta.arg.id] = false;
      })

      //Area master start here
      //get all area slice start here
      .addCase(actionGetAreaMaster.pending, (state) => {
        state.getAreaMasterLoader = true;
      })
      .addCase(actionGetAreaMaster.fulfilled, (state, action) => {
        state.getAreaMasterLoader = false;
        state.areaMasterData = action.payload.data;
      })
      .addCase(actionGetAreaMaster.rejected, (state) => {
        state.getAreaMasterLoader = false;
      })

      //add area slice
      .addCase(actionAddAreaMaster.pending, (state) => {
        state.addAreaMasterLoader = true;
      })
      .addCase(actionAddAreaMaster.fulfilled, (state, action) => {
        state.addAreaMasterLoader = false;
        if (action.payload) {
          if (!state.areaMasterData || !Array.isArray(state.areaMasterData)) {
            state.areaMasterData = [action.payload];
          } else {
            state.areaMasterData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddAreaMaster.rejected, (state) => {
        state.addAreaMasterLoader = false;
      })

      //delete area slice
      .addCase(actionDeleteAreaMaster.pending, (state, action) => {
        state.deleteAreaMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteAreaMaster.fulfilled, (state, action) => {
        state.areaMasterData = state.areaMasterData.filter(
          (type) => type.area_master_id !== action.payload
        );
        state.deleteAreaMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteAreaMaster.rejected, (state, action) => {
        state.deleteAreaMasterLoader[action.meta.arg.id] = false;
      })

      //get single area slice
      .addCase(actionGetSingleAreaMaster.pending, (state) => {
        state.getSingleAreaMasterLoader = true;
      })
      .addCase(actionGetSingleAreaMaster.fulfilled, (state, action) => {
        state.getSingleAreaMasterData = action.payload.data;
        state.getSingleAreaMasterLoader = false;
      })
      .addCase(actionGetSingleAreaMaster.rejected, (state) => {
        state.getSingleAreaMasterLoader = false;
      })

      //update area slice
      .addCase(actionEditAreaMasterMaster.pending, (state, action) => {
        state.updateAreaMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditAreaMasterMaster.fulfilled, (state, action) => {
        state.updateAreaMasterLoader[action.meta.arg.id] = false;
        state.areaMasterData = state.areaMasterData.map((type) =>
          type.area_master_id === action.payload.area_master_id
            ? {
                ...type,
                area_name: action.payload.area_name,
              }
            : type
        );
      })
      .addCase(actionEditAreaMasterMaster.rejected, (state, action) => {
        state.updateAreaMasterLoader[action.meta.arg.id] = false;
      })

      // Task Status Master Start Here
      .addCase(actionGetTaskStatusMaster.pending, (state) => {
        state.getTaskStatusMasterLoader = true;
      })
      .addCase(actionGetTaskStatusMaster.fulfilled, (state, action) => {
        state.getTaskStatusMasterLoader = false;
        state.taskStatusMasterData = action.payload.data;
      })
      .addCase(actionGetTaskStatusMaster.rejected, (state) => {
        state.getTaskStatusMasterLoader = false;
      })

      .addCase(actionAddTaskStatusMaster.pending, (state) => {
        state.addTaskStatusMasterLoader = true;
      })
      .addCase(actionAddTaskStatusMaster.fulfilled, (state, action) => {
        state.addTaskStatusMasterLoader = false;
        if (action.payload) {
          if (
            !state.taskStatusMasterData ||
            !Array.isArray(state.taskStatusMasterData)
          ) {
            state.taskStatusMasterData = [action.payload];
          } else {
            state.taskStatusMasterData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddTaskStatusMaster.rejected, (state) => {
        state.addTaskStatusMasterLoader = false;
      })

      .addCase(actionDeleteTaskStatusMaster.pending, (state, action) => {
        state.deleteTaskStatusMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteTaskStatusMaster.fulfilled, (state, action) => {
        state.taskStatusMasterData = state.taskStatusMasterData.filter(
          (type) => type.task_status_id !== action.payload
        );
        state.deleteTaskStatusMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteTaskStatusMaster.rejected, (state, action) => {
        state.deleteTaskStatusMasterLoader[action.meta.arg.id] = false;
      })

      .addCase(actionGetTaskStatusMasterDetail.pending, (state) => {
        state.getTaskStatusMasterDetailLoader = true;
      })
      .addCase(actionGetTaskStatusMasterDetail.fulfilled, (state, action) => {
        state.taskStatusMasterDetail = action.payload.data;
        state.getTaskStatusMasterDetailLoader = false;
      })
      .addCase(actionGetTaskStatusMasterDetail.rejected, (state) => {
        state.getTaskStatusMasterDetailLoader = false;
      })

      .addCase(actionEditTaskStatusMaster.pending, (state, action) => {
        state.updateTaskStatusMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditTaskStatusMaster.fulfilled, (state, action) => {
        state.updateTaskStatusMasterLoader[action.meta.arg.id] = false;
        state.taskStatusMasterData = state.taskStatusMasterData.map((type) =>
          type.task_status_id === action.payload.task_status_id
            ? {
                ...type,
                status: action.payload.status,
              }
            : type
        );
      })
      .addCase(actionEditTaskStatusMaster.rejected, (state, action) => {
        state.updateTaskStatusMasterLoader[action.meta.arg.id] = false;
      })

      //material group start here
      //get all material group start here
      .addCase(actionGetMaterialGroupMaster.pending, (state) => {
        state.getMaterialGroupLoader = true;
      })
      .addCase(actionGetMaterialGroupMaster.fulfilled, (state, action) => {
        state.getMaterialGroupLoader = false;
        state.materialGroupMasterData = action.payload.data;
      })
      .addCase(actionGetMaterialGroupMaster.rejected, (state) => {
        state.getMaterialGroupLoader = false;
      })

      //get single material group
      .addCase(actionGetSingleMaterialGroupMaster.pending, (state) => {
        state.getSingleMaterialGroupMasterLoader = true;
      })
      .addCase(
        actionGetSingleMaterialGroupMaster.fulfilled,
        (state, action) => {
          state.getSingleMaterialGroupMasterData = action.payload.data;
          state.getSingleMaterialGroupMasterLoader = false;
        }
      )
      .addCase(actionGetSingleMaterialGroupMaster.rejected, (state) => {
        state.getSingleMaterialGroupMasterLoader = false;
      })

      // add material group
      .addCase(actionAddMaterialGroupMaster.pending, (state) => {
        state.addMaterialGroupMasterLoader = true;
      })
      .addCase(actionAddMaterialGroupMaster.fulfilled, (state, action) => {
        state.addMaterialGroupMasterLoader = false;
        if (action.payload) {
          if (
            !state.materialGroupMasterData ||
            !Array.isArray(state.materialGroupMasterData)
          ) {
            state.materialGroupMasterData = [action.payload];
          } else {
            state.materialGroupMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddMaterialGroupMaster.rejected, (state) => {
        state.addMaterialGroupMasterLoader = false;
      })

      //delete material group
      .addCase(actionDeleteMaterialGroupMaster.pending, (state, action) => {
        state.deleteMaterialGroupMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteMaterialGroupMaster.fulfilled, (state, action) => {
        state.materialGroupMasterData = state.materialGroupMasterData.filter(
          (type) => type.material_group_master_id !== action.payload
        );
        state.deleteMaterialGroupMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteMaterialGroupMaster.rejected, (state, action) => {
        state.deleteMaterialGroupMasterLoader[action.meta.arg.id] = false;
      })

      //update material group
      .addCase(actionEditMaterialGroupMaster.pending, (state, action) => {
        state.updateMaterialGroupMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditMaterialGroupMaster.fulfilled, (state, action) => {
        state.updateMaterialGroupMasterLoader[action.meta.arg.id] = false;
        state.materialGroupMasterData = state.materialGroupMasterData.map(
          (type) =>
            type.material_group_master_id ===
            action.payload.material_group_master_id
              ? {
                  ...type,
                  name: action.payload.name,
                  description: action.payload.description,
                }
              : type
        );
      })
      .addCase(actionEditMaterialGroupMaster.rejected, (state, action) => {
        state.updateMaterialGroupMasterLoader[action.meta.arg.id] = false;
      })

      //address type
      //get all address type start here
      .addCase(actionGetAddressTypeMaster.pending, (state) => {
        state.getAddressTypeLoader = true;
      })
      .addCase(actionGetAddressTypeMaster.fulfilled, (state, action) => {
        state.getAddressTypeLoader = false;
        state.addressTypeMasterData = action.payload.data;
      })
      .addCase(actionGetAddressTypeMaster.rejected, (state) => {
        state.getAddressTypeLoader = false;
      })

      //get single address type
      .addCase(actionGetSingleAddressTypeMaster.pending, (state) => {
        state.getSingleAddressTypeMasterLoader = true;
      })
      .addCase(actionGetSingleAddressTypeMaster.fulfilled, (state, action) => {
        state.getSingleAddressTypeMasterData = action.payload.data;
        state.getSingleAddressTypeMasterLoader = false;
      })
      .addCase(actionGetSingleAddressTypeMaster.rejected, (state) => {
        state.getSingleAddressTypeMasterLoader = false;
      })

      // add address type
      .addCase(actionAddAddressTypeMaster.pending, (state) => {
        state.addAddressTypeMasterLoader = true;
      })
      .addCase(actionAddAddressTypeMaster.fulfilled, (state, action) => {
        state.addAddressTypeMasterLoader = false;
        if (action.payload) {
          if (
            !state.addressTypeMasterData ||
            !Array.isArray(state.addressTypeMasterData)
          ) {
            state.addressTypeMasterData = [action.payload];
          } else {
            state.addressTypeMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddAddressTypeMaster.rejected, (state) => {
        state.addAddressTypeMasterLoader = false;
      })

      //delete address type
      .addCase(actionDeleteAddressTypeMaster.pending, (state, action) => {
        state.deleteAddressTypeMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteAddressTypeMaster.fulfilled, (state, action) => {
        state.addressTypeMasterData = state.addressTypeMasterData.filter(
          (type) => type.address_type_master_id !== action.payload
        );
        state.deleteAddressTypeMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteAddressTypeMaster.rejected, (state, action) => {
        state.deleteAddressTypeMasterLoader[action.meta.arg.id] = false;
      })

      //update address type
      .addCase(actionEditAddressTypeMaster.pending, (state, action) => {
        state.updateAddressTypeMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditAddressTypeMaster.fulfilled, (state, action) => {
        state.updateAddressTypeMasterLoader[action.meta.arg.id] = false;
        state.addressTypeMasterData = state.addressTypeMasterData.map((type) =>
          type.address_type_master_id === action.payload.address_type_master_id
            ? {
                ...type,
                address_type: action.payload.address_type,
                description: action.payload.description,
              }
            : type
        );
      })
      .addCase(actionEditAddressTypeMaster.rejected, (state, action) => {
        state.updateAddressTypeMasterLoader[action.meta.arg.id] = false;
      })

      // payment method start here
      // get all payment method start here
      .addCase(actionGetPaymentMethodMaster.pending, (state) => {
        state.getPaymentMethodLoader = true;
      })
      .addCase(actionGetPaymentMethodMaster.fulfilled, (state, action) => {
        state.getPaymentMethodLoader = false;
        state.paymentMethodMasterData = action.payload.data;
      })
      .addCase(actionGetPaymentMethodMaster.rejected, (state) => {
        state.getPaymentMethodLoader = false;
      })

      //get single payment method
      .addCase(actionGetSinglePaymentMethodMaster.pending, (state) => {
        state.getSinglePaymentMethodMasterLoader = true;
      })
      .addCase(
        actionGetSinglePaymentMethodMaster.fulfilled,
        (state, action) => {
          state.getSinglePaymentMethodMasterData = action.payload.data;
          state.getSinglePaymentMethodMasterLoader = false;
        }
      )
      .addCase(actionGetSinglePaymentMethodMaster.rejected, (state) => {
        state.getSinglePaymentMethodMasterLoader = false;
      })

      // add payment method
      .addCase(actionAddPaymentMethodMaster.pending, (state) => {
        state.addPaymentMethodMasterLoader = true;
      })
      .addCase(actionAddPaymentMethodMaster.fulfilled, (state, action) => {
        state.addPaymentMethodMasterLoader = false;
        if (action.payload) {
          if (
            !state.paymentMethodMasterData ||
            !Array.isArray(state.paymentMethodMasterData)
          ) {
            state.paymentMethodMasterData = [action.payload];
          } else {
            state.paymentMethodMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddPaymentMethodMaster.rejected, (state) => {
        state.addPaymentMethodMasterLoader = false;
      })

      //delete payment method
      .addCase(actionDeletePaymentMethodMaster.pending, (state, action) => {
        state.deletePaymentMethodMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeletePaymentMethodMaster.fulfilled, (state, action) => {
        state.paymentMethodMasterData = state.paymentMethodMasterData.filter(
          (type) => type.payment_method_master_id !== action.payload
        );
        state.deletePaymentMethodMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeletePaymentMethodMaster.rejected, (state, action) => {
        state.deletePaymentMethodMasterLoader[action.meta.arg.id] = false;
      })

      //update payment method
      .addCase(actionEditPaymentMethodMaster.pending, (state, action) => {
        state.updatePaymentMethodMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditPaymentMethodMaster.fulfilled, (state, action) => {
        state.updatePaymentMethodMasterLoader[action.meta.arg.id] = false;
        state.paymentMethodMasterData = state.paymentMethodMasterData.map(
          (type) =>
            type.payment_method_master_id ===
            action.payload.payment_method_master_id
              ? {
                  ...type,
                  payment_method: action.payload.payment_method,
                  description: action.payload.description,
                }
              : type
        );
      })
      .addCase(actionEditPaymentMethodMaster.rejected, (state, action) => {
        state.updatePaymentMethodMasterLoader[action.meta.arg.id] = false;
      })

      //Expense category
      //get all Expense category start here
      .addCase(actionGetExpenseCategoryMaster.pending, (state) => {
        state.getExpenseCategoryLoader = true;
      })
      .addCase(actionGetExpenseCategoryMaster.fulfilled, (state, action) => {
        state.getExpenseCategoryLoader = false;
        state.expenseCategoryMasterData = action.payload.data;
      })
      .addCase(actionGetExpenseCategoryMaster.rejected, (state) => {
        state.getExpenseCategoryLoader = false;
      })

      //get single Expense category
      .addCase(actionGetSingleExpenseCategoryMaster.pending, (state) => {
        state.getSingleExpenseCategoryMasterLoader = true;
      })
      .addCase(
        actionGetSingleExpenseCategoryMaster.fulfilled,
        (state, action) => {
          state.getSingleExpenseCategoryMasterData = action.payload.data;
          state.getSingleExpenseCategoryMasterLoader = false;
        }
      )
      .addCase(actionGetSingleExpenseCategoryMaster.rejected, (state) => {
        state.getSingleExpenseCategoryMasterLoader = false;
      })

      // add Expense category
      .addCase(actionAddExpenseCategoryMaster.pending, (state) => {
        state.addExpenseCategoryMasterLoader = true;
      })
      .addCase(actionAddExpenseCategoryMaster.fulfilled, (state, action) => {
        state.addExpenseCategoryMasterLoader = false;
        if (action.payload) {
          if (
            !state.expenseCategoryMasterData ||
            !Array.isArray(state.expenseCategoryMasterData)
          ) {
            state.expenseCategoryMasterData = [action.payload];
          } else {
            state.expenseCategoryMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddExpenseCategoryMaster.rejected, (state) => {
        state.addExpenseCategoryMasterLoader = false;
      })

      //delete Expense category
      .addCase(actionDeleteExpenseCategoryMaster.pending, (state, action) => {
        state.deleteExpenseCategoryMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteExpenseCategoryMaster.fulfilled, (state, action) => {
        state.expenseCategoryMasterData =
          state.expenseCategoryMasterData.filter(
            (type) => type.expense_category_type_master_id !== action.payload
          );
        state.deleteExpenseCategoryMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteExpenseCategoryMaster.rejected, (state, action) => {
        state.deleteExpenseCategoryMasterLoader[action.meta.arg.id] = false;
      })

      //update Expense category
      .addCase(actionEditExpenseCategoryMaster.pending, (state, action) => {
        state.updateExpenseCategoryMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditExpenseCategoryMaster.fulfilled, (state, action) => {
        state.updateExpenseCategoryMasterLoader[action.meta.arg.id] = false;
        state.expenseCategoryMasterData = state.expenseCategoryMasterData.map(
          (type) =>
            type.expense_category_type_master_id ===
            action.payload.expense_category_type_master_id
              ? {
                  ...type,
                  expense_category_type: action.payload.expense_category_type,
                  description: action.payload.description,
                }
              : type
        );
      })
      .addCase(actionEditExpenseCategoryMaster.rejected, (state, action) => {
        state.updateExpenseCategoryMasterLoader[action.meta.arg.id] = false;
      })

      //Unit Conversion
      //get all Unit Conversion start here
      .addCase(actionGetUnitConversionMaster.pending, (state) => {
        state.getUnitConversionLoader = true;
      })
      .addCase(actionGetUnitConversionMaster.fulfilled, (state, action) => {
        state.getUnitConversionLoader = false;
        state.unitConversionMasterData = action.payload.data;
      })
      .addCase(actionGetUnitConversionMaster.rejected, (state) => {
        state.getUnitConversionLoader = false;
      })

      //get single Unit Conversion
      .addCase(actionGetSingleUnitConversionMaster.pending, (state) => {
        state.getSingleUnitConversionMasterLoader = true;
      })
      .addCase(
        actionGetSingleUnitConversionMaster.fulfilled,
        (state, action) => {
          state.getSingleUnitConversionMasterData = action.payload.data;
          state.getSingleUnitConversionMasterLoader = false;
        }
      )
      .addCase(actionGetSingleUnitConversionMaster.rejected, (state) => {
        state.getSingleUnitConversionMasterLoader = false;
      })

      // add Unit Conversion
      .addCase(actionAddUnitConversionMaster.pending, (state) => {
        state.addUnitConversionMasterLoader = true;
      })
      .addCase(actionAddUnitConversionMaster.fulfilled, (state, action) => {
        state.addUnitConversionMasterLoader = false;

        if (
          !state.unitConversionMasterData ||
          !Array.isArray(state.unitConversionMasterData)
        ) {
          state.unitConversionMasterData = [action.payload];
        } else {
          state.unitConversionMasterData.unshift(action.payload);
        }
      })

      .addCase(actionAddUnitConversionMaster.rejected, (state) => {
        state.addUnitConversionMasterLoader = false;
      })

      //delete Unit Conversion
      .addCase(actionDeleteUnitConversionMaster.pending, (state, action) => {
        state.deleteUnitConversionMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteUnitConversionMaster.fulfilled, (state, action) => {
        state.unitConversionMasterData = state.unitConversionMasterData.filter(
          (type) => type.unit_conversion_master_id !== action.payload
        );
        state.deleteUnitConversionMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteUnitConversionMaster.rejected, (state, action) => {
        state.deleteUnitConversionMasterLoader[action.meta.arg.id] = false;
      })

      //update Unit Conversion
      .addCase(actionEditUnitConversionMaster.pending, (state) => {
        state.updateUnitConversionMasterLoader = true;
      })
      .addCase(actionEditUnitConversionMaster.fulfilled, (state, action) => {
        state.updateUnitConversionMasterLoader = false;
        state.unitConversionMasterData = state.unitConversionMasterData.map(
          (data) =>
            data.unit_conversion_master_id ===
            action.payload.unit_conversion_master_id
              ? { ...data, ...action.payload }
              : data
        );
      })

      .addCase(actionEditUnitConversionMaster.rejected, (state) => {
        state.updateUnitConversionMasterLoader = false;
      })

      //exchane currency
      //get all exchane currency start here
      .addCase(actionGetExchageCurrencyMaster.pending, (state) => {
        state.getExchangeCurrencyLoader = true;
      })
      .addCase(actionGetExchageCurrencyMaster.fulfilled, (state, action) => {
        state.getExchangeCurrencyLoader = false;
        state.exchangeCurrencyMasterData = action.payload.data;
        state.exchangeCurrencyMasterDataCount = action.payload.total_records;
      })
      .addCase(actionGetExchageCurrencyMaster.rejected, (state) => {
        state.getExchangeCurrencyLoader = false;
      })

      //get single exchane currency
      .addCase(actionGetSingleExchangeCurrencyMaster.pending, (state) => {
        state.getSingleExchangeCurrencyMasterLoader = true;
      })
      .addCase(
        actionGetSingleExchangeCurrencyMaster.fulfilled,
        (state, action) => {
          state.getSingleExchangeCurrencyMasterData = action.payload.data;
          state.getSingleExchangeCurrencyMasterLoader = false;
        }
      )
      .addCase(actionGetSingleExchangeCurrencyMaster.rejected, (state) => {
        state.getSingleExchangeCurrencyMasterLoader = false;
      })

      //get single exchane currency
      .addCase(actionGetAllExchageCurrencyMaster.pending, (state) => {
        state.getAllExchangeCurrencyMasterLoader = true;
      })
      .addCase(actionGetAllExchageCurrencyMaster.fulfilled, (state, action) => {
        state.allCurrencyMasterData = action.payload.data;
        state.getAllExchangeCurrencyMasterLoader = false;
      })
      .addCase(actionGetAllExchageCurrencyMaster.rejected, (state) => {
        state.getAllExchangeCurrencyMasterLoader = false;
      })

      // sync exchane currency
      .addCase(actionSyncExchangeCurrencyMaster.pending, (state) => {
        state.syncExchangeCurrencyMasterLoader = true; // Loader starts here
      })
      .addCase(actionSyncExchangeCurrencyMaster.fulfilled, (state, action) => {
        state.syncExchangeCurrencyMasterLoader = false; // Loader stops after success
        // state.exchangeCurrencyMasterData = action.payload;  // Update data if necessary
      })
      .addCase(actionSyncExchangeCurrencyMaster.rejected, (state) => {
        state.syncExchangeCurrencyMasterLoader = false; // Loader stops after failure
      })

      //delete exchane currency
      .addCase(actionDeleteExchangeCurrencyMaster.pending, (state, action) => {
        state.deleteExchangeCurrencyMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(
        actionDeleteExchangeCurrencyMaster.fulfilled,
        (state, action) => {
          state.exchangeCurrencyMasterData =
            state.exchangeCurrencyMasterData.filter(
              (type) => type.currency_exchange_id !== action.payload
            );
          state.deleteExchangeCurrencyMasterLoader[action.meta.arg.id] = false;
          state.exchangeCurrencyMasterDataCount =
            state.exchangeCurrencyMasterDataCount - 1;
        }
      )
      .addCase(actionDeleteExchangeCurrencyMaster.rejected, (state, action) => {
        state.deleteExchangeCurrencyMasterLoader[action.meta.arg.id] = false;
      })

      //update exchane currency
      .addCase(actionEditExchangeCurrencyMaster.pending, (state, action) => {
        state.updateExchangeCurrencyMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditExchangeCurrencyMaster.fulfilled, (state, action) => {
        state.updateExchangeCurrencyMasterLoader[action.meta.arg.id] = false;
        state.exchangeCurrencyMasterData = state.exchangeCurrencyMasterData.map(
          (type) =>
            type.currency_exchange_id === action.payload.currency_exchange_id
              ? {
                  ...type,
                  exchange_rate: action.payload.exchange_rate,
                }
              : type
        );
      })
      .addCase(actionEditExchangeCurrencyMaster.rejected, (state, action) => {
        state.updateExchangeCurrencyMasterLoader[action.meta.arg.id] = false;
      });
  },
});
export default generalMastersSlice.reducer;
