import { BiFingerprint } from "react-icons/bi";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  StopOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Table,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User from "../../assets/img/user.svg";
import {
  actionGetCompanyUsers,
  actionGetCompanyAllUsers,
  actionDeactivateUser,
  actionClearUsersAllData,
} from "../../store/services/usersService";
import {
  actionGetCountries,
  actionGetStates,
} from "../../store/services/locationService";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import CustomIconText from "../common/CustomIconText";
import { LuFilter } from "react-icons/lu";
import { SiMicrosoftexcel } from "react-icons/si";
import dayjs from "dayjs";
import {
  actionGetAllRoleProfile,
  actionGetRoles,
} from "../../store/services/settingService";

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offsetRef = useRef(0);
  const [form] = Form.useForm();

  const {
    companyUsers,
    getCompanyUserLoader,
    companyUsersCount,
    companyAllUsers,
    getCompanyAllUserLoader,
    deactivateUsersLoader,
  } = useSelector((state) => state.users);

  const { user, currentCompanyUser } = useSelector((state) => state.auth);

  const { getRolesLoader, roles, getAllRoleProfileLoader, roleAllProfile } =
    useSelector((state) => state.setting);

  const { countries, states, getCountryLoader, statesLoader } = useSelector(
    (state) => state.location
  );

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(actionGetCompanyUsers({ offset: offsetRef.current, search }));
  }, [dispatch]);

  const initialColumns = [
    {
      title: "First Name",
      key: "1",
      dataIndex: "first_name",
      fixed: "left",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      width: "15%",
      render: (text, data) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() =>
            navigate("/update-user", { state: { userId: data.id } })
          }
        >
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            src={data.profile_pic}
            size="large"
          />
          <p className="ml-2 text-brand">
            {data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1)}
          </p>
        </div>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "2",
      width: "15%",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">{data.last_name ? data.last_name : "-"}</span>
      ),
    },
    {
      title: "Email",
      key: "3",
      width: "20%",
      dataIndex: "email",
      sorter: (a, b) => a.email - b.email,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Report To",
      dataIndex: "report_to",
      key: "4",
      width: "20%",
      render: (text, data) => (
        <span className="ml-2">
          {data.reports_to && (
            <>
              {data.reports_to.first_name}
              {data.reports_to.last_name ? ` ${data.reports_to.last_name}` : ""}
            </>
          )}
        </span>
      ),
    },
    {
      title: "Role Profile",
      key: "3",
      width: "20%",
      dataIndex: "roleprofile",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => text?.name,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "5",
      width: "15%",
      render: (text, data) => (
        <span
          className={`ml-2 border rounded-md px-3 py-1 ${data.status == "INVITED"
            ? "border-blue-700 text-blue-700"
            : data.status == "ACTIVE"
              ? "border-green-500 text-green-500"
              : data.status == "DEACTIVE"
                ? "border-orange-400 text-orange-400"
                : data.status == "REJECT_INVITATION"
                  ? "border-red-500 text-red-500"
                  : "border-black"
            }`}
        >
          {data.status == "REJECT_INVITATION"
            ? "REJECT"
            : data.status
              ? data.status
              : "-"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "10%",
      fixed: "right",
      render: (text, data) => {
        return (
          <>
            <Dropdown.Button
              id="custom-drop"
              menu={{
                items: [
                  (data.status === "ACTIVE" || data.status === "DEACTIVE") &&
                  !currentCompanyUser && {
                    label: (
                      <Popconfirm
                        okButtonProps={{
                          loading: deactivateUsersLoader,
                        }}
                        placement="topLeft"
                        title={
                          data.status === "ACTIVE" ? "Deactivate" : "Activate"
                        }
                        description={`Are you sure to ${data.status === "ACTIVE" ? "deactivate" : "activate"
                          } this user?`}
                        onConfirm={() => {
                          const req = {
                            users: [data.id],
                          };
                          data.status === "ACTIVE" &&
                            dispatch(actionDeactivateUser({ req }));
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div>
                          <StopOutlined
                            style={{
                              color:
                                data.status === "ACTIVE"
                                  ? "orange"
                                  : "#22c55e",
                            }}
                          />
                          <span
                            style={{
                              marginLeft: 8,
                              color:
                                data.status === "ACTIVE"
                                  ? "orange"
                                  : "#22c55e",
                            }}
                          >
                            {data.status === "ACTIVE"
                              ? "Deactivate"
                              : "Activate"}
                          </span>
                        </div>
                      </Popconfirm>
                    ),
                    key: "Deactivate",
                  },
                  {
                    label: <span>Edit</span>,
                    key: "Edit",
                    icon: <EditOutlined />,
                    onClick: () =>
                      navigate("/update-user", {
                        state: {
                          userId: data.id,
                          updateType: true,
                          accountType: currentCompanyUser ? "OWN_ACCOUNT" : "",
                        },
                      }),
                  },
                  {
                    label: <span>View</span>,
                    key: "View",
                    icon: <EyeOutlined />,
                    onClick: () =>
                      navigate("/update-user", {
                        state: {
                          userId: data.id,
                          accountType: currentCompanyUser ? "OWN_ACCOUNT" : "",
                        },
                      }),
                  },
                  {
                    label: <span>Attendance Logs</span>,
                    key: "View",
                    icon: <SolutionOutlined />,
                    onClick: () =>
                      navigate("/attendance-logs", {
                        state: {
                          userId: data.id,
                        },
                      }),
                  },
                /*   {
                    label: <span>Add Biometric Image</span>,
                    key: "AddBiometric",
                    icon: <BiFingerprint  />,
                    onClick: () =>
                      navigate("/add-biometric-image", {
                        state: {
                          userId: data.id,
                        },
                      }),
                  }, */
                  !currentCompanyUser && {
                    label: <span>Delete</span>,
                    key: "Delete",
                    icon: <DeleteOutlined />,
                    danger: true,
                    onClick: () => {
                      setDeleteData(data);
                      dispatch(actionGetCompanyAllUsers());
                    },
                  },
                ].filter(Boolean),
              }}
            >
              Action
            </Dropdown.Button>
          </>
        );
      },
    },
  ];

  const transferOptions = [];
  companyAllUsers &&
    companyAllUsers.map((data) =>
      transferOptions.push({
        label: (
          <span>
            <Avatar size={30} src={data?.profile_pic} className="mr-2" />
            {`${data.first_name ? data.first_name : ""} ${data.last_name ? data.last_name : ""
              }`}
          </span>
        ),
        value: data.id,
      })
    );

  const [deleteData, setDeleteData] = useState(false);
  const [columns, setColumns] = useState(initialColumns);
  const defaultCheckedList = columns.map((item) => item.key);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [radioButtonvalue, setRadioButtonValue] = useState(1);
  const [transferValue, setTransferValue] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [exportAll, setExportAll] = useState(false);

  const handleFilter = () => {
    setFilterModal(true);
    dispatch(actionGetRoles());
    dispatch(actionGetAllRoleProfile());
    dispatch(actionGetCompanyAllUsers());
    dispatch(actionGetCountries());
  };

  const handleApplyFilter = (values) => {
    setFilterValues(values);
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetCompanyUsers({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetCompanyUsers({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleRefresh = () => {
    setSearch(null);
    dispatch(
      actionGetCompanyUsers({
        offset: offsetRef.current,
      })
    );
  };

  const onClickDeactivate = () => {
    const req = {
      users:
        selectedRowKeys &&
        selectedRowKeys.length > 0 &&
        selectedRowKeys.filter((row) => {
          return (
            row !== user.company_user &&
            !user.company_users.some((company) => company?.id === row)
          );
        }),
    };
    dispatch(
      actionDeactivateUser({
        req,
        setSelectedRowKeys,
      })
    );
  };

  const handleDownload = () => {
    console.log("Download button clicked!");
  };

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  const onChangeRadioValue = (e) => {
    setRadioButtonValue(e.target.value);
  };

  const onSelect = (value) => {
    setTransferValue(value);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const exportCurrentPageToExcel = () => {
    const data =
      companyUsers &&
      companyUsers.map((user) => ({
        "First Name": user.first_name ? user.first_name : "-",
        "Last Name": user.last_name ? user.last_name : "-",
        Email: user.email ? user.email : "-",
        "Report To": user.reports_to
          ? user.reports_to.first_name && user.reports_to.last_name
            ? user.reports_to.first_name + " " + user.reports_to.last_name
            : user.reports_to.last_name
          : "-",
        Status: user.status ? user.status : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Users.xlsx");
  };

  const exportAllToExcel = async () => {
    const data =
      (await companyAllUsers) &&
      companyAllUsers.length > 0 &&
      companyAllUsers.map((user) => ({
        "First Name": user.first_name ? user.first_name : "-",
        "Last Name": user.last_name ? user.last_name : "-",
        Email: user.email ? user.email : "-",
        "Report To": user.reports_to
          ? user.reports_to?.first_name && user.reports_to?.last_name
            ? user.reports_to?.first_name + " " + user.reports_to?.last_name
            : user.reports_to.first_name
          : "-",
        Status: user.status ? user.status : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Users.xlsx");
    dispatch(actionClearUsersAllData({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && companyAllUsers && companyAllUsers.length > 0) {
      exportAllToExcel();
    }
  }, [companyAllUsers]);

  const onClickDownloadMenu = ({ key }) => {
    if (key === "1") {
      exportCurrentPageToExcel();
    } else {
      dispatch(actionGetCompanyAllUsers());
      setExportAll(true);
    }
  };

  const items = [
    {
      label: "Export This Page",
      key: "1",
      icon: <SiMicrosoftexcel color="green" />,
    },
    {
      label: "Export All",
      key: "2",
      icon: <SiMicrosoftexcel color="green" />,
    },
  ];

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(val));
    form.setFieldsValue({
      state_id: null,
    });
  };

  const roleData = [];
  const roleProfileData = [];
  const reportsToData = [];
  const countriesData = [];
  const stateData = [];

  roles &&
    roles.map((data) =>
      roleData.push({
        label: data.name,
        value: data.id,
      })
    );
  roleAllProfile &&
    roleAllProfile.map((data) =>
      roleProfileData.push({
        label: data.name,
        value: data.id,
      })
    );
  companyAllUsers &&
    companyAllUsers.map((data) =>
      reportsToData.push({
        label: (
          <span>
            <Avatar size={35} src={data?.profile_pic} className="mr-2" />
            {`${data.first_name ? data.first_name : ""} ${data.last_name ? data.last_name : ""
              }`}
          </span>
        ),
        value: data.id,
        search: data.first_name && data.last_name,
      })
    );

  countries &&
    countries.map((data) =>
      countriesData.push({
        label: data.name,
        value: data.id,
      })
    );

  states &&
    states.map((data) =>
      stateData.push({
        label: data.name,
        value: data.id,
      })
    );

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetCompanyUsers({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
    // setSortedInfo(sorterParams);
  };

  return (
    <section className="main-wrapper">
      <PageTitle
        image={User}
        title="Users"
        buttonText="Add User"
        buttonLink="/create-user"
      />

      <CustomInputWithButtons
        handleFilter={handleFilter}
        handleRefresh={handleRefresh}
        handleDownload={handleDownload}
        columns={columns}
        filterValues={filterValues}
        setColumns={setColumns}
        setCheckedList={setCheckedList}
        checkedList={checkedList}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
        isDeactivate={selectedRowKeys && selectedRowKeys.length > 1}
        onClickDeactivate={onClickDeactivate}
        items={items}
        onClickDownloadMenu={onClickDownloadMenu}
      />

      <Table
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        rowSelection={rowSelection}
        rowKey={(record) => record.id}
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        columns={newColumns}
        dataSource={checkedList.length <= 0 ? [] : companyUsers}
        loading={getCompanyUserLoader || getCompanyAllUserLoader}
      />
      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={companyUsersCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            dispatch(
              actionGetCompanyUsers({ offset: offsetRef.current, search })
            );
          }}
        />
      </div>

      {/* DELETE MODAL START */}
      <Modal
        title={
          <p className="font-popinsMedium text-lg">{`Delete ${deleteData.first_name ? deleteData.first_name : ""
            } ${deleteData.last_name ? deleteData.last_name : ""}`}</p>
        }
        centered
        open={deleteData}
        width={700}
        onOk={() => setDeleteData(false)}
        onCancel={() => setDeleteData(false)}
        footer={[
          <Button key="back" onClick={() => setDeleteData(false)}>
            Cancle
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger
          //loading={loading}
          //onClick={handleOk}
          >
            Delete
          </Button>,
        ]}
      >
        <p className="font-popinsMedium mt-2">Select a deletion option</p>
        <Radio.Group
          onChange={onChangeRadioValue}
          value={radioButtonvalue}
          className="mt-4"
        >
          <Space direction="vertical">
            <Radio value={1}>Delete without transfer</Radio>
            <Radio value={2}>Transfer now</Radio>
          </Space>
        </Radio.Group>
        {radioButtonvalue == 2 && (
          <div className="mt-3 ml-6 flex items-center">
            <p className="font-popinsRegular">Transfer To</p>
            <Select
              className="ml-2 modal-select"
              showSearch
              placeholder="Transfer to"
              allowClear
              onClear={() => setTransferValue("")}
              onChange={onSelect}
              filterOption={(input, option) =>
                (option?.value ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={transferOptions}
              disabled={getCompanyAllUserLoader}
            />
          </div>
        )}
        <div className="mt-5 border border-red-300 rounded-xl bg-red-50 p-5">
          <p className="font-popinsMedium text-base">Note</p>
          <p className="font-popinsRegular ml-1 mt-2 text-[#505050] text-[13]">
            • If the deleted user has integrated any additional applications
            with this CRM account, those integrations will be disabled
          </p>
          {radioButtonvalue == 1 && (
            <>
              <p className="font-popinsRegular ml-1 mt-2 text-[#505050] text-[13]">
                • All the associates data will be removed permanently and cannot
                be recovered anymore.
              </p>
            </>
          )}
          {radioButtonvalue == 2 && (
            <>
              <p className="font-popinsRegular text-base mt-2">
                Record ownership
              </p>
              <p className="font-popinsRegular ml-1 mt-1 text-[#505050] text-[13]">
                • All open records associated with the deleted user will be
                transferred to the new user
              </p>
            </>
          )}
        </div>
      </Modal>
      {/* DELETE MODAL ENDS */}

      <Modal
        width={1000}
        centered
        open={filterModal}
        onOk={() => setFilterModal(false)}
        onCancel={() => setFilterModal(false)}
        footer={false}
      >
        <section className="main-section">
          <div className="mb-4">
            <CustomIconText
              icon={<LuFilter size={25} color="#6883fd" />}
              title="Users Filter"
            />
          </div>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            onFinish={handleApplyFilter}
            autoComplete="off"
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Role" name="role_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Role"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={roleData}
                    disabled={getRolesLoader}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Role Profile" name="role_profile_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Role Profile"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={roleProfileData}
                    disabled={getAllRoleProfileLoader}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Report To" name="reports_to_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Report To"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.search ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={reportsToData}
                    disabled={getCompanyAllUserLoader}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Country" name="country_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Country"
                    optionFilterProp="children"
                    onSelect={handleGetState}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={countriesData}
                    disabled={getCountryLoader}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="State" name="state_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={stateData}
                    disabled={statesLoader}
                  />
                </Form.Item>
              </Col>

              <Col
                span={24}
                className="flex items-center my-4 justify-center gap-x-6"
              >
                <Form.Item>
                  <Button
                    type="button"
                    className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                    onClick={() => {
                      form.resetFields();
                      setFilterValues("");
                      dispatch(
                        actionGetCompanyUsers({ offset: offsetRef.current })
                      );
                    }}
                  >
                    Reset
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    //loading={addRoleLoader || updateRoleLoader}
                    type="primary"
                    className="root-btn  min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                  >
                    Apply
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </section>
      </Modal>
    </section>
  );
};

export default Users;
