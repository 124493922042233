import React from "react";
import CustomIconText from "../common/CustomIconText";
import settingImg from "../../assets/img/setting.svg";
import { Card, Col, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useSelector } from "react-redux";

const Setting = () => {
  const navigate = useNavigate();
  const { user ,currentCompanyUser} = useSelector((state) => state.auth);
  const handleCustomFields = () => {
    toast.info("Hello World!",);
  };

 

  const handleNavigation = (url = "product-masters", type) => {
    navigate(`/${url}`, {
      state: { type },
    });
  };

  const settingsData = [
    {
      title: "General",
      links: [
        {
          type: "action",
          text: "Personal Setting",
          action: () =>
            navigate("/update-user", {
              state: {
                userId: currentCompanyUser?.id,
                accountType: "OWN_ACCOUNT",
              },
            }),
        },
        { text: "Users", url: "/users" },
        { text: "Company Setting", url: "/company-setting" },
      ],
    },
    {
      title: "Location",
      links: [
        // { text: "Country", url: "/country" },
        // { text: "Country", url: "/" },
        // { text: "State", url: "/state" },
        // { text: "State", url: "/" },
        // { text: "City", url: "/city" },
        // { text: "City", url: "/" },
        {
          text: "Zone",
          type: "action",
          action: () => handleNavigation("general-masters", "zone"),
        },
        {
          text: "Area",
          type: "action",
          action: () => handleNavigation("general-masters", "area"),
        },
        // { text: "Territory", url: "/territory" },
        // { text: "Territory", url: "/" },
      ],
    },
  ];

  const configurations = [
    {
      title: "Security Control",
      links: [
        { text: "Role Profile", url: "/role-profile" },
        { text: "Roles", url: "/role-hierarchy" },
        // { text: "Login History", url: "/login-logs" },
        // { text: "Audit Log", url: "/audit-log" },
      ],
    },
    {
      title: "Customization",
      links: [
        { type: "action", text: "Custom Fields", action: handleCustomFields },
        {
          text: "Products",
          type: "action",
          action: () => handleNavigation("product-masters", "product"),
        },
        {
          text: "Product Types",
          type: "action",
          action: () => handleNavigation("product-masters", "product-type"),
        },
        {
          text: "Category",
          type: "action",
          action: () => handleNavigation("product-masters", "product-category"),
        },
        {
          text: "Sub Category",

          type: "action",
          action: () =>
            handleNavigation("product-masters", "product-sub-category"),
        },
        {
          text: "Units",

          type: "action",
          action: () => handleNavigation("product-masters", "unit"),
        },
        {
          text: "HSN / SAC Code",

          type: "action",
          action: () => handleNavigation("product-masters", "hsn-sac-code"),
        },
        // { text: "Clusters", url: "/clusters" },
      ],
    },
  ];

  const renderSettingCards = (data) =>
    data.map((setting, index) => (
      <Col sm={12} xs={24}  md={12} lg={6} xl={6}  xxl={5} className="lg:mb-0 mb-3" key={index}>
        <Card
          bordered
          style={{
            border: "1px solid #dddae9",
            minHeight: 350,
            borderRadius: 10,
          }}
        >
          <p className="text-xl font-popinsSemiBold">{setting.title}</p>
          <div className="flex flex-col pt-4 gap-y-2.5">
            {setting.links.map((link, linkIndex) => {
              return link.type === "action" ? (
                <div
                  onClick={link.action}
                  key={linkIndex}
                  className="cursor-pointer text-base text-[#7C7C7C] font-popinsRegular hover:text-brand"
                >
                  {link.text}
                </div>
              ) : (
                <Link
                  key={linkIndex}
                  to={link.url}
                  className="text-base text-[#7C7C7C] font-popinsRegular hover:text-brand"
                >
                  {link.text}
                </Link>
              );
            })}
          </div>
        </Card>
      </Col>
    ));

  return (
    <section className="main-section">
      <CustomIconText title={"Preference"} image={settingImg} />

      <Row gutter={24} className="my-5">
        {renderSettingCards(settingsData)}
      </Row>
      <Row gutter={24} className="my-10">
        {renderSettingCards(configurations)}
      </Row>
    </section>
  );
};

export default Setting;
