import React, { useEffect, useState } from "react";
import expensefirstdetail from "../../../assets/img/expensefirstdetail.svg";
import leftArrowPrimary from "../../../assets/img/leftArrowPrimary.svg";
import historyexpense from "../../../assets/img/history-expense.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Image, Table, Timeline, Avatar, Empty, Tooltip } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { actionGetExpenseVoucherDetails } from "../../../store/services/financeService";
import Typography from "antd/es/typography/Typography";

const ViewExpenseVoucher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  const dispatch = useDispatch();
  const [selectItem, setSelectItem] = useState("expanses");
  const { getExpenseVoucherDetailLoader, expenseVoucherDetailData } =
    useSelector((state) => state.finance);
  const { currentCompanyUser } = useSelector((state) => state.auth);
  console.log(currentCompanyUser?.company?.currency);

  if (!id && !getExpenseVoucherDetailLoader) {
    return <Empty description={"No data found"} />;
  }

  useEffect(() => {
    if (id) {
      dispatch(actionGetExpenseVoucherDetails(id));
    }
  }, [id, dispatch]);

  const columns = [
    {
      title: "Receipt",
      dataIndex: "receipt_attachments",
      key: "receipt",
      width: 150,
      fixed: "left",
      ellipsis: true,
      render: (text, data) => {
        return data.receipt_attechments &&
          data.receipt_attechments.length > 0 ? (
          <Image.PreviewGroup
            items={data.receipt_attechments.map((item) => ({
              src: `${item.upload.url}`,
            }))}
          >
            <Image
              width={45}
              height={45}
              className="object-contain"
              src={`${data.receipt_attechments[0].upload.url}`}
              style={{
                objectFit: "cover",
              }}
            />
          </Image.PreviewGroup>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "expense_category_type",
      key: "expense_category_type",
      width: 200,
      ellipsis: true,
      render: (text, data) =>
        data.expense_category_type_master &&
        data.expense_category_type_master?.expense_category_type
          ? data.expense_category_type_master?.expense_category_type
          : "-",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 300,
      ellipsis: true,
      render: (text, data) => {
        const amountInBaseCurr = data?.amount * data.amount_currency_rate;
        const { currency_symbol: userCurrencySymbol, currency: userCurrency } =
          currentCompanyUser?.company?.currency || {};

        const { currency_symbol, currency } = data?.amount_currency || {};
        const displayText =
          currency !== userCurrency
            ? `Amount in ${userCurrency}: ${userCurrencySymbol}${amountInBaseCurr.toFixed(
                2
              )} (1 ${currency} = ${data.amount_currency_rate} ${userCurrency})`
            : "";
        return (
          <>
            <p>
              <span
                style={{
                  fontFamily: "Arial, sans-serif",
                }}
              >
                {currency_symbol !== null ? currency_symbol : ""}
              </span>
              {` ${data.amount || ""}`}
            </p>

            <Typography.Paragraph
              ellipsis={{ rows: 1, tooltip: true }}
              style={{ width: 280, margin: 0 }}
              className="ml-2"
            >
              {displayText}
            </Typography.Paragraph>
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <span
          className="ml-2"
          style={{
            display: "block",
            maxWidth: 200,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Typography.Paragraph
            ellipsis={{
              tooltip: data.description ? data.description : "-",
            }}
            style={{ margin: 0 }}
          >
            {data.description ? data.description : "-"}
          </Typography.Paragraph>
        </span>
      ),
    },

    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      width: 200,
      ellipsis: true,
      render: (text, data) =>
        data.payment_method_master && data.payment_method_master?.payment_method
          ? data.payment_method_master?.payment_method
          : "-",
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <span className="ml-2">
          {data.supplier_name ? data.supplier_name : "-"}
        </span>
      ),
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <span className="ml-2">
          {data.invoice_number ? data.invoice_number : "-"}
        </span>
      ),
    },
  ];

  const items = [
    {
      label: "Expenses",
      key: "expanses",
    },
    {
      label: "History",
      key: "history",
    },
  ];

  const changeExpenseHistoryTab = () => {
    switch (selectItem) {
      case "expanses":
        return (
          <>
            <Table
              className="mt-4 expense-items-table"
              sticky
              rowKey="expense_voucher_items_id"
              scrollToFirstRowOnChange={true}
              sortDirections={["ascend", "descend"]}
              bordered
              virtual
              scroll={{
                y: 600,
              }}
              pagination={{
                hideOnSinglePage: true,
              }}
              columns={columns}
              loading={getExpenseVoucherDetailLoader}
              dataSource={expenseVoucherDetailData.expense_voucher_items}
            />

            <div className="flex justify-between mt-3 bg-transparent">
              <p className="text-[15px] font-popinsMedium">
                Total Expense Voucher Detail :{" "}
                {expenseVoucherDetailData.expense_voucher_items_count
                  ? expenseVoucherDetailData.expense_voucher_items_count
                  : 0}
              </p>
            </div>
          </>
        );

      case "history":
        return (
          <>
            <div className="mt-8 remove-bg-dot">
              <Timeline
                key="expense_voucher_log_id"
                className="remove-bg-dot"
                items={
                  expenseVoucherDetailData.expense_voucher_log &&
                  expenseVoucherDetailData.expense_voucher_log.length > 0
                    ? expenseVoucherDetailData.expense_voucher_log.map(
                        (logItem) => ({
                          key: logItem.expense_voucher_log_id,
                          dot: (
                            <Avatar
                              src={logItem.created_by.profile_pic}
                              alt="history expense"
                              className="border-[2px] border-[#6883FD]"
                            />
                          ),
                          children: (
                            <>
                              <p className="text-[#7C7C7C] text-[15px] ml-2">
                                <span className="font-popinsSemiBold">
                                  {logItem.created_by.full_name ?? "-"}
                                </span>{" "}
                                {dayjs(logItem.created_at).format("DD/MM/YYYY")}
                              </p>

                              <span className="text-[15px] ml-2">
                                {logItem.log ?? "-"}
                              </span>
                            </>
                          ),
                        })
                      )
                    : [
                        {
                          dot: <img src={historyexpense} alt="default dot" />,
                          children: (
                            <p className="text-[#7C7C7C] text-[15px]">no log</p>
                          ),
                        },
                      ]
                }
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const getStatusStyles = (status) => {
    switch (status?.toLowerCase()) {
      case "submitted":
        return "border-yellow-500 text-yellow-500 bg-yellow-50";
      case "approved":
        return "border-green-500 text-green-500 bg-green-50";
      case "unsubmitted":
        return "border-gray-500 text-gray-500 bg-gray-50";
      case "rejected":
        return "border-red-500 text-red-500 bg-red-50";
      case "reimbursed":
        return "border-blue-500 text-blue-500 bg-blue-50";
      case "pending approval":
        return "border-orange-500 text-orange-500 bg-orange-50";
      case "awaiting approval":
        return "border-purple-500 text-purple-500 bg-purple-50";
      default:
        return "border-gray-300 text-white-500 bg-white";
    }
  };

  return (
    <>
      <section className="main-section px-4 custom-color" id="overview">
        <div className="flex items-center gap-x-5">
          <div
            className="flex items-center gap-x-3 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <div className="rounded-[10px] flex items-center">
              <Image
                src={leftArrowPrimary}
                alt="image"
                height={20}
                width={20}
                preview={false}
              />
            </div>
            <h1 className="lg:text-lg text-base text-primary/90">
              Expense Voucher
            </h1>
          </div>
          <span
            className={`border-[0.5px] py-1 rounded-[5px] px-2 text-sm ${getStatusStyles(
              expenseVoucherDetailData?.status?.toLowerCase() === "submitted"
                ? expenseVoucherDetailData.approve_by?.id ===
                  currentCompanyUser?.id
                  ? "pending approval"
                  : "awaiting approval"
                : expenseVoucherDetailData.status
            )} uppercase rounded-md`}
          >
            {expenseVoucherDetailData?.status?.toLowerCase() === "submitted"
              ? expenseVoucherDetailData.approve_by?.id ===
                currentCompanyUser?.id
                ? "PENDING APPROVAL"
                : "AWAITING APPROVAL"
              : expenseVoucherDetailData.status}
          </span>
        </div>

        {expenseVoucherDetailData.status === "Reimbursed" && (
          <div className="mt-4  ant-card-custom">
            <Card className="rounded-lg">
              <div className="bg-[#E9FBF8] rounded-t-lg">
                <div className="flex items-center px-4 py-3 border-b border-b-[#dddae9] border-gray-200">
                  <img
                    src={expensefirstdetail}
                    alt="Reimbursement Icon"
                    className="w-6 h-6"
                  />
                  <p className="text-[#0299C8] ml-3 text-[15px] font-medium">
                    Reimbursement Details
                  </p>
                </div>
              </div>
              <div className="px-0 py-4">
                <div className="flex bg-white items-center justify-between">
                  <div className="flex justify-between pr-9 pl-7 py-3 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black">
                        Reimbursed Date:
                      </p>
                      <p className="text-[14px] mt-2 text-[#7C7C7C] text-start">
                        {expenseVoucherDetailData.expense_voucher_reimbusred
                          .reimbursed_date ?? "-"}
                      </p>
                    </div>
                  </div>
                  <div className="border-l border-[#DDDAE9] h-12"></div>
                  <div className=" flex justify-between px-9 py-3 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black text-align: start">
                        Reference#:
                      </p>
                      <p className="text-[14px] mt-2 text-[#7C7C7C] text-start">
                        {expenseVoucherDetailData.expense_voucher_reimbusred
                          .reference ?? "-"}
                      </p>
                    </div>
                  </div>
                  <div className="border-l border-[#DDDAE9] h-12"></div>
                  <div className="flex justify-between px-9 py-3 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black">
                        Paid Through:
                      </p>
                      <p className="text-[14px] mt-2 text-[#7C7C7C] text-start">
                        {expenseVoucherDetailData.expense_voucher_reimbusred
                          .paid_via ?? "-"}
                      </p>
                    </div>
                  </div>
                  <div className="border-l border-[#DDDAE9] h-12"></div>{" "}
                  <div className="flex-1 flex justify-between px-9 py-3 items-center">
                    <div className="flex-1 flex justify-between  py-3 items-center">
                      <div className="flex flex-col">
                        <p className="text-base font-popinsMedium text-black">
                          Note:
                        </p>
                        <p className="text-[14px] mt-2 text-[#7C7C7C] text-start">
                          {/*                           
                          {getTruncatedText(
                            expenseVoucherDetailData.expense_voucher_reimbusred
                              .note,
                            50
                          )} */}
                          <span
                            className=""
                            style={{
                              display: "block",
                              maxWidth: 225,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Typography.Paragraph
                              ellipsis={{
                                tooltip: expenseVoucherDetailData
                                  .expense_voucher_reimbusred.note
                                  ? expenseVoucherDetailData
                                      .expense_voucher_reimbusred.note
                                  : "-",
                              }}
                              style={{ margin: 0 }}
                            >
                              {expenseVoucherDetailData
                                .expense_voucher_reimbusred.note
                                ? expenseVoucherDetailData
                                    .expense_voucher_reimbusred.note
                                : "-"}
                            </Typography.Paragraph>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}

        <div className="mt-4">
          <Card className="">
            <div className="flex justify-between items-center">
              <div>
                <div className="flex bg-white items-center">
                  <div className="flex pr-5 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        Voucher No:
                        <span className="text-[#7C7C7C] ml-2">
                          {/* NE-954359-215975 */}
                          {expenseVoucherDetailData.voucher_no ?? "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="border-l border-[#DDDAE9] h-5"></div>
                  <div className=" flex  px-5 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        Date:
                        <span className="text-[#7C7C7C] ml-2">
                          {" "}
                          {expenseVoucherDetailData.voucher_date ?? "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="border-l border-[#DDDAE9] h-5"></div>
                  <div className="flex  px-5 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        City:
                        <span className="text-[#7C7C7C] ml-2">
                          {expenseVoucherDetailData.city &&
                          expenseVoucherDetailData.city.trim() !== ""
                            ? expenseVoucherDetailData.city
                            : "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col   mt-3">
                  <div className="text-base font-popinsMedium text-black flex items-center">
                    <p> Approver:</p>
                    <div className="h-[24px] w-[24px] ml-2 inline-block">
                      <img
                        src={
                          expenseVoucherDetailData.approve_by &&
                          expenseVoucherDetailData.approve_by?.profile_pic
                            ? expenseVoucherDetailData.approve_by?.profile_pic
                            : "-"
                        }
                        className="rounded-full w-full h-full"
                      />
                    </div>
                    <span className="text-[#7C7C7C] ml-2">
                      {expenseVoucherDetailData.approve_by &&
                      expenseVoucherDetailData.approve_by?.full_name
                        ? expenseVoucherDetailData.approve_by?.full_name
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-base font-popinsMedium text-black flex justify-end">
                  Total
                </p>
                <span className="text-[#6883FD] font-popinsSemiBold ml-2 text-[20px]">
                  <span
                    style={{
                      fontFamily: "Arial, sans-serif",
                    }}
                  >
                    {currentCompanyUser.company?.currency &&
                    currentCompanyUser.company?.currency !== null
                      ? currentCompanyUser.company.currency.currency_symbol
                      : ""}
                  </span>
                  {` ${
                    expenseVoucherDetailData?.expense_voucher_items_amount_count ||
                    ""
                  }`}
                </span>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4 flex items-center">
          <p className="text-base font-popinsMedium text-black text-[15px]">
            Note:
          </p>
          <span className="text-[#7C7C7C] ml-2 text-[15px]">
            {expenseVoucherDetailData.notes &&
            expenseVoucherDetailData.notes.trim() !== ""
              ? expenseVoucherDetailData.notes
              : "-"}
          </span>
        </div>

        <div className="mt-4">
          <div className="flex flex-col border border-[#DDDAE9] rounded-[10px] bg-white mt-5 w-[35%] px-6">
            <div className="flex items-center justify-center mt-2">
              {items.map(({ key, label }) => (
                <div
                  key={key}
                  className={`flex-1 text-center text-[15px] font-medium cursor-pointer  ${
                    selectItem === key
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : "text-black"
                  }`}
                  onClick={() => setSelectItem(key)}
                >
                  <h1 className="mb-[7px]">{label}</h1>
                </div>
              ))}
            </div>
          </div>

          <div>{changeExpenseHistoryTab()}</div>
        </div>
      </section>
    </>
  );
};

export default ViewExpenseVoucher;
