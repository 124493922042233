import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import locationSlice from "./slices/locationSlice";
import settingSlice from "./slices/settingSlice";
import usersSlice from "./slices/usersSlice";
import masterDataSlice from "./slices/masterDataSlice";
import eventSlice from "./slices/eventSlice";
import leadsSlice from "./slices/leadsSlice";
import generalMasterSlice from "./slices/generalMasterSlice";
import opportunitySlice from "./slices/opportunitySlice";
import productMasterSlice from "./slices/productMasterSlice";
import contactSlice from "./slices/contactSlice";
import salesSlice from "./slices/salesSlice";
import taskSlice from "./slices/taskSlice";
import financeSlice from "./slices/financeSlice";

const isDevelopment = process.env.REACT_APP_ENV_MODE === "DEV";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    location: locationSlice,
    setting: settingSlice,
    users: usersSlice,
    masterData: masterDataSlice,
    events: eventSlice,
    leads: leadsSlice,
    generalMaster: generalMasterSlice,
    opportunities: opportunitySlice,
    productMaster: productMasterSlice,
    contact: contactSlice,
    sales: salesSlice,
    tasks: taskSlice,
    finance: financeSlice,
  },
  devTools: isDevelopment,
});
