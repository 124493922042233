import { createSlice } from "@reduxjs/toolkit";
import {
  actionClearAllExpenseVouchers,
  actionDeleteExpenseVoucher,
  actionGetAllExpenseVouchers,
  actionGetCurrencyExchangeMaster,
  actionGetExpenseVouchers,
  actionGetExpenseVoucherDetails,
  actionAddExpenseVoucher,
  actionUpdateExpenseVoucher,
  actionChangeExpenseVoucherStatus,
  actionGetExpenseItemsBySupplierName,
} from "../services/financeService";

const financeSlice = createSlice({
  name: "finance",
  initialState: {
    getExpenseVouchersLoader: false,
    getAllExpenseVouchersLoader: false,
    getExpenseVoucherDetailLoader: false,
    addExpenseVoucherLoader: false,
    updateExpenseVoucherLoader: false,
    deleteExpenseVoucherLoader: false,
    updateExpenseVoucherLoader: false,
    changeStatusLoader: false,
    expenseVoucherData: [],
    allExpenseVoucherData: [],
    expenseVoucherDetailData: "",
    expenseVoucherDataCount: "",
    supplierData: [],
    getSupplierLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get Expense Vouchers reducers
      .addCase(actionGetExpenseVouchers.pending, (state) => {
        state.getExpenseVouchersLoader = true;
      })
      .addCase(actionGetExpenseVouchers.fulfilled, (state, action) => {
        state.getExpenseVouchersLoader = false;
        state.expenseVoucherData = action.payload.data;
        state.expenseVoucherDataCount = action.payload.total_records;
      })
      .addCase(actionGetExpenseVouchers.rejected, (state) => {
        state.getExpenseVouchersLoader = false;
      })

      //get single expense voucher start here
      .addCase(actionGetExpenseVoucherDetails.pending, (state, action) => {
        state.getExpenseVoucherDetailLoader = true;
      })
      .addCase(actionGetExpenseVoucherDetails.fulfilled, (state, action) => {
        state.getExpenseVoucherDetailLoader = false;
        state.expenseVoucherDetailData = action.payload.data;
      })
      .addCase(actionGetExpenseVoucherDetails.rejected, (state, action) => {
        state.getExpenseVoucherDetailLoader = false;
      })

      .addCase(actionAddExpenseVoucher.pending, (state) => {
        state.addExpenseVoucherLoader = true;
      })
      .addCase(actionAddExpenseVoucher.fulfilled, (state, action) => {
        state.addExpenseVoucherLoader = false;

        if (
          !state.expenseVoucherData ||
          !Array.isArray(state.expenseVoucherData)
        ) {
          state.expenseVoucherData = [action.payload];
        } else {
          state.expenseVoucherData.unshift(action.payload);
        }
      })
      .addCase(actionAddExpenseVoucher.rejected, (state) => {
        state.addExpenseVoucherLoader = false;
      })

      .addCase(actionUpdateExpenseVoucher.pending, (state) => {
        state.updateExpenseVoucherLoader = true;
      })
      .addCase(actionUpdateExpenseVoucher.fulfilled, (state, action) => {
        state.updateExpenseVoucherLoader = false;

        /*   state.expenseVoucherData = state.expenseVoucherData.map((item) =>
          item.expense_voucher_id === action.payload.expense_voucher_id
            ? { ...item, ...action.payload }
            : item
        ); */
      })
      .addCase(actionUpdateExpenseVoucher.rejected, (state) => {
        state.updateExpenseVoucherLoader = false;
      })

      // Clear All Expense Vouchers Data
      .addCase(actionClearAllExpenseVouchers.fulfilled, (state, action) => {
        state.allExpenseVoucherData = action.payload;
      })
      // actionGetAllExpenseVouchers State
      .addCase(actionGetAllExpenseVouchers.pending, (state) => {
        state.getAllExpenseVouchersLoader = true;
      })
      .addCase(actionGetAllExpenseVouchers.fulfilled, (state, action) => {
        state.getAllExpenseVouchersLoader = false;
        state.allExpenseVoucherData = action.payload.data;
      })
      .addCase(actionGetAllExpenseVouchers.rejected, (state) => {
        state.getAllExpenseVouchersLoader = false;
      })

      .addCase(actionDeleteExpenseVoucher.pending, (state) => {
        state.deleteExpenseVoucherLoader = true;
      })
      .addCase(actionDeleteExpenseVoucher.fulfilled, (state, action) => {
        state.deleteExpenseVoucherLoader = false;
        state.expenseVoucherData = state.expenseVoucherData.filter(
          (item) => item.expense_voucher_id !== action.payload
        );
      })
      .addCase(actionDeleteExpenseVoucher.rejected, (state) => {
        state.deleteExpenseVoucherLoader = false;
      })

      .addCase(actionChangeExpenseVoucherStatus.pending, (state) => {
        state.changeStatusLoader = true;
      })
      .addCase(actionChangeExpenseVoucherStatus.fulfilled, (state, action) => {
        const { ids, status } = action.payload;
        state.changeStatusLoader = false;
        state.expenseVoucherData = state.expenseVoucherData.map((item) =>
          ids.includes(item.expense_voucher_id)
            ? { ...item, status: status }
            : item
        );
      })
      .addCase(actionChangeExpenseVoucherStatus.rejected, (state) => {
        state.changeStatusLoader = false;
      })

      .addCase(actionGetExpenseItemsBySupplierName.pending, (state) => {
        state.getSupplierLoader = true;
      })
      .addCase(
        actionGetExpenseItemsBySupplierName.fulfilled,
        (state, action) => {
          state.getSupplierLoader = false;
          state.supplierData = action.payload.data;
        }
      )
      .addCase(actionGetExpenseItemsBySupplierName.rejected, (state) => {
        state.getSupplierLoader = false;
      });
  },
});
export default financeSlice.reducer;
