import React from "react";

const EventDescriptionCard = ({title,value}) => {
  return (
    <div className="flex  gap-x-10 items-center w-full gap-y-1">
      <p className="text-xs text-[#818181] w-[30%]">{title}:</p>
      <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
        {/* +91 8586291612 */}
        {value ? value : "-"}
      </p>
    </div>
  );
};

export default EventDescriptionCard;
