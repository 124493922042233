import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Image,
  Pagination,
  Table,
  Modal,
  Form,
  Row,
  Col,
  Upload,
  Input,
  Spin,
  Popconfirm,
  Select,
  Popover,
} from "antd";
import ImgCrop from "antd-img-crop";
import PageTitle from "../../common/PageTitle";
import { useRef, useState, useEffect } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import addbutton from "../../../assets/img/add-button.svg";
import exibhitorprofile from "../../../assets/img/exibhitorprofile.svg";
import viewexhibitors from "../../../assets/img/viewexhibitors.svg";
import editPrimary from "../../../assets/img/editPrimary.svg";
import dummy from "../../../assets/img/dummy.png";
import plusSvg from "../../../assets/img/plus.svg";
import exibhitors from "../../../assets/img/exibhitors.svg";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import SortIcon from "../../common/SortIcon";
import EventDetailWrapper from "./EventDetailWrapper";
import CommonModal from "../../common/CommonModal";
import * as XLSX from "xlsx";
import {
  actionGetEventExhibitors,
  actionEventExhibitorDetail,
  actionDeleteEventExhibitor,
  actionAddEventExhibitor,
  actionUpdateEventExhibitor,
  actionGetAllEventExhibitors,
  actionClearAllEventExhibitorsData,
} from "../../../store/services/eventService";
import {
  extractFilename,
  formatUrl,
  getEventId,
  getFirstLetterCapital,
} from "../../helper/common-function";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import { toast } from "sonner";
import {
  actionGetExhibitionCategoryMaster,
  actionAddExhibitionCategoryMaster,
} from "../../../store/services/masterDataService";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Exhibitors = () => {
  const dispatch = useDispatch();
  const eventId = getEventId();
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [exhibitorData, setExhibitorData] = useState(null);
  const [isExhibitorCategoryOpen, setIsExhibitorCategoryModalOpen] =
    useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [exhibitorCategoryModalForm] = Form.useForm();
  const offsetRef = useRef(0);
  const {
    eventExhibitorsData,
    getExhibitorsLoader,
    exhibitorsDataCount,
    getExhibitorDetail,
    getExhibitorDetailLoader,
    deleteExhibitorLoader,
    allExhibitorsData,
    updateExhibitorLoader,
    addExhibitorLoader,
    getAllExhibitorsLoader,
  } = useSelector((state) => state.events);
  const {
    exhibitionCategoryData,
    getExhibitionCategoryLoader,
    addExhibitionCategoryLoader,
  } = useSelector((state) => state.masterData);

  useEffect(() => {
    if (eventId) {
      dispatch(
        actionGetEventExhibitors({ offset: offsetRef.current, search, eventId })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (exhibitorData) {
      dispatch(
        actionEventExhibitorDetail({
          id: exhibitorData.event_exhibitor_id,
          eventId,
        })
      );
    }
  }, [dispatch, exhibitorData]);
  

  useEffect(() => {
    if (exhibitorData && getExhibitorDetail) {
      form.setFieldsValue({
        exhibitor_name: getExhibitorDetail.exhibitor_name,
        exhibitor_contact_person: getExhibitorDetail.exhibitor_contact_person,
        exhibitor_contact_person_email:
          getExhibitorDetail.exhibitor_contact_person_email,
        exhibitor_contact_person_phone:
          getExhibitorDetail.exhibitor_contact_person_phone,
        booth_number: getExhibitorDetail.booth_number,
        exhibitor_category_id:
          getExhibitorDetail.exhibitor_category
            ?.event_exhibitor_category_master_id,
        exhibitor_website: getExhibitorDetail.exhibitor_website,
      });
      setProfilePicUrl(getExhibitorDetail.exhibitor_image);
    }
  }, [getExhibitorDetail]);

  

  const columns = [
    {
      title: "Exhibitor Name & Image",
      key: "1",
      dataIndex: "exhibitor_name",
      fixed: "left",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      width: 250,
      render: (text, data) => (
        <div
          onClick={() => {
            setExhibitorData(data);
            setViewModalOpen(true);
          }}
          className="flex items-center cursor-pointer"
        >
          <Avatar
            style={{ verticalAlign: "middle" }}
            src={data.exhibitor_image}
            size="large"
          />
          <p className="ml-2 text-brand">
            {data.exhibitor_name.charAt(0).toUpperCase() +
              data.exhibitor_name.slice(1)}
          </p>
        </div>
      ),
    },
    {
      title: "Contact Person Name",
      dataIndex: "exhibitor_contact_person",
      key: "2",
      width: 250,
      sorter: (a, b) => a.exhibitor_contact_person - b.exhibitor_contact_person,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.exhibitor_contact_person ? data.exhibitor_contact_person : "-"}
        </span>
      ),
    },
    {
      title: "Contact Person Email",
      key: "3",
      width: 250,
      dataIndex: "exhibitor_contact_person_email",
      sorter: (a, b) =>
        a.exhibitor_contact_person_email - b.exhibitor_contact_person_email,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.exhibitor_contact_person_email
            ? data.exhibitor_contact_person_email
            : "-"}
        </span>
      ),
    },
    {
      title: "Contact Person Phone",
      dataIndex: "exhibitor_contact_person_phone",
      key: "4",
      width: 250,
      sorter: (a, b) =>
        a.exhibitor_contact_person_phone - b.exhibitor_contact_person_phone,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.exhibitor_contact_person_phone
            ? data.exhibitor_contact_person_phone
            : "-"}
        </span>
      ),
    },
    {
      title: "Booth Number",
      dataIndex: "booth_number",
      key: "4",
      width: 180,
      sorter: (a, b) => a.booth_number - b.booth_number,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.booth_number ? data.booth_number : "-"}
        </span>
      ),
    },
    {
      title: "Exhibitor Category",
      dataIndex: "exhibitor_category",
      key: "4",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">
          {data.exhibitor_category &&
          data.exhibitor_category &&
          data.exhibitor_category?.exhibitor_category
            ? data.exhibitor_category?.exhibitor_category
            : "-"}
        </span>
      ),
    },
    {
      title: "Exhibitor Website",
      dataIndex: "exhibitor_website",
      key: "4",
      width: 200,
      sorter: (a, b) => a.exhibitor_website - b.exhibitor_website,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <a
          target="_blank"
          href={formatUrl(
            data &&
              data.exhibitor_website &&
              data.exhibitor_website != null &&
              data.exhibitor_website
          )}
          className="ml-2 text-blue-500"
        >
          {data.exhibitor_website ? data.exhibitor_website : "-"}
        </a>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    setIsModalOpen(true);
                    setExhibitorData(data);
                    dispatch(actionGetExhibitionCategoryMaster());
                  },
                },
                // {
                //   label: <span>View</span>,
                //   key: "View",
                //   icon: <EyeOutlined />,
                //   onClick: () =>
                //     navigate("/update-user", { state: { userId: data.id } }),
                // },
                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteExhibitorLoader,
                      }}
                      placement="topLeft"
                      title={`Are you sure to delete this exhibitor?`}
                      onConfirm={() => {
                        // handleOk
                        dispatch(
                          actionDeleteEventExhibitor({
                            id: data.event_exhibitor_id,
                            event_id: eventId,
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div className="flex items-center">
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span style={{ marginLeft: 8, color: "#ff4d4f" }}>
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ),
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const onFinish = (values) => {
    const fileUrl = profilePicUrl
      ? extractFilename(profilePicUrl && profilePicUrl)
      : "";

    const data = {
      ...values,
      exhibitor_image: fileUrl,
      event_id: eventId,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };

    exhibitorData
      ? dispatch(
          actionUpdateEventExhibitor({
            id: getExhibitorDetail.event_exhibitor_id,
            req,
            handleCancel,
          })
        )
      : dispatch(
          actionAddEventExhibitor({
            req,
            handleCancel,
          })
        );
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);

        setProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setExhibitorData(false);
    form.resetFields();
    setProfilePicUrl("");
  };

  const handleRefresh = () => {
    setSearch(null);
    dispatch(
      actionGetEventExhibitors({
        offset: offsetRef.current,
        eventId,
      })
    );
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetEventExhibitors({
          offset: offsetRef.current,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetEventExhibitors({
          offset: offsetRef.current,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllEventExhibitors({ eventId }));
    setExportAll(true);
  };

  const handleCancelExhibitorCategoryModal = () => {
    setIsExhibitorCategoryModalOpen(false);
    exhibitorCategoryModalForm.resetFields();
  };

  const showExhibitorCategoryOpen = () => {
    setIsExhibitorCategoryModalOpen(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetEventExhibitors({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        eventId,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allExhibitorsData) &&
      allExhibitorsData.length > 0 &&
      allExhibitorsData.map((data) => ({
        "Exhibitor Name": data.exhibitor_name ? data.exhibitor_name : "-",

        "Contact Person Name": data.exhibitor_contact_person
          ? data.exhibitor_contact_person
          : "-",
        "Contact Person Email": data.exhibitor_contact_person_email
          ? data.exhibitor_contact_person_email
          : "-",
        "Contact Person Phone": data.exhibitor_contact_person_phone
          ? data.exhibitor_contact_person_phone
          : "-",
        "Booth Number": data.booth_number ? data.booth_number : "-",
        "Exhibitor Category": data.exhibitor_category?.exhibitor_category
          ? data.exhibitor_category?.exhibitor_category
          : "-",
        "Exhibitor Website": data.exhibitor_website
          ? data.exhibitor_website
          : "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "event-exhibitor.xlsx");
    dispatch(actionClearAllEventExhibitorsData({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allExhibitorsData && allExhibitorsData.length > 0) {
      exportAllToExcel();
    }
  }, [allExhibitorsData]);

  const cateData =
    exhibitionCategoryData &&
    exhibitionCategoryData.length > 0 &&
    exhibitionCategoryData.map((data) => ({
      label: data.exhibitor_category,
      value: data.event_exhibitor_category_master_id,
    }));

  const exhibitoreCategoryPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={exhibitorCategoryModalForm}
          onFinish={(values) =>
            dispatch(
              actionAddExhibitionCategoryMaster({
                form,
                values,
                handleCancel: handleCancelExhibitorCategoryModal,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="exhibitor_category"
            label="Exhibitor Category"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input exhibitor category!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Exhibitor Category"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelExhibitorCategoryModal()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addExhibitionCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  return (
    <EventDetailWrapper>
      <PageTitle
        image={exibhitors}
        title="Exhibitors"
        buttonText="Add Exibhitor"
        handleClick={() => {
          setIsModalOpen(true);
          dispatch(actionGetExhibitionCategoryMaster());
        }}
        type="btn"
      />

      <CustomInputWithButtons
        handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
        onClickDownloadMenu={onClickDownloadMenu}
      />

      <Table
        rowKey="event_exhibitor_id"
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        columns={columns}
        dataSource={eventExhibitorsData}
        loading={getExhibitorsLoader || getAllExhibitorsLoader}
      />

      <div className="flex justify-between mt-3">
        <p className="text-[15px] font-popinsMedium">
          Total Exhibitor : {exhibitorsDataCount ? exhibitorsDataCount : 0}
        </p>

        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          total={exhibitorsDataCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            dispatch(
              actionGetEventExhibitors({
                offset: offsetRef.current,
                search,
                eventId,
              })
            );
          }}
        />
      </div>

      <Modal
        centered
        onOk={handleCancel}
        onCancel={handleCancel}
        afterClose={handleCancel}
        footer={false}
        width={800}
        title=""
        open={isModalOpen}
      >
        <div className="text-brand flex items-center gap-x-3">
          <Image
            src={exibhitors}
            alt="image"
            height={20}
            width={20}
            preview={false}
          />
          <h1 className="lg:text-lg text-base  font-popinsRegular">
            {exhibitorData ? "Update" : "Add"} Exhibitor
          </h1>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          {/* <Spin> */}
          <Spin spinning={getExhibitorDetailLoader}>
            <Row gutter={[16, 0]}>
              <Col span={24} align="middle">
                <Form.Item
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter exhibitor Image!",
                    },
                  ]}
                >
                  {profilePicUrl ? (
                    <div className="relative border border-dashed border-brand h-[102px] w-[102px] rounded-[6px]">
                      <img
                        src={profilePicUrl}
                        alt="avatar"
                        className="h-full w-full rounded-[6px]"
                      />

                      <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                        <Upload
                          name="file"
                          listType="picture-card"
                          className="avatar-uploader main-upload"
                          showUploadList={false}
                          action={BASE_URL_UPLOAD}
                          openFileDialogOnClick={true}
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("crmWebToken"),
                          }}
                          onChange={handleChangeUserPic}
                        >
                          <div className="absolute bottom-0 right-0 px-1.5 py-1 bg-brand main-upload  rounded-tl-xl rounded-br-[6px] cursor-pointer flex">
                            <Image
                              width={15}
                              height={15}
                              src={editPrimary}
                              className="rounded-lg"
                              preview={false}
                            />
                          </div>
                        </Upload>
                      </ImgCrop>
                    </div>
                  ) : (
                    <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        openFileDialogOnClick={true}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("crmWebToken"),
                        }}
                        onChange={handleChangeUserPic}
                      >
                        <button
                          style={{
                            border: 0,
                            background: "none",
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Upload
                          </div>
                        </button>
                      </Upload>
                    </ImgCrop>
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="exhibitor_name"
                  label="Exhibitor Name"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: true,
                      message: "Please input exhibitor Name!",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Exhibitor Name"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                {" "}
                <Popover
                  placement="bottomRight"
                  content={exhibitoreCategoryPopover}
                  trigger="click"
                  open={isExhibitorCategoryOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setIsExhibitorCategoryModalOpen(false);
                      exhibitorCategoryModalForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={showExhibitorCategoryOpen}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Exhibitor Category
                    </h1>
                  </div>
                </Popover>
                <Form.Item
                  name="exhibitor_category_id"
                  label="Exhibitor Category"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please enter exhibitor category!",
                    },
                  ]}
                >
                  <Select
                    options={cateData}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={getExhibitionCategoryLoader}
                    disabled={getExhibitionCategoryLoader}
                    style={{ background: "white" }}
                    placeholder="Select event category"
                    className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="exhibitor_contact_person"
                  label="Contact Person Name"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Contact Person!",
                    },
                  ]}
                >
                  <Input
                    aria-controls="false"
                    style={{ background: "white" }}
                    placeholder="Enter Contact Person Name"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="exhibitor_contact_person_email"
                  label="Contact Person Email"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      type: "email",
                      required: false,
                      message: "Please enter valid email!",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Contact Person Email"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="exhibitor_contact_person_phone"
                  label="Contact Person Phone"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      max: 15,
                      message: "phone number cannot be longer than 15 digits",
                    },
                  ]}
                >
                  <Input
                    // rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Contact Person Phone"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="booth_number"
                  label="Booth Number"
                  className="mb-[12px]"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Booth Number!",
                    },
                  ]}
                >
                  <Input
                    // rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Booth Number"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="exhibitor_website"
                  label="Exhibitor Website"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter a valid URL!",
                    },
                  ]}
                >
                  <Input
                    // rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Exhibitor Website"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                align="middle"
                className="flex justify-center mt-[40px]"
              >
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addExhibitorLoader || updateExhibitorLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      {exhibitorData ? "Save" : "Submit"}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Spin>
          {/* </Spin> */}
        </Form>
      </Modal>

      {/* <Modal
        centered
        destroyOnClose={() => form.resetFields()}
        onOk={() => handleCancelExhibitorCategoryModal()}
        onCancel={() => handleCancelExhibitorCategoryModal()}
        footer={false}
        width={500}
        open={isExhibitorCategoryOpen}
      >
        <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
          Exhibitor Category
        </h1>
        <Form
          name=""
          form={exhibitorCategoryModalForm}
          onFinish={(values) =>
            dispatch(
              actionAddExhibitionCategoryMaster({
                values,
                handleCancel: handleCancelExhibitorCategoryModal,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="exhibitor_category"
            label="Exhibitor Category"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input exhibitor category!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Exhibitor Category"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelExhibitorCategoryModal()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addExhibitionCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal> */}

      <CommonModal
        className=""
        centered
        open={viewModalOpen}
        title=""
        onCancel={() => {
          setViewModalOpen(false);
          setExhibitorData(null);
          handleCancel();
        }}
        handleReset={() => {
          setViewModalOpen(false);
          handleCancel();
          setExhibitorData(null);
        }}
        onOk={() => {
          setViewModalOpen(false);
          setExhibitorData(null);
          handleCancel();
        }}
      >
        {/* <Spin> */}
        <Spin spinning={getExhibitorDetailLoader}>
          {getExhibitorDetail && (
            <div>
              <div className="text-brand flex items-center gap-x-3">
                <Image
                  src={exibhitors}
                  alt="image"
                  height={20}
                  width={20}
                  preview={false}
                />
                <h1 className="lg:text-lg text-base font-popinsRegular">
                  View Exhibitor
                </h1>
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex items-center pt-5 gap-x-4">
                  <Image
                    preview={false}
                    height={100}
                    width={100}
                    className="rounded-[10px]"
                    src={getExhibitorDetail.exhibitor_image}
                    alt="Profile Pic"
                  />
                  <div className="flex justify-start flex-col gap-y-1">
                    <p className="text-sm font-popinsMedium">
                      {getExhibitorDetail.exhibitor_name}
                    </p>
                    <p className="text-sm text-[#818181]">
                      {getExhibitorDetail.exhibitor_contact_person_email ?? ""}
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">
                    Contact Person Name:
                  </p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getExhibitorDetail.exhibitor_contact_person ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">
                    Contact Person Phone:
                  </p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getExhibitorDetail.exhibitor_contact_person_phone ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">
                    Booth Number:
                  </p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getExhibitorDetail.booth_number ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">
                    Exhibitor Category:
                  </p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getExhibitorDetail.exhibitor_category &&
                    getExhibitorDetail.exhibitor_category?.exhibitor_category
                      ? getExhibitorDetail.exhibitor_category
                          ?.exhibitor_category
                      : "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">
                    Exhibitor Website:
                  </p>
                  <a
                    href={formatUrl(
                      getExhibitorDetail &&
                        getExhibitorDetail.exhibitor_website !== null &&
                        getExhibitorDetail.exhibitor_website
                    )}
                    className="text-sm text-start w-[70%] text-blue-500 font-popinsMedium"
                    target="_blank"
                  >
                    {getExhibitorDetail.exhibitor_website ?? "-"}
                  </a>
                </div>
              </div>
            </div>
          )}
        </Spin>
        {/* </Spin> */}
      </CommonModal>
    </EventDetailWrapper>
  );
};

export default Exhibitors;
