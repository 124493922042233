import * as XLSX from "xlsx";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  Table,
  Typography,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import editPrimary from "../../../assets/img/editPrimary.svg";
import speaker from "../../../assets/img/speaker.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import {
  actionAddEventSpeaker,
  actionClearAllEventSpeakerData,
  actionDeleteEventSpeaker,
  actionEventSpeakerDetail,
  actionGetAllEventSpeakers,
  actionGetEventSpeakers,
  actionUpdateEventSpeaker,
} from "../../../store/services/eventService";
import CommonModal from "../../common/CommonModal";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import {
  extractFilename,
  getEventId,
  getFirstLetterCapital,
} from "../../helper/common-function";
import EventDetailWrapper from "./EventDetailWrapper";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Speakers = () => {
  const dispatch = useDispatch();
  const eventId = getEventId();
  const [form] = Form.useForm();
  const {
    eventSpeakersData,
    getEventSpeakersLoader,
    eventSpeakersDataCount,
    addEventSpeakerLoader,
    updateEventSpeakerLoader,
    getEventSpeakerDetailLoader,
    getEventSpeakerDetail,
    deleteEventSpeakerLoader,
    allEventSpeakersData,
    getAllEventSpeakersLoader,
  } = useSelector((state) => state.events);
  const [loading, setLoading] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [speakerData, setSpeakerData] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const offsetRef = useRef(0);

  const columns = [
    {
      title: "Speaker Name",
      key: "1",
      fixed: "left",
      dataIndex: "speaker_name",
      sorter: (a, b) => a.speaker_name - b.speaker_name,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text, data) => (
        <div
          onClick={() => {
            setSpeakerData(data);
            setViewModalOpen(true);
          }}
          className="flex items-center cursor-pointer"
        >
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            src={data.speaker_image}
            size="large"
          />
          <p className="ml-2 text-brand">
            {getFirstLetterCapital(data.speaker_name)}
          </p>
        </div>
      ),
    },

    {
      title: "Speaker Email",
      key: "3",
      width: 300,

      dataIndex: "speaker_email",
      sorter: (a, b) => a.speaker_email - b.speaker_email,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Speaker Contact",
      dataIndex: "speaker_contact",
      key: "4",
      width: 200,
      sorter: (a, b) => a.speaker_contact - b.speaker_contact,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Speaker Company",
      dataIndex: "speaker_company",
      key: "5",
      width: 200,
      sorter: (a, b) => a.speaker_company - b.speaker_company,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Speaker Bio",
      dataIndex: "speaker_bio",
      key: "2",
      width: 200,
      ellipsis: true,
      sortIcon: () => <SortIcon />,
      sorter: (a, b) => a.speaker_bio - b.speaker_bio,
      render: (text, data) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 300 }}
          className="ml-2"
        >
          {text ?? "-"}
        </Typography.Paragraph>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    setIsModalOpen(true);
                    setSpeakerData(data);
                  },
                },

                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteEventSpeakerLoader,
                      }}
                      placement="topLeft"
                      description={`Are you sure to delete this speaker?`}
                      onConfirm={() => {
                        dispatch(
                          actionDeleteEventSpeaker({
                            id: data.event_speaker_id,
                            event_id: eventId,
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ),
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (eventId) {
      dispatch(
        actionGetEventSpeakers({ offset: offsetRef.current, search, eventId })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (speakerData) {
      dispatch(
        actionEventSpeakerDetail({ id: speakerData.event_speaker_id, eventId })
      );
    }
  }, [dispatch, speakerData]);

  useEffect(() => {
    if (speakerData && getEventSpeakerDetail) {
      form.setFieldsValue({
        speaker_name: getEventSpeakerDetail.speaker_name,
        speaker_email: getEventSpeakerDetail.speaker_email,
        speaker_contact: getEventSpeakerDetail.speaker_contact,
        speaker_bio: getEventSpeakerDetail.speaker_bio,
        speaker_company: getEventSpeakerDetail.speaker_company,
      });
      setProfilePicUrl(getEventSpeakerDetail.speaker_image);
    }
  }, [getEventSpeakerDetail]);

  

  const handleRefresh = () => {
    setSearch(null);
    dispatch(
      actionGetEventSpeakers({
        offset: offsetRef.current,
        eventId,
      })
    );
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetEventSpeakers({
          offset: offsetRef.current,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetEventSpeakers({
          offset: offsetRef.current,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSpeakerData(false);
    form.resetFields();
    setProfilePicUrl("");
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {
    const fileUrl = profilePicUrl
      ? extractFilename(profilePicUrl && profilePicUrl)
      : "";

    const data = {
      ...values,
      speaker_image: fileUrl,
      event_id: eventId,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };

    speakerData
      ? dispatch(
          actionUpdateEventSpeaker({
            id: getEventSpeakerDetail.event_speaker_id,
            req,
            handleCancel,
          })
        )
      : dispatch(
          actionAddEventSpeaker({
            req,
            handleCancel,
          })
        );
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetEventSpeakers({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        eventId,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allEventSpeakersData) &&
      allEventSpeakersData.length > 0 &&
      allEventSpeakersData.map((data) => ({
        "Speaker Name": data.speaker_name ? data.speaker_name : "-",
        "Spiker Bio": data.speaker_bio ? data.speaker_bio : "-",
        "Speaker Email": data.speaker_email ? data.speaker_email : "-",
        "Speaker Contact": data.speaker_contact ? data.speaker_contact : "-",
        "Speaker Company": data.speaker_company ? data.speaker_company : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "event-speakers.xlsx");
    dispatch(actionClearAllEventSpeakerData({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allEventSpeakersData && allEventSpeakersData.length > 0) {
      exportAllToExcel();
    }
  }, [allEventSpeakersData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllEventSpeakers({ eventId }));
    setExportAll(true);
  };

  return (
    <>
      <EventDetailWrapper>
        <PageTitle
          image={speaker}
          title="Speakers"
          buttonText="Add Speaker"
          handleClick={() => setIsModalOpen(true)}
          type="btn"
        />

        <CustomInputWithButtons
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          searchValue={search}
          /* isAllowDownload={
            eventSpeakersDataCount !== undefined && eventSpeakersDataCount > 0
          } */
          onClickDownloadMenu={onClickDownloadMenu}
        />

        <Table
          rowKey="event_speaker_id"
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            // x: 1000,
            y: window.innerWidth < 1500 ? "49vh" : "61vh",
          }}
          pagination={false}
          columns={columns}
          dataSource={eventSpeakersData}
          loading={getEventSpeakersLoader || getAllEventSpeakersLoader}
        />
        <div className="flex justify-between mt-3">
          <p className="text-[15px] font-popinsMedium">
            Total Speakers :{" "}
            {eventSpeakersDataCount ? eventSpeakersDataCount : 0}
          </p>
          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            showSizeChanger={false}
            total={eventSpeakersDataCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              dispatch(
                actionGetEventSpeakers({
                  eventId,
                  offset: offsetRef.current,
                  search,
                })
              );
            }}
          />
        </div>

        {/*  Form Modal */}
        <Modal
          loading={getEventSpeakerDetailLoader}
          centered
          onOk={handleCancel}
          onCancel={handleCancel}
          afterClose={handleCancel}
          footer={false}
          width={800}
          title=""
          open={isModalOpen}
        >
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={speaker}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />

            <h1 className="lg:text-lg text-base  font-popinsRegular">
              {speakerData ? "Update" : "Add"} Speaker
            </h1>
          </div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            {/* <Spin spinning={getEventSpeakerDetailLoader}> */}
            <Row gutter={[16, 0]}>
              <Col span={24} align="middle">
                <Form.Item
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please Select Speaker Image!",
                    },
                  ]}
                >
                  {profilePicUrl ? (
                    <div className="relative border border-dashed border-brand  h-[102px]  w-[102px] rounded-[6px]">
                      <img
                        src={profilePicUrl}
                        alt="avatar"
                        className="h-full w-full rounded-[7px]"
                      />

                      <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                        <Upload
                          name="file"
                          listType="picture-card"
                          className="avatar-uploader main-upload"
                          showUploadList={false}
                          action={BASE_URL_UPLOAD}
                          openFileDialogOnClick={true}
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("crmWebToken"),
                          }}
                          onChange={handleChangeUserPic}
                        >
                          <div className="absolute bottom-0 right-0 px-1.5 py-1 bg-brand  rounded-tl-xl rounded-br-[6px] cursor-pointer flex">
                            <Image
                              width={15}
                              height={15}
                              src={editPrimary}
                              className="rounded-lg"
                              preview={false}
                            />
                          </div>
                        </Upload>
                      </ImgCrop>
                    </div>
                  ) : (
                    <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        openFileDialogOnClick={true}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("crmWebToken"),
                        }}
                        onChange={handleChangeUserPic}
                      >
                        <button
                          style={{
                            border: 0,
                            background: "none",
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Upload
                          </div>
                        </button>
                      </Upload>
                    </ImgCrop>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="speaker_name"
                  label="Speaker Name"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: true,
                      message: "Please enter speaker name!",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter speaker name"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="speaker_email"
                  label="Speaker Email"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      type: "email",
                      required: false,
                      message: "Please enter speaker email!",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter speaker email"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="speaker_contact"
                  label="Speaker Contact"
                  className="mb-[12px]"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  size="small"
                  /*  rules={[
                    {
                      required: false,
                      message: "Please enter speaker contact!",
                    },
                  ]} */
                  rules={[
                    {
                      max: 15,
                      message: "contact number cannot be longer than 15 digits",
                    },
                  ]}
                >
                  <Input
                    aria-controls="false"
                    style={{ background: "white" }}
                    placeholder="Enter Speaker Contact"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="speaker_company"
                  label="Speaker Company"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please enter speaker company!",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Speaker Company"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="speaker_bio"
                  label="Speaker Bio"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please enter speaker bio!",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Speaker Bio"
                    className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>

              <Col
                span={24}
                align="middle"
                className="flex justify-center mt-[40px]"
              >
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={
                        addEventSpeakerLoader || updateEventSpeakerLoader
                      }
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      {speakerData ? "Save" : "Submit"}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {/* </Spin> */}
          </Form>
        </Modal>
        <CommonModal
          centered={true}
          loading={getEventSpeakerDetailLoader}
          open={viewModalOpen}
          title=""
          handleReset={() => {
            setViewModalOpen(false);
            handleCancel();
          }}
          onCancel={() => {
            setViewModalOpen(false);
            handleCancel();
          }}
          onOk={() => {
            setViewModalOpen(false);
            handleCancel();
          }}
        >
          {/* <Spin spinning={getEventSpeakerDetailLoader}> */}
          {getEventSpeakerDetail && (
            <>
              <div className="text-brand flex items-center gap-x-3">
                <Image
                  src={speaker}
                  alt="image"
                  height={20}
                  width={20}
                  preview={false}
                />

                <h1 className="lg:text-lg text-base  font-popinsRegular">
                  View Speaker
                </h1>
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex items-center pt-5 gap-x-4">
                  <Image
                    preview={false}
                    height={100}
                    width={100}
                    className="rounded-[10px]"
                    src={getEventSpeakerDetail.speaker_image}
                    alt="Profile Pic"
                  />
                  <div className="flex justify-start flex-col gap-y-1">
                    <p className="text-sm font-popinsMedium">
                      {getEventSpeakerDetail.speaker_name}
                    </p>
                    <p className="text-sm  text-[#818181]">
                      {getEventSpeakerDetail.speaker_email ?? "-"}
                    </p>
                  </div>
                </div>
                <div className="flex  gap-x-10 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[25%]">Speaker Bio:</p>
                  <p className="text-sm text-start w-[75%] text-black font-popinsMedium">
                    {getEventSpeakerDetail.speaker_bio ?? "-"}
                  </p>
                </div>
                <div className="flex  gap-x-10 items-center w-full gap-y-1">
                  <p className="text-xs text-[#818181] w-[25%]">
                    Speaker Contact:
                  </p>
                  <p className="text-sm text-start w-[75%] text-black font-popinsMedium">
                    {/* +91 8586291612 */}
                    {getEventSpeakerDetail.speaker_contact ?? "-"}
                  </p>
                </div>
                <div className="flex  gap-x-10 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[25%]">
                    Speaker Company:
                  </p>
                  <p className="text-sm text-start w-[75%] text-black font-popinsMedium">
                    {getEventSpeakerDetail.speaker_company ?? "-"}
                  </p>
                </div>
              </div>
            </>
          )}
          {/* </Spin> */}
        </CommonModal>
      </EventDetailWrapper>
    </>
  );
};

export default Speakers;
