import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetCities,
  actionGetCountries,
  actionGetStates,
} from "../services/locationService";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    getCountryLoader: false,
    statesLoader: false,
    citiesLoader: false,
    areasLoader: false,
    zonesLoader: false,
    countries: [],
    states: [],
    cities: [],
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // actionGetCountries State
      .addCase(actionGetCountries.pending, (state) => {
        state.getCountryLoader = true;
      })
      .addCase(actionGetCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
        state.getCountryLoader = false;
      })
      .addCase(actionGetCountries.rejected, (state) => {
        state.getCountryLoader = false;
      })

      // actionGetStates State
      .addCase(actionGetStates.pending, (state) => {
        state.statesLoader = true;
      })
      .addCase(actionGetStates.fulfilled, (state, action) => {
        state.states = action.payload;
        state.statesLoader = false;
      })
      .addCase(actionGetStates.rejected, (state) => {
        state.statesLoader = false;
      })

      // actionGetCities State
      .addCase(actionGetCities.pending, (state) => {
        state.citiesLoader = true;
      })
      .addCase(actionGetCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.citiesLoader = false;
      })
      .addCase(actionGetCities.rejected, (state) => {
        state.citiesLoader = false;
      });
  },
});
export default locationSlice.reducer;
