import { Button, Card, Col, Form, Image, Input, Row } from "antd";
import React from "react";
import report from "../../assets/img/report-to.svg";

import { FaUserShield } from "react-icons/fa";
import CustomIconText from "../common/CustomIconText";
import PageTitle from "../common/PageTitle";

const Permissions = () => {
  return (
    <section className="main-section">
      <PageTitle
        rightIcon={false}
        icon={<FaUserShield color="#6883FD" size={20} />}
        title="Permissions"
      />

      <Card className="main-card 2xl:w-4/5 w-11/12">
        <Form
          layout="vertical"
          name="basic"
          initialValues={{
            remember: true,
          }}
          //   onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Role Profile"
                name="role_profile"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Role Profile!",
                  },
                ]}
              >
                <Input placeholder="Enter Role Profile" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Role Name"
                name="role"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Role Name!",
                  },
                ]}
              >
                <Input placeholder="Enter Role Name" />

                {/*   <Select
              allowClear
              showSearch
              placeholder="Select Role Name"
              optionFilterProp="children"
              className=""
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                { label: "Admin", value: "ADMIN" },
                { label: "User", value: "USER" },
              ]}
            /> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Report To"
                name="report_to"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Report!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Report"
                  suffix={<Image preview={false} src={report} alt="Report" />}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Description!",
                  },
                ]}
              >
                <Input.TextArea placeholder="Enter Description" />
              </Form.Item>
            </Col>

            <Col
              span={24}
              className="flex items-center my-4 justify-center gap-x-10"
            >
              <Form.Item>
                <Button
                  type="primary"
                  className="root-btn  min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="button"
                  className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </section>
  );
};

export default Permissions;
