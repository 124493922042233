import sponser from "../../../assets/img/sponser.svg";
import PageTitle from "../../common/PageTitle";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import { useRef, useState, useEffect } from "react";
import {
  Avatar,
  Table,
  Pagination,
  Modal,
  Form,
  Spin,
  Row,
  Col,
  Upload,
  Select,
  Dropdown,
  Popconfirm,
  Image,
  Input,
  Button,
} from "antd";
import EventDetailWrapper from "./EventDetailWrapper";
import editPrimary from "../../../assets/img/editPrimary.svg";
import {
  DeleteOutlined,
  EditOutlined,
  // EyeOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import plusSvg from "../../../assets/img/plus.svg";
import CommonModal from "../../common/CommonModal";
import SortIcon from "../../common/SortIcon";
import {
  actionGetEventSponsor,
  actionClearAllEventSponsor,
  actionGetAllEventSponsor,
  actionEventSponsorDetail,
  actionAddEventSponsor,
  actionDeleteEventSponsor,
  actionUpdateEventSponsor,
} from "../../../store/services/eventService";
import { useDispatch, useSelector } from "react-redux";
import {
  extractFilename,
  getEventId,
  getFirstLetterCapital,
} from "../../helper/common-function";
import * as XLSX from "xlsx";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import { toast } from "sonner";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Sponsor = () => {
  const dispatch = useDispatch();
  const eventId = getEventId();
  const [form] = Form.useForm();
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isExhibitorCategoryOpen, setIsExhibitorCategoryModalOpen] =
    useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [sponsorData, setSponsorData] = useState(null);
  const offsetRef = useRef(0);

  const {
    getSponsorLoader,
    getSponsorDetailLoader,
    addSponsorLoader,
    getAllSponsorLoader,
    deleteSponsorLoader,
    getSponsorDetail,
    sponsorDetailLoader,
    sponsorDetailData,
    updateSponsorLoader,
    allSponsorData,
    sponsorDataCount,
    eventSponsorData,
  } = useSelector((state) => state.events);

  useEffect(() => {
    if (eventId) {
      dispatch(
        actionGetEventSponsor({ offset: offsetRef.current, search, eventId })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (sponsorData) {
      dispatch(
        actionEventSponsorDetail({
          id: sponsorData.event_sponsor_id,
          eventId,
        })
      );
    }
  }, [dispatch, sponsorData]);

  useEffect(() => {
    if (sponsorData && getSponsorDetail) {
      form.setFieldsValue({
        sponsor_name: getSponsorDetail.sponsor_name,
        sponsor_category: getSponsorDetail.sponsor_category,
        sponsor_contact_person: getSponsorDetail.sponsor_contact_person,
        sponsor_person_phone: getSponsorDetail.sponsor_person_phone,
        sponsor_person_email: getSponsorDetail.sponsor_person_email,
      });
      setProfilePicUrl(getSponsorDetail.sponsor_image);
    }
  }, [getSponsorDetail]);

  const exportAllToExcel = async () => {
    const data =
      (await allSponsorData) &&
      allSponsorData.length > 0 &&
      allSponsorData.map((data) => ({
        "Sponsor Name": data.sponsor_name ? data.sponsor_name : "-",

        // "Sponsor Category": data.sponsor_category ? data.sponsor_category : "-",
        "Sponsor Category": data.sponsor_category ? data.sponsor_category : "-",
        "Contact Person Name": data.sponsor_contact_person
          ? data.sponsor_contact_person
          : "-",
        "Sponsor Phone": data.sponsor_person_phone
          ? data.sponsor_person_phone
          : "-",
        "Sponsor Email": data.sponsor_person_email
          ? data.sponsor_person_email
          : "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "event-sponsor.xlsx");
    dispatch(actionClearAllEventSponsor({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allSponsorData && allSponsorData.length > 0) {
      exportAllToExcel();
    }
  }, [allSponsorData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllEventSponsor({ eventId }));
    setExportAll(true);
  };

  const columns = [
    {
      title: "Sponsor Name & Image",
      key: "1",
      dataIndex: "sponsor_name",
      fixed: "left",
      sorter: (a, b) => a.sponsor_name - b.sponsor_name,
      sortIcon: () => <SortIcon />,
      width: 250,
      render: (text, data) => (
        <div
          onClick={() => {
            setSponsorData(data);
            setViewModalOpen(true);
          }}
          className="flex items-center cursor-pointer"
        >
          <Avatar
            style={{ verticalAlign: "middle" }}
            src={data.sponsor_image}
            size="large"
          />
          <p className="ml-2 text-brand">
            {data?.sponsor_name.charAt(0).toUpperCase() +
              data?.sponsor_name.slice(1)}
          </p>
        </div>
      ),
    },
    {
      title: "Sponsor Category",
      dataIndex: "sponsor_category",
      key: "2",
      width: 200,
      sorter: (a, b) => a.sponsor_category - b.sponsor_category,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <>
          {/* <span className="ml-2">
            {data.sponsor_category ? data.sponsor_category : "-"}
          </span> */}
          <p className="ml-2">
            {data.sponsor_category
              ? data.sponsor_category.charAt(0).toUpperCase() +
                data.sponsor_category.slice(1).toLowerCase()
              : "-"}
          </p>
        </>
      ),
    },
    {
      title: "Contact Person Name",
      key: "3",
      width: 250,
      dataIndex: "sponsor_contact_person",
      sorter: (a, b) => a.sponsor_contact_person - b.sponsor_contact_person,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.sponsor_contact_person ? data.sponsor_contact_person : "-"}
        </span>
      ),
    },
    {
      title: "Sponsor Phone",
      dataIndex: "sponsor_person_phone",
      key: "4",
      width: 200,
      sorter: (a, b) => a.sponsor_person_phone - b.sponsor_person_phone,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.sponsor_person_phone ? data.sponsor_person_phone : "-"}
        </span>
      ),
    },
    {
      title: "Sponsor Email",
      dataIndex: "sponsor_person_email",
      key: "4",
      width: 180,
      sorter: (a, b) => a.sponsor_person_email - b.sponsor_person_email,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.sponsor_person_email ? data.sponsor_person_email : "-"}
        </span>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    setIsModalOpen(true);
                    setSponsorData(data);
                  },
                },

                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteSponsorLoader,
                      }}
                      placement="topLeft"
                      title={`Are you sure to delete this sponsor?`}
                      onConfirm={() => {
                        dispatch(
                          actionDeleteEventSponsor({
                            id: data.event_sponsor_id,
                            event_id: eventId,
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span style={{ marginLeft: 8, color: "#ff4d4f" }}>
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ),
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);

        setProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  // const showExhibitorCategoryOpen = () => {
  //   setIsExhibitorCategoryModalOpen(true);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSponsorData("");
    form.resetFields();
    setProfilePicUrl("");
  };

  const handleRefresh = () => {
    setSearch(null);
    dispatch(
      actionGetEventSponsor({
        offset: offsetRef.current,
        eventId,
      })
    );
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetEventSponsor({
          offset: offsetRef.current,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetEventSponsor({
          offset: offsetRef.current,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const onFinish = (values) => {
    const fileUrl = profilePicUrl
      ? extractFilename(profilePicUrl && profilePicUrl)
      : "";

    const data = {
      ...values,
      sponsor_image: fileUrl,
      event_id: eventId,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };
    sponsorData
      ? dispatch(
          actionUpdateEventSponsor({
            id: getSponsorDetail.event_sponsor_id,
            req,
            handleCancel,
          })
        )
      : dispatch(
          actionAddEventSponsor({
            req,
            handleCancel,
          })
        );
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetEventSponsor({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        eventId,
      })
    );
  };

  const lowerStr =
    getSponsorDetail && getSponsorDetail.sponsor_category !== null
      ? getSponsorDetail.sponsor_category.toLowerCase()
      : "-";
  const sponsorCategory = getFirstLetterCapital(lowerStr);

  return (
    <>
      <EventDetailWrapper>
        <PageTitle
          image={sponser}
          title="Sponsor"
          buttonText="Add Sponsor"
          handleClick={() => {
            setIsModalOpen(true);
            // dispatch(actionGetExhibitionCategoryMaster());
          }}
          type="btn"
        />

        <CustomInputWithButtons
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          searchValue={search}
          onClickDownloadMenu={onClickDownloadMenu}
        />

        <Table
          rowKey="event_sponsor_id"
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            y: window.innerWidth < 1500 ? "49vh" : "61vh",
          }}
          pagination={false}
          columns={columns}
          dataSource={eventSponsorData}
          loading={getSponsorLoader || getAllSponsorLoader}
        />

        <div className="flex justify-between mt-3">
          <p className="text-[15px] font-popinsMedium">
            Total Sponsor : {sponsorDataCount ? sponsorDataCount : 0}
          </p>

          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            total={sponsorDataCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              dispatch(
                actionGetEventSponsor({
                  offset: offsetRef.current,
                  search,
                  eventId,
                })
              );
            }}
          />
        </div>

        <Modal
          centered
          onOk={handleCancel}
          onCancel={handleCancel}
          afterClose={handleCancel}
          footer={false}
          width={800}
          title=""
          open={isModalOpen}
        >
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={sponser}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base  font-popinsRegular">
              {sponsorData ? "Update" : "Add"} Sponsor
            </h1>
          </div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            <Row gutter={[16, 0]}>
              <Col span={24} align="middle">
                <Form.Item
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter exhibitor Image!",
                    },
                  ]}
                >
                  {profilePicUrl ? (
                    <div className="relative border border-dashed border-brand h-[102px] w-[102px] rounded-[6px]">
                      <img
                        src={profilePicUrl}
                        alt="avatar"
                        className="h-full w-full rounded-[6px]"
                      />

                      <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                        <Upload
                          name="file"
                          listType="picture-card"
                          className="avatar-uploader main-upload"
                          showUploadList={false}
                          action={BASE_URL_UPLOAD}
                          openFileDialogOnClick={true}
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("crmWebToken"),
                          }}
                          onChange={handleChangeUserPic}
                        >
                          <div className="absolute bottom-0 right-0 px-1.5 py-1 bg-brand main-upload  rounded-tl-xl rounded-br-[6px] cursor-pointer flex">
                            <Image
                              width={15}
                              height={15}
                              src={editPrimary}
                              className="rounded-lg"
                              preview={false}
                            />
                          </div>
                        </Upload>
                      </ImgCrop>
                    </div>
                  ) : (
                    <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        openFileDialogOnClick={true}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("crmWebToken"),
                        }}
                        onChange={handleChangeUserPic}
                      >
                        <button
                          style={{
                            border: 0,
                            background: "none",
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Upload
                          </div>
                        </button>
                      </Upload>
                    </ImgCrop>
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="sponsor_name"
                  label="Sponsor Name"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: true,
                      message: "Please enter sponsor name!",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Sponsor Name"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="sponsor_category"
                  label="Sponsor Category"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please input sponsor category!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "GOLD",
                        label: "Gold",
                      },
                      {
                        value: "SILVER",
                        label: "Silver",
                      },
                      {
                        value: "BRONZE",
                        label: "Bronze",
                      },
                    ]}
                    style={{ background: "white" }}
                    placeholder="Select Sponsor Category"
                    className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="sponsor_contact_person"
                  label="Contact Person Name"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Sponsor Contact Person!",
                    },
                  ]}
                >
                  <Input
                    aria-controls="false"
                    style={{ background: "white" }}
                    placeholder="Enter Contact Person Name"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="sponsor_person_phone"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  label="Sponsor Person Phone"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      max: 15,
                      message: "phone number cannot be longer than 15 digits",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Contact Person Phone"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="sponsor_person_email"
                  label="Sponsor Person Email"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      type: "email",
                      required: false,
                      message: "Please enter valid email!!",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Sponsor Email"
                    className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>

              <Col
                span={24}
                align="middle"
                className="flex justify-center mt-[40px]"
              >
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addSponsorLoader || updateSponsorLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      {sponsorData ? "Save" : "Submit"}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>

        <CommonModal
          className=""
          centered
          open={viewModalOpen}
          title=""
          onCancel={() => {
            setViewModalOpen(false);
            handleCancel();
          }}
          handleReset={() => {
            setViewModalOpen(false);
            handleCancel();
          }}
          onOk={() => {
            setViewModalOpen(false);
            setSponsorData("");
            handleCancel();
          }}
        >
          {/* <Spin> */}
          <Spin spinning={getSponsorDetailLoader}>
            {getSponsorDetail && (
              <div>
                <div className="text-brand flex items-center gap-x-3">
                  <Image
                    src={sponser}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="lg:text-lg text-base font-popinsRegular">
                    View Sponsor
                  </h1>
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="flex items-center pt-5 gap-x-4">
                    <Image
                      preview={false}
                      height={100}
                      width={100}
                      className="rounded-[10px]"
                      src={getSponsorDetail.sponsor_image}
                      alt="Profile Pic"
                    />
                    <div className="flex justify-start flex-col gap-y-1">
                      <p className="text-sm font-popinsMedium">
                        {getSponsorDetail.sponsor_name}
                      </p>
                      <p className="text-sm text-[#818181]">
                        {getSponsorDetail.sponsor_person_email ?? "-"}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Sponsor Category:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {sponsorCategory}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Contact Person Name:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSponsorDetail.sponsor_contact_person ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Sponsor Phone:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSponsorDetail.sponsor_person_phone ?? "-"}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Spin>
          {/* </Spin> */}
        </CommonModal>
      </EventDetailWrapper>
    </>
  );
};

export default Sponsor;
