import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import convertIcon from "../../../assets/img/convert-icon.svg";
import LeadIcon from "../../../assets/img/leadsIcon.svg";
import {
  actionClearAllLeads,
  actionConvertLeadToOpportunity,
  actionDeleteLead,
  actionGetAllLeads,
  actionGetLeadDetail,
  actionGetLeads,
  actionLeadChangeStatus,
} from "../../../store/services/leadsService";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import EventDescriptionCard from "../../common/EventDescriptionCard";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import { getFirstLetterCapital } from "../../helper/common-function";
import { actionGetLeadStatusMaster } from "../../../store/services/generalMasterService";
import { MdOutlineChangeCircle } from "react-icons/md";

const Leads = () => {
  const [statusForm] = Form.useForm();
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [leadId, setLeadId] = useState("");
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [statusModal, setStatusModal] = useState(false);

  const {
    getLeadsLoader,
    getAllLeadsLoader,
    getLeadDetailLoader,
    deleteLeadLoader,
    leadsData,
    allLeadsData,
    leadDetailData,
    leadsDataCount,
    changeLeadStatusLoader,
  } = useSelector((state) => state.leads);

  const { leadStatusData, getLeadStatusLoader } = useSelector(
    (state) => state.generalMaster
  );

  useEffect(() => {
    dispatch(
      actionGetLeads({
        offset: offsetRef.current,
        search,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (leadId && viewModalOpen) {
      dispatch(actionGetLeadDetail(leadId));
    }
  }, [viewModalOpen, leadId]);

  const handleCancelLeadStatusModal = () => {
    setLeadId("");
    setStatusModal(false);
    statusForm.resetFields();
  };

  const columns = [
    {
      title: "Lead Name",
      key: "lead_name",
      fixed: "left",
      dataIndex: "lead_name",
      ellipsis: true,
      // sorter: true,
      // sortIcon: () => <SortIcon />,
      width: 200,

      render: (text, data) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            setViewModalOpen(true);
            setLeadId(data.lead_id);
          }}
        >
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            src={data?.lead_image}
            size="large"
          />
          <p className="ml-2 text-brand">
            {getFirstLetterCapital(data?.first_name) +
              " " +
              getFirstLetterCapital(data?.last_name)}
          </p>
        </div>
      ),
    },
    {
      title: "Lead Owner",
      key: "lead_owner",
      dataIndex: "lead_owner",
      width: 150,
      render: (text) => {
        const displayName = text
          ? text.first_name
            ? text.last_name
              ? text.first_name.charAt(0).toUpperCase() +
                text.first_name.slice(1).toLowerCase() +
                " " +
                text.last_name.charAt(0).toUpperCase() +
                text.last_name.slice(1).toLowerCase()
              : text.first_name.charAt(0).toUpperCase() +
                text.first_name.slice(1).toLowerCase()
            : "-"
          : "-";
        return <span className="ml-2">{displayName}</span>;
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Email",
      key: "secondary_email",
      dataIndex: "secondary_email",
      sorter: true,
      ellipsis: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Company",
      key: "company_name",
      dataIndex: "company_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Website",
      key: "website",
      dataIndex: "website",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => (
        <a href={text} target="_blank" className="ml-2">
          {text || "-"}
        </a>
      ),
    },
    {
      title: "Phone",
      key: "phone",
      dataIndex: "phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Lead Status",
      key: "lead_status",
      dataIndex: "lead_status",
      width: 150,
      render: (text) => {
        return <span className="ml-2">{text?.lead_status || "-"}</span>;
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      dataIndex: "mobile",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },

    {
      title: "Lead Source",
      key: "lead_source",
      dataIndex: "lead_source",
      width: 150,
      render: (text) => (
        <span className="ml-2">{text?.lead_source || "-"}</span>
      ),
    },
    {
      title: "Rating",
      key: "lead_rating",
      dataIndex: "lead_rating",
      width: 150,
      render: (text) => (
        <span className="ml-2">{text?.lead_rating || "-"}</span>
      ),
    },
    {
      title: "Industry",
      key: "industry",
      ellipsis: true,
      dataIndex: "industry",
      width: 150,
      render: (text) => <span className="ml-2">{text?.industry || "-"}</span>,
    },
    {
      title: "Fax",
      key: "fax",
      dataIndex: "fax",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => <span className="ml-2">{text}</span>,
    },
    {
      title: "Annual Revenue",
      key: "annual_revenue",
      dataIndex: "annual_revenue",
      sorter: true,
      ellipsis: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Skype ID",
      key: "skype_id",
      dataIndex: "skype_id",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Twitter",
      key: "twitter",
      dataIndex: "twitter",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Country",
      key: "country",
      dataIndex: "country",
      width: 150,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "State",
      key: "state",
      dataIndex: "state",
      width: 150,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "City",
      key: "city",
      dataIndex: "city",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Street",
      key: "street",
      dataIndex: "street",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Zip Code",
      key: "zip_code",
      dataIndex: "zip_code",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Address - 1",
      key: "address_line_1",
      dataIndex: "address_line_1",
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Address - 2",
      key: "address_line_2",
      dataIndex: "address_line_2",
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
    render: (text) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1, tooltip: false }}
        style={{ width: 200 }}
        className="ml-2"
      >
        {text || "-"}
      </Typography.Paragraph>
    ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      fixed: "right",
      width: 120,
      render: (text, data) => (
        <Dropdown.Button
          trigger={"hover"}
          id="custom-drop"
          menu={{
            items: [
              {
                label: <span>Edit</span>,
                key: "Edit",
                icon: <EditOutlined />,
                onClick: () => {
                  navigate("/create-lead", {
                    state: { leadId: data.lead_id },
                  });
                },
              },
              {
                label: <span>Change Status</span>,
                key: "change-status",
                icon: (
                  <MdOutlineChangeCircle color="rgba(0,0,0,0.8)" size={15} />
                ),
                onClick: () => {
                  setStatusModal(true);
                  setLeadId(data.lead_status_master_id);
                  dispatch(actionGetLeadStatusMaster());
                  dispatch(actionGetLeadDetail(data.lead_id));
                },
              },
              {
                label: (
                  <Popconfirm
                    okButtonProps={{
                      className: "root-btn",
                      //                      loading: cancleEventLoader,
                    }}
                    placement="topLeft"
                    title="Convert to Opportunity"
                    description={`Are you sure you want to convert this lead to an opportunity?`}
                    onConfirm={() => {
                      dispatch(actionConvertLeadToOpportunity(data.lead_id));
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span
                      onClick={(e) => e.stopPropagation()} // Stop event propagation
                      className="ml-2.5"
                    >
                      Convert to Opportunity
                    </span>
                  </Popconfirm>
                ),
                key: "convert",
                icon: (
                  <Image
                    className=""
                    preview={false}
                    src={convertIcon}
                    height={10}
                    width={10}
                    alt="Convert"
                  />
                ),
              },
              {
                label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                key: "Delete",
                icon: <DeleteOutlined style={{ color: "#ff4d4f" }} />,
                onClick: () => {
                  setIsDeleteModalOpen(data.lead_id);
                  setLeadId(data.lead_id);
                },
              },
            ].filter(Boolean),
          }}
        >
          Action
        </Dropdown.Button>
      ),
    },
  ];

  const handleRefresh = () => {
    setSearch(null);
    offsetRef.current = 0;
    dispatch(
      actionGetLeads({
        offset: offsetRef.current,
      })
    );
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      // * TODO Call Api here
      dispatch(
        actionGetLeads({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      // * TODO Call Api here
      dispatch(
        actionGetLeads({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllLeads());
    setExportAll(true);
  };

  const exportAllToExcel = async () => {
    const data =
      ((await allLeadsData) &&
        allLeadsData.length > 0 &&
        allLeadsData.map((data) => ({
          "Lead Name":
            data.first_name && data.first_name
              ? data.first_name + " " + data.last_name
              : "-",
          "Lead Owner": data.lead_owner
            ? `${data.lead_owner.first_name ?? ""} ${
                data.lead_owner.last_name ?? ""
              }`.trim() || "-"
            : "-",
          Email: data.email ? data.email : "-",
          "Secondary Email": data.secondary_email ? data.secondary_email : "-",
          Company: data.company_name ? data.company_name : "-",
          Website: data.website ? data.website : "-",
          Phone: data.phone ? data.phone : "-",
          "Lead Status": data.lead_status?.lead_status
            ? data.lead_status?.lead_status
            : "-",
          Mobile: data.mobile || "-",
          "Lead Source": data.lead_source?.lead_source
            ? data.lead_source?.lead_source
            : "-",
          "Lead rating": data.lead_rating?.lead_rating
            ? data.lead_rating?.lead_rating
            : "-",
          Industry: data.industry?.industry ? data.industry?.industry : "-",
          "Skype ID": data.skype_id ? data.skype_id : "-",
          Twitter: data.twitter ? data.twitter : "-",
          Country: data.country?.name || "-",
          State: data.state?.name || "-",
          City: data.city || "-",
          Street: data.street || "-",
          "Zip Code": data.zip_code || "-",
          Description: data.description || "-",
          "Address - 1": data.address_line_1 || "-",
          "Address - 2": data.address_line_2 || "-",
          Fax: data.fax || "-",
          "Annual Revenue": data.annual_revenue || "-",
        }))) ||
      [];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "leads.xlsx");
    dispatch(actionClearAllLeads({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allLeadsData && allLeadsData.length > 0) {
      exportAllToExcel();
    }
  }, [allLeadsData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
    setLeadId("");
  };

  const handleCloseDeleteModal = () => {
    setLeadId("");
    setIsDeleteModalOpen(false);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetLeads({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const statusData =
    leadStatusData &&
    leadStatusData.length > 0 &&
    leadStatusData.map((data) => ({
      label: data.lead_status,
      value: data.lead_status_master_id,
    }));

  return (
    <section className="main-wrapper">
      <PageTitle
        image={LeadIcon}
        title="Leads"
        buttonText="Create Lead"
        buttonLink="/create-lead"
      />

      <CustomInputWithButtons
        handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
        onClickDownloadMenu={onClickDownloadMenu}
      />

      <Table
        sticky={true}
        rowKey={(record) => record.lead_id}
        scrollToFirstRowOnChange={true}
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        columns={columns}
        dataSource={leadsData}
        loading={getLeadsLoader || getAllLeadsLoader}
      />

      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={leadsDataCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            dispatch(
              actionGetLeads({
                offset: offsetRef.current,
                search,
              })
            );
          }}
        />
      </div>

      {/* View Modal */}
      <Modal
        centered={true}
        loading={getLeadDetailLoader}
        footer={false}
        open={viewModalOpen}
        title=""
        width={600}
        destroyOnClose={() => {
          setViewModalOpen(false);
          handleCloseModal();
        }}
        onCancel={() => {
          setViewModalOpen(false);
          handleCloseModal();
        }}
        onOk={() => {
          setViewModalOpen(false);
          handleCloseModal();
        }}
      >
        {leadDetailData && (
          <>
            <div className="text-brand flex items-center gap-x-3">
              <Image
                src={LeadIcon}
                alt="image"
                height={20}
                width={20}
                preview={false}
              />

              <h1 className="lg:text-lg text-base  font-popinsRegular">
                View Lead
              </h1>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center pt-5 gap-x-4">
                <Image
                  preview={false}
                  height={100}
                  width={100}
                  className="rounded-[10px]"
                  src={leadDetailData.lead_image}
                  alt="Profile Pic"
                />
                <div className="flex justify-start flex-col gap-y-1">
                  <p className="text-sm font-popinsMedium">
                    {getFirstLetterCapital(leadDetailData.first_name) +
                      " " +
                      getFirstLetterCapital(leadDetailData.last_name)}
                  </p>
                  {leadDetailData?.email && (
                    <p className="text-sm  text-[#818181]">
                      {leadDetailData?.email}
                    </p>
                  )}
                </div>
              </div>
              <EventDescriptionCard
                title="Lead Owner"
                value={
                  leadDetailData?.lead_owner?.first_name != null &&
                  leadDetailData?.lead_owner?.last_name != null
                    ? leadDetailData?.lead_owner?.first_name +
                      " " +
                      leadDetailData?.lead_owner?.last_name
                    : leadDetailData?.lead_owner?.first_name
                }
              />
              <EventDescriptionCard
                title="Secondary Email"
                value={leadDetailData.secondary_email}
              />
              <EventDescriptionCard
                title="Company"
                value={leadDetailData.company_name}
              />
              <EventDescriptionCard
                title="Website"
                value={leadDetailData.website}
              />
              <EventDescriptionCard
                title="Address 1"
                value={leadDetailData.address_line_1}
              />
              <EventDescriptionCard
                title="Address 2"
                value={leadDetailData.address_line_2}
              />
              <EventDescriptionCard
                title="Website"
                value={leadDetailData.website}
              />
              <EventDescriptionCard
                title="Phone"
                value={leadDetailData.phone}
              />
              <EventDescriptionCard
                title="Lead Status"
                value={leadDetailData.lead_status?.lead_status}
              />
              <EventDescriptionCard
                title="Mobile"
                value={leadDetailData.mobile}
              />
              <EventDescriptionCard
                title="Lead Source"
                value={leadDetailData.lead_source?.lead_source}
              />
              <EventDescriptionCard
                title="Rating"
                value={leadDetailData?.lead_rating?.lead_rating}
              />
              <EventDescriptionCard
                title="Industry"
                value={leadDetailData.industry?.industry}
              />
              <EventDescriptionCard title="Fax" value={leadDetailData.fax} />
              <EventDescriptionCard
                title="Annual Revenue"
                value={leadDetailData.annual_revenue}
              />
              <EventDescriptionCard
                title="Skype ID"
                value={leadDetailData.skype_id}
              />
              <EventDescriptionCard
                title="Twitter"
                value={leadDetailData.twitter}
              />
              <EventDescriptionCard
                title="Country"
                value={leadDetailData?.country?.name}
              />
              <EventDescriptionCard
                title="State"
                value={leadDetailData?.state?.name}
              />
              <EventDescriptionCard title="City" value={leadDetailData?.city} />
              <EventDescriptionCard
                title="Street"
                value={leadDetailData.street}
              />
              <EventDescriptionCard
                title="Zip Code"
                value={leadDetailData.zip_code}
              />
              <EventDescriptionCard
                title="Description"
                value={leadDetailData.description}
              />
            </div>
          </>
        )}
      </Modal>

      {/* ! Delete Modal */}
      <Modal
        className="delete-modal"
        centered
        onOk={handleCloseDeleteModal}
        onCancel={handleCloseDeleteModal}
        afterClose={handleCloseDeleteModal}
        footer={false}
        width={600}
        title=""
        open={isDeleteModalOpen}
      >
        <div className="">
          <h1 className="text-[#000000;] text-[20px] font-popinsMedium mt-2">
            Are you sure you want to Delete this Lead ??
          </h1>
          <div className="flex items-center pt-8 justify-end w-full gap-x-3">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCloseDeleteModal}
                type="button"
                className="gray-button gray-hover text-white hover:text-white  min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Cancel
              </Button>
            </Form.Item>

            <Form.Item className="mb-0">
              <Button
                loading={deleteLeadLoader}
                type="primary"
                className="danger-btn  min-h-[45px] min-w-[120px]"
                htmlType="submit"
                onClick={() => {
                  dispatch(
                    actionDeleteLead({
                      id: leadId,
                      handleCloseDeleteModal,
                    })
                  );
                }}
              >
                Delete
              </Button>
            </Form.Item>
          </div>
        </div>
      </Modal>

      {/* Change Status */}
      <Modal
        width={500}
        centered={true}
        open={statusModal}
        footer={false}
        title=""
        loading={getLeadStatusLoader}
        destroyOnClose={handleCancelLeadStatusModal}
        onCancel={handleCancelLeadStatusModal}
        onOk={handleCancelLeadStatusModal}
      >
        <div className="text-brand flex items-center gap-x-3">
          <MdOutlineChangeCircle size={20} color="#6883FD" />

          <h1 className="lg:text-lg text-base  font-popinsRegular">
            Change Status
          </h1>
        </div>

        <Form
          form={statusForm}
          onFinish={(values) => {
            dispatch(
              actionLeadChangeStatus({
                values,
                id: leadDetailData.lead_id,
                handleCancelLeadStatusModal,
              })
            );
          }}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          {" "}
          <Form.Item
            name="lead_status_master_id"
            label="Lead Status"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select status!",
              },
            ]}
          >
            <Select
              options={
                statusData &&
                statusData.length > 0 &&
                statusData.map((s) => ({
                  ...s,
                  disabled:
                    s.value ===
                    leadDetailData?.lead_status?.lead_status_master_id,
                }))
              }
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={getLeadStatusLoader}
              disabled={getLeadStatusLoader}
              style={{ background: "white" }}
              placeholder="Select lead Status"
              className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item className="mt-6 mb-0">
            <Button
              loading={changeLeadStatusLoader}
              type="primary"
              className="root-btn min-h-[45px] min-w-[120px]"
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
          {/* </Col>
          </Row> */}
        </Form>
      </Modal>
    </section>
  );
};

export default Leads;
