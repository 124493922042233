import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { toast } from "sonner";
import {
  handleErrorMessage,
  startTimer,
} from "../../components/helper/common-function";
import setAuthToken from "../../config/setAuthToken";
import { BASE_URL } from "../../config/web-config";
import { handleGetResponse } from "./commonService";
import BusinessHoursConfig from "../../components/business/BusinessHoursConfig";
import UpdateBusinessHours from "../../components/setting/company-setting/UpdateBusinessHours";

// register
export const actionRegister = createAsyncThunk(
  "actionRegister",
  async (data, { rejectWithValue }) => {
    const { navigate, values } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/register`, values);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(`/otp`, { state: "fromRegister" });
        localStorage.setItem("userData", JSON.stringify(responseData));
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Login API
export const actionLogin = createAsyncThunk(
  "actionLogin",
  async (data, { rejectWithValue }) => {
    const { navigate, credentials, remember_me } = data;

    try {
      const res = await axios.post(`${BASE_URL}/auth/login`, credentials);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setAuthToken(responseData.accessToken);

        navigate && navigate("/");
        // }
        if (remember_me) {
          localStorage.setItem("crmHarborEmail", credentials.email);
          localStorage.setItem("crmHarborPassowrd", credentials.password);
        } else {
          localStorage.removeItem("crmHarborEmail");
          localStorage.removeItem("crmHarborPassowrd");
        }
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, is_verified: 1 })
        );
        return responseData;
      } else {
        toast.error(customMsg);
        return false;
      }
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        toast.error("Unable to reach the server. Please try again later.");
      } else {
        toast.error(error);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Auth me
export const authMe = createAsyncThunk(
  "authMe",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/auth/me`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 401) {
        localStorage.removeItem("crmWebToken");
        localStorage.removeItem("collapsed");
        setAuthToken(false);
        window.location.replace("/login");
        toast.error(customMsg, 5);
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      if (error.response.data.message === "Unauthorized") {
        localStorage.removeItem("crmWebToken");
        setAuthToken(false);
        window.location.replace("/login");
        toast.error(error?.message, 5);
      } else if (error?.code === "ERR_NETWORK") {
        toast.error(
          "Network error: Unable to reach the server. Please try again later."
        );
      }
      return rejectWithValue(error.message);
    }
  }
);

// Sign With Google
export const actionSignInWithGoogle = createAsyncThunk(
  "actionSignUpWithGoogle",
  async (data, { rejectWithValue }) => {
    const { navigate, values } = data;
    try {
      const res = await axios.post(`${BASE_URL}/sign-up-google`, values);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(`/otp`);
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

// send otp on Email
export const actionVerifyOTPEmail = createAsyncThunk(
  "actionVerifyOTPEmail",
  async ({ req, setVerifed, setEmailOtp }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/verify-email`, req);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        localStorage.setItem("userData", JSON.stringify(responseData));
        setVerifed((prev) => ({
          ...prev,
          is_email_verified: 1,
        }));
        setEmailOtp && setEmailOtp("");
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

// send otp on Mobile
export const actionVerifyOTPMobile = createAsyncThunk(
  "actionVerifyOTPMobile",
  async ({ req, setVerifed, navigate, setPhoneOtp }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/verify-phone`, req);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        // toast.success(customMsg, 5);
        setVerifed((prev) => ({
          ...prev,
          is_mobile_verified: 1,
        }));
        setPhoneOtp && setPhoneOtp("");
        navigate
          ? localStorage.setItem(
              "userData",
              JSON.stringify({ ...responseData, is_verified: 1 })
            )
          : localStorage.setItem("userData", JSON.stringify(responseData));
        navigate && navigate("/");
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//  resend otp Email
export const actionReSendOTPEmail = createAsyncThunk(
  "actionReSendOTPEmail",
  async (data, { rejectWithValue }) => {
    const { user_id, setEmailTimer } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/resend-otp-email`, {
        user_id: user_id,
      });
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        startTimer(0, setEmailTimer && setEmailTimer);
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//  resend otp Mobile
export const actionReSendOTPMobile = createAsyncThunk(
  "actionReSendOTPMobile",
  async ({ user_id, setPhoneTimer }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/resend-otp-phone`, {
        user_id: user_id,
      });
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        startTimer(0, setPhoneTimer && setPhoneTimer);
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//  Continue  Login
export const actionContinueToLogin = createAsyncThunk(
  "actionContinueToLogin",
  async ({ navigate, req }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/continue-to-login`, req);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, is_verified: 1 })
        );
        navigate && navigate("/");
        setAuthToken(responseData.accessToken);
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//  Login With OTP
export const actionLoginOTP = createAsyncThunk(
  "actionLoginOTP",
  async (
    { navigate, req, timer, setEmailTimer, phone },
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/login-with-otp`, req);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, phoneNum: phone })
        );
        timer && startTimer(0, setEmailTimer && setEmailTimer);
        navigate &&
          navigate("/otp", {
            state: {
              type: req.type,
              sendTo: req.send_to,
              iso: req.country_code,
              phoneNum: phone,
            },
          });

        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message, 5);
        rejectWithValue(error.response.data.message);
      } else {
        toast.error(error.message, 5);
        rejectWithValue(error.message);
      }
    }
  }
);

// send otp on Email
export const actionVerifyOtpLogin = createAsyncThunk(
  "actionVerifyOtpLogin",
  async ({ req, navigate, setPhoneOtp }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/auth/verify-otp-and-login`,
        req
      );
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, is_verified: 1 })
        );
        setPhoneOtp && setPhoneOtp("");
        setAuthToken(responseData.accessToken);
        navigate && navigate("/");
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//forgot password
export const actionForgotPassword = createAsyncThunk(
  "actionForgotPassword",
  async (data) => {
    const { values, navigate } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/forgot-password`,
        values
      );
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/login");
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

//reset password
export const actionResetPassword = createAsyncThunk(
  "actionResetPassword",
  async (data) => {
    const { request, navigate } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/reset-password`,
        request
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/login");
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

//change-mobileno
export const actionChangeMobile = createAsyncThunk(
  "actionChangeMobile",
  async (data) => {
    const { request, navigate, phone } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/change-phone-number`,
        request
      );
      const { status, message: customMsg, data: responseData } = response.data;
      localStorage.setItem(
        "userData",
        JSON.stringify({ ...responseData, phoneNum: phone })
      );
      if (parseInt(status) == 200) {
        toast.success(customMsg, 5);
        navigate(-1, {
          state: {
            type: "PHONE",
            phoneNum: phone,
            page: true,
          },
        });
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Check User exist with google login if not then navigate create-account else  loggedin
export const actionContinueWithGoogle = createAsyncThunk(
  "actionContinueWithGoogle",
  async (
    { token, navigate, setCustomLoader, user, isIdToken },
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/continue-with-google`, {
        token: token,
        token_type: !isIdToken ? "Access Token" : "ID Token",
      });
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, is_verified: 1 })
        );
        setAuthToken(responseData.accessToken);
        sessionStorage.removeItem("customLoader");
        navigate && navigate("/");
        setCustomLoader && setCustomLoader(false);
        return responseData;
      } else if (parseInt(status) === 404) {
        const req = {
          user_fullname: user?.name,
          user_email: user?.email,
          user_first_Name: user?.given_name,
          user_last_Name: user?.family_name,
          user_token: token,
          user_profile_pic: user?.picture,
        };
        sessionStorage.removeItem("customLoader");
        navigate &&
          navigate("/create-account", {
            state: req,
          });
        setCustomLoader && setCustomLoader(false);
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

// Register Company
export const actionRegisterCompany = createAsyncThunk(
  "actionRegisterCompany",
  async (data, { dispatch }) => {
    const { request, navigate } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/register-company`,
        request
      );

      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        // dispatch(authMe());
        navigate && navigate("/business-hours");
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

//actionAddBusinessHours
export const actionAddBusinessHours = createAsyncThunk(
  "actionAddBusinessHours",
  async (data, { dispatch }) => {
    const { req, navigate, from } = data;
    try {
      const response = await axios.post(`${BASE_URL}/company-hours`, req);
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        if (from !== "businessHours") {
          navigate && navigate("/");
          dispatch(authMe());
        }
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Logout User
export const actionLogout = createAsyncThunk(
  "actionLogout",
  async ({ navigate, setVisible }) => {
    setVisible && setVisible(false);
    localStorage.removeItem("crmWebToken");
    localStorage.removeItem("userData");

    setAuthToken(false);
    navigate("/login");
  }
);

//create-account
export const actionCreateAccount = createAsyncThunk(
  "actionCreateAccount",
  async (data, { rejectWithValue }) => {
    const { navigate, values, phone, from } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/create-account`, values);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate &&
          navigate(`/otp`, {
            state: {
              type: "PHONE",
              sendTo: responseData.phone,
              phoneNum: phone,
              page: true,
              from,
            },
          });
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, phoneNum: phone })
        );
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message, 5);
        rejectWithValue(error.response.data.message);
      } else {
        toast.error(error.message, 5);
        rejectWithValue(error.message);
      }
    }
  }
);

export const actionGetTimezones = createAsyncThunk(
  "actionGetTimezones",
  async (data, { rejectWithValue }) => {
    return handleGetResponse("timezones");
  }
);

// invite user from begin page
export const actionInviteUser = createAsyncThunk(
  "actionInviteUser",
  async (data, { rejectWithValue }) => {
    const { req, setIsModalOpen, setResetDataAfterAPI } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/company-users/send-invitation`,
        { data: req }
      );
      const { status, message: customMsg, data: responseData } = response.data;
      if (parseInt(status) === 200) {
        setResetDataAfterAPI && setResetDataAfterAPI(true);
        if (responseData.errors && responseData.errors.length > 0) {
          toast.error(
            `${responseData.errors.length} invitations failed to send. please check`,
            5
          );
        }
        if (!responseData.errors || responseData.errors.length === 0) {
          toast.success(
            `${
              responseData &&
              responseData.successes &&
              responseData.successes.length
            } invitations sent successfully. please check`,
            5
          );
          setIsModalOpen && setIsModalOpen(false);
        }
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

export const actionClearInviteMessages = createAsyncThunk(
  "actionClearInviteMessages",
  async () => {
    return true;
  }
);

//check auth status before accept invitation
export const actionCheckAuthStatus = createAsyncThunk(
  "actionCheckAuthStatus",
  async (data, { rejectWithValue }) => {
    const { token, navigate, senderEmail } = data;
    try {
      const response = await axios.get(
        `${BASE_URL}/auth/status?token=${token}`
      );
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        return response.data;
      } else if (parseInt(status) === 404) {
        const DATA = "USER_NOT_FOUND";
        return DATA;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return false;
    }
  }
);

//accept invitation
export const actionAcceptInvitation = createAsyncThunk(
  "actionAcceptInvitation",
  async (data) => {
    const { request, navigate, senderEmail } = data;
    try {
      const response = await axios.post(`${BASE_URL}/auth/accept-invitation`, {
        token: request,
      });
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem("crmWebToken", data.accessToken);
        navigate && navigate("/");
      } else if (parseInt(status) === 404) {
        navigate &&
          navigate("/accept-invitation-create-account", {
            state: { user_token: request, senderEmail },
          });
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// reject invitation
export const actionRejectInvitation = createAsyncThunk(
  "actionRejectInvitation",
  async (data) => {
    const { request } = data;
    try {
      const response = await axios.post(`${BASE_URL}/auth/reject-invitation`, {
        token: request,
      });
      const { status, message: customMsg, responseData } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        // localStorage.removeItem("crmWebToken", responseData.accessToken);
        // navigate && navigate("/");
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);
