import {
  SearchOutlined,
  UploadOutlined,
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  Typography,
  Upload,
} from "antd";
import gallery from "../../../assets/img/gallery.svg";
import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusSvg from "../../../assets/img/plus.svg";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import productImage from "../../../assets/img/product-img.svg";
import SortIcon from "../../common/SortIcon";
import Slider from "react-slick";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import { toast } from "sonner";
import { IoMdClose } from "react-icons/io";
import {
  actionAddHsnSacCodeMaster,
  actionAddProductCategoryMaster,
  actionAddProductMaster,
  actionAddProductSubCategoryMaster,
  actionAddProductTypeMaster,
  actionAddUnitMaster,
  actionDeleteProductMaster,
  actionGetHsnSacMaster,
  actionGetProductCategoryMaster,
  actionGetProductMaster,
  actionGetProductsMaster,
  actionGetProductSubCategoryMaster,
  actionGetProductTypeMaster,
  actionGetUnitMaster,
  actionUpdateProductMaster,
} from "../../../store/services/productMasterService";
import {
  actionAddMaterialGroupMaster,
  actionGetMaterialGroupMaster,
} from "../../../store/services/generalMasterService";
import axios from "axios";
import {
  isNullOrEmpty,
  isValidSearchString,
} from "../../helper/common-function";
import AddPopover from "../../common/AddPopover";
import DeleteModal from "../../common/DeleteModal";

const Material = () => {
  const [form] = Form.useForm();
  const [addForm] = Form.useForm();
  const [baseUnitForm] = Form.useForm();
  const [hsnSacForm] = Form.useForm();
  const [materialGroupForm] = Form.useForm();
  const [categoryForm] = Form.useForm();
  const [subCategoryForm] = Form.useForm();
  const [materialTypeForm] = Form.useForm();
  const [standardWeightUnitForm] = Form.useForm();
  const dispatch = useDispatch();
  const {
    productTypeData,
    getProductsLoader,
    addProductLoader,
    productsData,
    productSubCategoryData,
    getProductSubCategoryLoader,
    getProductCategoryLoader,
    productCategoryData,
    getUnitMasterLoader,
    unitMasterData,
    getHsnSacLoader,
    hsnSacCodeData,
    addUnitLoader,
    addHsnSacCodeLoader,
    addProductCategoryLoader,
    addProductSubCategoryLoader,
    addProductTypeLoader,
    getSingleProductLoader,
    getSingleProductData,
    updateProductLoader,
    deleteProductLoader,
  } = useSelector((state) => state.productMaster);

  const {
    getMaterialGroupLoader,
    materialGroupMasterData,
    addMaterialGroupMasterLoader,
  } = useSelector((state) => state.generalMaster);

  const [search, setSearch] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [materialType, setMaterialType] = useState(null);
  const [materialImages, setMaterialImages] = useState([]);
  const [isBaseUnitPopoverOpen, setIsBaseUnitPopoverOpen] = useState(false);
  const [isHsnSacPopoverOpen, setIsHsnSacPopoverOpen] = useState(false);
  const [isStandardWeightUnitPopoverOpen, setIsStandardWeightUnitPopoverOpen] =
    useState(false);
  const [isMaterialGroupPopoverOpen, setIsMaterialGroupPopoverOpen] =
    useState(false);
  const [isCategoryPopoverOpen, setIsCategoryPopoverOpen] = useState(false);
  const [isSubCategoryPopoverOpen, setIsSubCategoryPopoverOpen] =
    useState(false);
  const [isMaterialTypePopoverOpen, setIsMaterialTypePopoverOpen] =
    useState(false);
  const [uploading, setUploading] = useState(false);
  const [materialId, setMaterialId] = useState(null);

  useEffect(() => {
    dispatch(actionGetProductsMaster());
  }, [dispatch]);

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(actionGetProductTypeMaster());
    dispatch(
      actionGetProductCategoryMaster({
        status: "Active",
        orderByColumn: "category_name",
        order: "ASC",
      })
    );
    dispatch(actionGetUnitMaster());
    dispatch(
      actionGetProductSubCategoryMaster({
        status: "Active",
        orderByColumn: "sub_category_name",
        order: "ASC",
      })
    );
    dispatch(actionGetHsnSacMaster());
    dispatch(actionGetMaterialGroupMaster());
  };

  useEffect(() => {
    if (getSingleProductData && materialId) {
      setMaterialType(
        getSingleProductData.material_type?.product_type_master_id
      );
      if (
        getSingleProductData.material_attachments &&
        getSingleProductData.material_attachments.length > 0
      ) {
        const attachments = getSingleProductData.material_attachments.map(
          (attachment) => ({
            url: attachment.upload?.url,
            upload_id: attachment.upload?.upload_id,
            file_name: attachment.upload?.file_name,
            size: attachment.upload?.size,
            file_type: attachment.upload?.file_type,
          })
        );
        setMaterialImages(attachments);
      }
      addForm.setFieldsValue({
        material_name: getSingleProductData.material_name,
        material_code: getSingleProductData.material_code,
        material_description: getSingleProductData.material_description,
        base_unit_master_id: getSingleProductData.base_unit_of_measure
          ? getSingleProductData.base_unit_of_measure?.unit_master_id
          : null,
        material_group_master_id: getSingleProductData.material_group
          ? getSingleProductData.material_group?.material_group_master_id
          : null,
        material_category_id: getSingleProductData.material_category
          ? getSingleProductData.material_category?.product_category_master_id
          : null,
        material_sub_category_id: getSingleProductData.material_sub_category
          ? getSingleProductData.material_sub_category
              ?.product_sub_category_master_id
          : null,
        standard_weight_unit_master_id:
          getSingleProductData.standard_weight_unit
            ? getSingleProductData.standard_weight_unit?.unit_master_id
            : null,
        hsn_code_master_id: getSingleProductData.hsn_code
          ? getSingleProductData.hsn_code?.hsn_code_master_id
          : null,
        min_stock_level: getSingleProductData.min_stock_level,
        max_stock_level: getSingleProductData.max_stock_level,
        standard_weight: getSingleProductData.standard_weight,
        batch_required: getSingleProductData.batch_required,
        shelf_life: getSingleProductData.shelf_life,
        shelf_life_period: getSingleProductData.shelf_life_period,
        erp_code: getSingleProductData.erp_code,
        price: getSingleProductData.price,
        material_description: getSingleProductData.material_description,
      });
    }
  }, [dispatch, getSingleProductData, materialId]);

  const subCategoryOptions = useMemo(() => {
    return productSubCategoryData && productSubCategoryData.length > 0
      ? productSubCategoryData.map((data) => ({
          label: data.sub_category_name,
          value: data.product_sub_category_master_id,
        }))
      : [];
  }, [productSubCategoryData]);

  const categoryOptions = useMemo(() => {
    return productCategoryData && productCategoryData.length > 0
      ? productCategoryData.map((data) => ({
          label: data.category_name,
          value: data.product_category_master_id,
        }))
      : [];
  }, [productCategoryData]);

  const baseUnitptions = useMemo(() => {
    return unitMasterData && unitMasterData.length > 0
      ? unitMasterData.map((data) => ({
          label: data.code,
          value: data.unit_master_id,
        }))
      : [];
  }, [unitMasterData]);

  const hsnSacOptions = useMemo(() => {
    return hsnSacCodeData && hsnSacCodeData.length > 0
      ? hsnSacCodeData.map((data) => ({
          label: data.hsn_code,
          value: data.hsn_code_master_id,
        }))
      : [];
  }, [hsnSacCodeData]);

  const materialGroupOptions = useMemo(() => {
    return materialGroupMasterData && materialGroupMasterData.length > 0
      ? materialGroupMasterData.map((data) => ({
          label: data.name,
          value: data.material_group_master_id,
        }))
      : [];
  }, [materialGroupMasterData]);

  const handleClose = () => {
    setIsModalOpen(false);
    addForm.resetFields();
    setMaterialImages([]);
    setMaterialType(null);
    setMaterialId(null);
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: 100,
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Material Image",
      dataIndex: "material_attachments",
      key: "2",
      width: 200,
      ellipsis: true,
      render: (text) =>
        text && text.length > 0 ? (
          <Image.PreviewGroup
            items={text.map((img) => ({ src: img?.upload.url }))}
          >
            <Image
              width={70}
              height={70}
              className="object-contain"
              src={text[0].upload?.url}
            />
          </Image.PreviewGroup>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: "Material Name",
      dataIndex: "material_name",
      key: "3",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span
          className="ml-2 text-brand cursor-pointer"
          onClick={() => {
            showModal();
            setMaterialId(record.material_master_id);
            dispatch(actionGetProductMaster(record.material_master_id));
          }}
        >
          {isNullOrEmpty(text)}
        </span>
      ),
    },
    {
      title: "Material Type",
      dataIndex: "material_type",
      key: "3",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.product_type)}</span>
      ),
    },
    {
      title: "Material Description",
      dataIndex: "material_description",
      key: "4",
      width: 300,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{isNullOrEmpty(text)}</span>,
    },
    {
      title: "Base Unit of Measure",
      dataIndex: "base_unit_of_measure",
      key: "5",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.code)}</span>
      ),
    },

    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "7",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.name)}</span>
      ),
    },

    {
      title: "Material Category",
      dataIndex: "material_category",
      key: "7",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.category_name)}</span>
      ),
    },

    {
      title: "Material Sub Category",
      dataIndex: "material_sub_category",
      key: "7",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.sub_category_name)}</span>
      ),
    },
    {
      title: "Standard Weight Unit",
      dataIndex: "standard_weight_unit",
      key: "7",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.code)}</span>
      ),
    },
    {
      title: "Action",
      key: "7",
      width: 150,
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <div className="cursor-pointer">
              <Popconfirm
                okButtonProps={{
                  loading: deleteProductLoader,
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(actionDeleteProductMaster(record.material_master_id))
                }
                className="flex items-center"
              >
                <img
                  src={deletevent}
                  alt="Delete Icon"
                  className="cursor-pointer"
                />{" "}
                <span className="text-sm text-red-500 tracking-wide ml-2">
                  {" "}
                  Delete
                </span>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetProductsMaster({
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetProductsMaster({
          search: e.target.value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetProductsMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    initialSlide: 0,
    draggable: true, // Allow dragging to scroll

    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const typeSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const handleUpload = async (files) => {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));

    try {
      setUploading(true);
      const response = await axios.post(
        `${BASE_URL_UPLOAD}/upload-multiple`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const newFiles = response.data.data.map((file) => ({
        url: file.url,
        upload_id: file.upload_id,
        file_name: file.originalname,
        size: file.size,
        file_type: file.mimetype,
      }));
      setMaterialImages((prevFiles) => [...prevFiles, ...newFiles]);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      await handleUpload(files);
    }
  };

  const handleRemoveMaterial = (id) => {
    const updatedMaterialImages =
      materialImages &&
      materialImages.length > 0 &&
      materialImages.filter((img) => img.upload_id !== id);
    setMaterialImages(updatedMaterialImages);
  };

  const onFinish = (req) => {
    const values = {
      ...req,
      material_type_id: materialType ? materialType : null,
      min_stock_level: req.min_stock_level
        ? parseInt(req.min_stock_level)
        : null,
      max_stock_level: req.max_stock_level
        ? parseInt(req.max_stock_level)
        : null,
      material_attachments:
        materialImages && materialImages.length > 0
          ? materialImages.map((att) => att.upload_id)
          : [],

      standard_weight: req.standard_weight
        ? parseInt(req.standard_weight)
        : null,
      shelf_life: req.shelf_life ? parseInt(req.shelf_life) : null,
    };


    materialId
      ? dispatch(
          actionUpdateProductMaster({
            materialId,
            values,
            handleCancel: handleClose,
          })
        )
      : dispatch(actionAddProductMaster({ values, handleCancel: handleClose }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    if (files && files.length > 0) {
      handleUpload(files);
    }
  };

  // Popover and close
  const closeBaseUnitPopover = () => {
    setIsBaseUnitPopoverOpen(false);
    baseUnitForm.resetFields();
  };

  const closeStandardWeightUnitPopover = () => {
    setIsStandardWeightUnitPopoverOpen(false);
    baseUnitForm.resetFields();
  };

  const closeHsnSacPopover = () => {
    setIsHsnSacPopoverOpen(false);
    hsnSacForm.resetFields();
  };

  const closeMaterialGroupPopover = () => {
    setIsMaterialGroupPopoverOpen(false);
    materialGroupForm.resetFields();
  };

  const closeCategoryPopover = () => {
    setIsCategoryPopoverOpen(false);
    categoryForm.resetFields();
  };

  const closeSubCategoryPopover = () => {
    setIsSubCategoryPopoverOpen(false);
    subCategoryForm.resetFields();
  };

  const closeMaterialTypePopover = () => {
    setIsMaterialTypePopoverOpen(false);
    materialTypeForm.resetFields();
  };

  // Popover content start here
  const showBaseUnitContent = (field) => {
    return (
      <Form
        form={baseUnitForm}
        onFinish={(values) =>
          dispatch(
            actionAddUnitMaster({
              values,
              handleCancel:
                field === "base_unit_master_id"
                  ? closeBaseUnitPopover
                  : closeStandardWeightUnitPopover,
              form: addForm,
              field: field,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="code"
          label="Unit Code"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please enter unit code!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Unit"
            className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Unit Description"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            style={{ background: "white" }}
            placeholder="Enter Description"
            className="!indent-1 pt-2 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={
                field === "base_unit_master_id"
                  ? closeBaseUnitPopover
                  : closeStandardWeightUnitPopover
              }
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addUnitLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showHsnSacContent = () => {
    return (
      <Form
        form={hsnSacForm}
        onFinish={(values) =>
          dispatch(
            actionAddHsnSacCodeMaster({
              values,
              handleCancel: closeHsnSacPopover,
              form: addForm,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="hsn_code"
          label="HSN/SAC Code"
          className="mb-[12px]"
          size="small"
          normalize={(value) => value && value.replace(/[^+\d]/g, "")}
          rules={[
            {
              required: true,
              message: "Please input hsn/sac code!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter HSN/SAC Code"
            className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          className="mb-[12px]"
          size="small"
        >
          <Input.TextArea
            rows={3}
            style={{ background: "white" }}
            placeholder="Enter Description"
            className="!indent-1 pt-2 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeHsnSacPopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addHsnSacCodeLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showMaterialGroupContent = () => {
    return (
      <Form
        form={materialGroupForm}
        onFinish={(values) =>
          dispatch(
            actionAddMaterialGroupMaster({
              values,
              handleCancel: closeMaterialGroupPopover,
              form: addForm,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="name"
          label="Material Group"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please input Material Group!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Material Group"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          className="mb-[12px]"
          size="small"
        >
          <Input.TextArea
            rows={3}
            style={{ background: "white" }}
            placeholder="Enter Description"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeMaterialGroupPopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addMaterialGroupMasterLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showCategoryContent = () => {
    return (
      <Form
        form={categoryForm}
        initialValues={{
          status: "Active",
        }}
        onFinish={(values) =>
          dispatch(
            actionAddProductCategoryMaster({
              values,
              handleCancel: closeCategoryPopover,
              form: addForm,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="category_name"
          label="Product Category Name"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please input Product Category Name!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Product Category Name"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please select status!",
            },
          ]}
        >
          <Select
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: "Active",
                label: "Active",
              },
              {
                value: "Archive",
                label: "Archive",
              },
              {
                value: "Inactive",
                label: "Inactive",
              },
            ]}
            style={{ background: "white" }}
            placeholder="Select Status"
            className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeCategoryPopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addProductCategoryLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showSubCategoryContent = () => {
    return (
      <Form
        form={subCategoryForm}
        initialValues={{
          status: "Active",
        }}
        onFinish={(values) =>
          dispatch(
            actionAddProductSubCategoryMaster({
              values,
              handleCancel: closeSubCategoryPopover,
              form: addForm,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="product_category_id"
          label="Product Category"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please select product category!",
            },
          ]}
        >
          <Select
            options={categoryOptions}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            loading={getProductCategoryLoader}
            disabled={getProductCategoryLoader}
            style={{ background: "white" }}
            placeholder="Select product category"
            className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="sub_category_name"
          label="Product Sub Category Name"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please input product sub category name!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Product Sub Category Name"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please select status!",
            },
          ]}
        >
          <Select
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: "Active",
                label: "Active",
              },
              {
                value: "Archive",
                label: "Archive",
              },
              {
                value: "Inactive",
                label: "Inactive",
              },
            ]}
            style={{ background: "white" }}
            placeholder="Select Status"
            className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeSubCategoryPopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addProductSubCategoryLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showMaterialTypeContent = () => {
    return (
      <Form
        form={materialTypeForm}
        onFinish={(values) =>
          dispatch(
            actionAddProductTypeMaster({
              values,
              handleCancel: closeMaterialTypePopover,
              setMaterialType,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="product_type"
          label="Material Type"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please input material type!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Material Type"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            style={{ background: "white" }}
            placeholder="Enter Description"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeMaterialTypePopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addProductTypeLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  /* const show */

  return (
    <>
      <div className="event-input">
        <div className="flex justify-between mt-[15px]">
          <h1 className="text-[#6883FD] text-[20px] font-medium">Material</h1>
          <div>
            <Button
              onClick={showModal}
              className="root-btn text-white hover:text-white py-5 flex-center radius"
              icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
            >
              Add Material
            </Button>
            <Modal
              loading={getSingleProductLoader}
              centered
              onOk={handleClose}
              onCancel={handleClose}
              footer={false}
              width={1200}
              title=""
              open={isModalOpen}
            >
              <div className="flex">
                <img src={productImage} alt="" />
                <h1 className="text-[#6883FD] text-[20px]  ml-[8px] mt-[5px] font-medium">
                  Add Material
                </h1>
              </div>
              <Form
                form={addForm}
                onFinish={onFinish}
                onFinishFailed={(errorFields) => console.log({ errorFields })}
                layout="vertical"
                className="mt-[20px] px-1 mb-0"
              >
                <Row gutter={16}>
                  <Col span={9}>
                    <Card
                      className=" my-5"
                      title={
                        <h1 className="font-popinsSemiBold">Material Image</h1>
                      }
                    >
                      <div className="slider-container">
                        <Slider {...settings} className="material-slider">
                          <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className="mr-3 rounded-xl min-w-40 max-w-40 h-[150px] cursor-pointer border border-dashed flex items-center justify-center border-[#6883FD]"
                          >
                            {uploading ? (
                              <div className="flex gap-x-2 items-center justify-center h-full w-full">
                                <LoadingOutlined
                                  height={18}
                                  width={18}
                                  color="#7C7C7C"
                                />
                                <p className="text-sm font-popinsRegular text-[#000]">
                                  Uploading...
                                </p>
                              </div>
                            ) : (
                              <label
                                htmlFor="file-upload"
                                className="h-full w-full"
                              >
                                <div className="flex items-center  cursor-pointer h-full gap-x-5 px-[13px] justify-center">
                                  <Image
                                    src={gallery}
                                    preview={false}
                                    width={50}
                                    height={50}
                                    alt="Gallery"
                                  />
                                  <p className="text-left text-[11px]">
                                    Drop your file here or{" "}
                                    <span className="text-brand font-semibold">
                                      Click to Browse
                                    </span>
                                  </p>
                                </div>
                              </label>
                            )}
                          </div>
                          <input
                            id="file-upload"
                            type="file"
                            hidden
                            multiple
                            onChange={handleFileChange}
                            className="!hidden"
                            style={{ display: "none" }}
                            accept=".jpeg,.jpg,.png,.gif,.pdf,.doc"
                          />

                          {materialImages &&
                            materialImages.length > 0 &&
                            materialImages.map((data, index) => (
                              <div
                                key={index}
                                className={`mr-3 group rounded-xl relative border border-gray min-w-40 max-w-40 h-[150px] cursor-pointer`}
                                onClick={() => (isDragging ? "" : "")}
                                style={{
                                  cursor: isDragging ? "grabbing" : "grab",
                                }}
                              >
                                <img
                                  className="rounded-xl object-cover h-full w-full"
                                  src={data.url}
                                  alt="material-image"
                                />
                                <div
                                  onClick={() =>
                                    handleRemoveMaterial(data.upload_id)
                                  }
                                  className="bg-red-500 cursor-pointer absolute top-0 right-0 w-[30px] rounded-tr-[9px] rounded-bl-xl h-[30px] flex items-center justify-center"
                                >
                                  <IoMdClose color="white" size={20} />
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </Card>
                  </Col>
                  <Col span={15}>
                    <Card
                      className="my-5 min-h-[260px]"
                      title={
                        <div className="flex items-center justify-between">
                          <h1 className="">Material Type</h1>
                          <AddPopover
                            content={showMaterialTypeContent}
                            isOpen={isMaterialTypePopoverOpen}
                            onOpenChange={(visible) => {
                              if (!visible) {
                                setIsMaterialTypePopoverOpen(false);
                                materialTypeForm.resetFields();
                              }
                            }}
                            text="Add Type"
                            showModal={() =>
                              setIsMaterialTypePopoverOpen((prev) => !prev)
                            }
                          />
                        </div>
                      }
                    >
                      <div className="slider-container">
                        {productTypeData && productTypeData.length > 0 ? (
                          <Slider {...typeSettings}>
                            {productTypeData.map((data, index) => (
                              <div key={index} className="py-3">
                                <div
                                  className={`p-3 mr-3 rounded-xl border ${
                                    materialType === data.product_type_master_id
                                      ? "border-[#6883FD] bg-custom-gradient"
                                      : "border-[#DDDAE9] bg-[#FCFBFD]"
                                  } min-w-90 max-w-90 min-h-24 cursor-pointer`}
                                  onClick={() =>
                                    isDragging
                                      ? ""
                                      : setMaterialType((prev) =>
                                          prev === data.product_type_master_id
                                            ? null
                                            : data.product_type_master_id
                                        )
                                  }
                                  style={{
                                    cursor: isDragging ? "grabbing" : "grab",
                                  }}
                                >
                                  <h3 className="font-popinsMedium">
                                    {data.product_type}
                                  </h3>
                                  <Typography.Paragraph
                                    ellipsis={{ rows: 2 }}
                                    className="font-popinsRegular text-xs mt-1"
                                  >
                                    {data.description}
                                  </Typography.Paragraph>
                                </div>
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <Empty description="No Material Type found" />
                        )}
                      </div>
                    </Card>
                  </Col>

                  <Col span={24}>
                    <Card
                      title={
                        <h1 className="font-popinsSemiBold">
                          Material Information
                        </h1>
                      }
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please enter material name!",
                              },
                            ]}
                            label="Material Name"
                            name="material_name"
                          >
                            <Input placeholder="Enter Material Name" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="ERP Code" name="erp_code">
                            <Input placeholder="Enter ERP Code" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item label="Material Code" name="material_code">
                            <Input placeholder="Enter Material Code" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            normalize={(value) =>
                              value && value.replace(/[^+\d]/g, "")
                            }
                            label="Price"
                            name="price"
                          >
                            <Input placeholder="Enter Price" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            normalize={(value) =>
                              value && value.replace(/[^+\d]/g, "")
                            }
                            label="Min Stock Level"
                            name="min_stock_level"
                          >
                            <Input placeholder="Enter Min Stock Level" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            normalize={(value) =>
                              value && value.replace(/[^+\d]/g, "")
                            }
                            label="Max Stock Level"
                            name="max_stock_level"
                          >
                            <Input placeholder="Enter Max Stock Level" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <AddPopover
                            content={showCategoryContent}
                            isOpen={isCategoryPopoverOpen}
                            onOpenChange={(visible) => {
                              if (!visible) {
                                setIsCategoryPopoverOpen(false);
                                categoryForm.resetFields();
                              }
                            }}
                            text="Add Category"
                            showModal={() =>
                              setIsCategoryPopoverOpen(!isCategoryPopoverOpen)
                            }
                          />
                          <Form.Item
                            label="Category"
                            name="material_category_id"
                          >
                            <Select
                              allowClear
                              showSearch
                              loading={getProductCategoryLoader}
                              disabled={getProductCategoryLoader}
                              placeholder="Select Category"
                              optionFilterProp="children"
                              className=""
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={categoryOptions}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <AddPopover
                            content={showSubCategoryContent}
                            isOpen={isSubCategoryPopoverOpen}
                            onOpenChange={(visible) => {
                              if (!visible) {
                                setIsSubCategoryPopoverOpen(false);
                                subCategoryForm.resetFields();
                              }
                            }}
                            text="Add Sub Category"
                            showModal={() =>
                              setIsSubCategoryPopoverOpen(
                                !isSubCategoryPopoverOpen
                              )
                            }
                          />

                          <Form.Item
                            label="Sub Category"
                            name="material_sub_category_id"
                          >
                            <Select
                              allowClear
                              showSearch
                              loading={getProductSubCategoryLoader}
                              disabled={getProductSubCategoryLoader}
                              placeholder="Select Sub Category"
                              optionFilterProp="children"
                              className=""
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={subCategoryOptions}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <AddPopover
                            content={() =>
                              showBaseUnitContent("base_unit_master_id")
                            }
                            isOpen={isBaseUnitPopoverOpen}
                            onOpenChange={(visible) => {
                              if (!visible) {
                                setIsBaseUnitPopoverOpen(false);
                              }
                            }}
                            showModal={() =>
                              setIsBaseUnitPopoverOpen(!isBaseUnitPopoverOpen)
                            }
                            text="Add Base Unit"
                          />

                          <Form.Item
                            label="Base Unit of Measure"
                            name="base_unit_master_id"
                          >
                            <Select
                              allowClear
                              showSearch
                              loading={getUnitMasterLoader}
                              disabled={getUnitMasterLoader}
                              placeholder="Select Base Unit"
                              optionFilterProp="children"
                              className=""
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={baseUnitptions}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <AddPopover
                            content={showMaterialGroupContent}
                            isOpen={isMaterialGroupPopoverOpen}
                            onOpenChange={(visible) => {
                              if (!visible) {
                                setIsMaterialGroupPopoverOpen(false);
                                materialGroupForm.resetFields();
                              }
                            }}
                            text="Add Material Group"
                            showModal={() =>
                              setIsMaterialGroupPopoverOpen(
                                !isMaterialGroupPopoverOpen
                              )
                            }
                          />

                          <Form.Item
                            label="Material Group"
                            name="material_group_master_id"
                          >
                            <Select
                              allowClear
                              showSearch
                              loading={getMaterialGroupLoader}
                              disabled={getMaterialGroupLoader}
                              placeholder="Select Material Group"
                              optionFilterProp="children"
                              className=""
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={materialGroupOptions}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Standard Weight"
                            name="standard_weight"
                          >
                            <Input placeholder="Standard Weight" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          {/*  <AddPopover
                            content={() =>
                              showBaseUnitContent(
                                "standard_weight_unit_master_id"
                              )
                            }
                            isOpen={isStandardWeightUnitPopoverOpen}
                            onOpenChange={(visible) => {
                              if (!visible) {
                                setIsStandardWeightUnitPopoverOpen(false);
                                standardWeightUnitForm.resetFields();
                              }
                            }}
                            text="Add Standard Weight Unit"
                            showModal={() =>
                              setIsStandardWeightUnitPopoverOpen(
                                (prev) => !prev
                              )
                            }
                          /> */}

                          <AddPopover
                            content={() =>
                              showBaseUnitContent(
                                "standard_weight_unit_master_id"
                              )
                            }
                            isOpen={isStandardWeightUnitPopoverOpen}
                            onOpenChange={(visible) => {
                              if (!visible) {
                                setIsStandardWeightUnitPopoverOpen(false);
                                hsnSacForm.resetFields();
                              }
                            }}
                            showModal={() =>
                              setIsStandardWeightUnitPopoverOpen(
                                (prev) => !prev
                              )
                            }
                            text="Add Standard Weight Unit"
                          />

                          <Form.Item
                            label="Standard Weight Unit"
                            name="standard_weight_unit_master_id"
                          >
                            <Select
                              allowClear
                              showSearch
                              loading={getUnitMasterLoader}
                              disabled={getUnitMasterLoader}
                              placeholder="Select Standard Weight Unit"
                              optionFilterProp="children"
                              className=""
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={baseUnitptions}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <AddPopover
                            content={showHsnSacContent}
                            isOpen={isHsnSacPopoverOpen}
                            onOpenChange={(visible) => {
                              if (!visible) {
                                setIsHsnSacPopoverOpen(false);
                                hsnSacForm.resetFields();
                              }
                            }}
                            showModal={() =>
                              setIsHsnSacPopoverOpen((prev) => !prev)
                            }
                            text="Add HSN/SAC Code"
                          />

                          <Form.Item
                            label="HSN/SAC Code"
                            name="hsn_code_master_id"
                          >
                            <Select
                              allowClear
                              showSearch
                              loading={getHsnSacLoader}
                              disabled={getHsnSacLoader}
                              placeholder="Select HSN/SAC Code"
                              optionFilterProp="children"
                              className=""
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={hsnSacOptions}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Batch required"
                            name="batch_required"
                          >
                            <Radio.Group className="border rounded-[10px] justify-between  border-[#DDDAE9] w-full flex">
                              <Radio
                                value="Yes"
                                className=" w-1/2  py-3 border-r m-0 border-[#DDDAE9]  flex items-center justify-center"
                              >
                                Yes
                              </Radio>
                              <Radio
                                value="No"
                                className=" w-1/2 py-3 m-0  flex items-center justify-center"
                              >
                                No
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Shelf Life" name="shelf_life">
                            <Input
                              style={{ width: "100%" }}
                              placeholder="Enter Shelf Life"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Shelf Life period indicator"
                            name="shelf_life_period"
                          >
                            <Radio.Group size="large" buttonStyle="solid">
                              <Radio.Button value="days">Days</Radio.Button>
                              <Radio.Button value="weeks">Weeks</Radio.Button>
                              <Radio.Button value="months">Months</Radio.Button>
                              <Radio.Button value="years">Years</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Description"
                            name="material_description"
                          >
                            <Input.TextArea
                              rows={5}
                              placeholder="Enter Description"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <div className="flex justify-end  mt-[40px]">
                  <Button
                    onClick={handleClose}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                  <div className="ml-4">
                    <Form.Item className="mb-0">
                      <Button
                        loading={addProductLoader || updateProductLoader}
                        type="primary"
                        className="root-btn min-h-[45px] min-w-[120px]"
                        htmlType="submit"
                      >
                        {materialId ? "Update" : "Save"}
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </Modal>
          </div>
        </div>
        <div className="pt-[25px]">
          <Input
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            onBlur={handleBlurAndPressEnter}
            onPressEnter={handleBlurAndPressEnter}
            prefix={<SearchOutlined className="text-[#7C7C7C]" />}
          />
        </div>

        <div className="pt-5 pb-[75px]">
          <Table
            // virtual
            scroll={{ /* x: "max-content" ,*/ y: 500 }}
            pagination={false}
            loading={getProductsLoader}
            bordered
            onChange={handleSortChange}
            dataSource={productsData}
            rowKey="material_master_id"
            columns={columns}
            rowClassName="editable-row"
          />
        </div>
      </div>
    </>
  );
};

export default Material;
