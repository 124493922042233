import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddTask,
  actionClearAllTasks,
  actionDeleteTasks,
  actionGetAllTasks,
  actionGetTaskDetail,
  actionGetTasks,
  actionUpdateTask,
} from "../services/taskService";

const taskSlice = createSlice({
  name: "task",
  initialState: {
    getTasksLoader: false,
    getTaskDetailLoader: false,
    alltasksCount: 0,
    addTaskLoader: false,
    tasks: [],
    getTaskDetail: false,
    getAllTasksLoader: false,
    deleteTaskLoader: false,
    updateTaskLoader: false,
    allTasks: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionGetTasks.pending, (state) => {
        state.getTasksLoader = true;
      })
      .addCase(actionGetTasks.fulfilled, (state, action) => {
        state.getTasksLoader = false;
        state.tasks = action.payload.data;
        state.alltasksCount = action.payload.total_records;
      })
      .addCase(actionGetTasks.rejected, (state) => {
        state.getTasksLoader = false;
      })

      // clear all data
      .addCase(actionClearAllTasks.pending, (state, action) => {
        state.allTasks = action.payload;
      })

      .addCase(actionGetAllTasks.pending, (state) => {
        state.getAllTasksLoader = true;
      })
      .addCase(actionGetAllTasks.fulfilled, (state, action) => {
        state.getAllTasksLoader = false;
        state.allTasks = action.payload.data;
      })
      .addCase(actionGetAllTasks.rejected, (state) => {
        state.getAllTasksLoader = false;
      })

      .addCase(actionGetTaskDetail.pending, (state) => {
        state.getTaskDetailLoader = true;
      })
      .addCase(actionGetTaskDetail.fulfilled, (state, action) => {
        state.getTaskDetailLoader = false;
        state.getTaskDetail = action.payload.data;
      })
      .addCase(actionGetTaskDetail.rejected, (state) => {
        state.getTaskDetailLoader = false;
      })

      .addCase(actionAddTask.pending, (state) => {
        state.addTaskLoader = true;
      })

      .addCase(actionAddTask.fulfilled, (state, action) => {
        state.addTaskLoader = false;
        if (!state.tasks || !Array.isArray(state.tasks)) {
          state.tasks = [action.payload];
        } else {
          state.tasks.unshift(action.payload);
        }

        state.alltasksCount = state.alltasksCount + 1;
      })
      .addCase(actionAddTask.rejected, (state) => {
        state.addTaskLoader = false;
      })

      .addCase(actionUpdateTask.pending, (state) => {
        state.updateTaskLoader = true;
      })
      .addCase(actionUpdateTask.fulfilled, (state, action) => {
        state.updateTaskLoader = false;
        state.tasks = state.tasks.map((task) =>
          task.task_id === action.payload.task_id
            ? { ...task, ...action.payload }
            : task
        );
      })
      .addCase(actionUpdateTask.rejected, (state) => {
        state.updateTaskLoader = false;
      })

      .addCase(actionDeleteTasks.pending, (state) => {
        state.deleteTaskLoader = true;
      })
      .addCase(actionDeleteTasks.fulfilled, (state, action) => {
        state.deleteTaskLoader = false;
        state.tasks = state.tasks.filter(
          (task) => task.task_id !== action.payload
        );
      })
      .addCase(actionDeleteTasks.rejected, (state) => {
        state.deleteTaskLoader = false;
      });
  },
});

export default taskSlice.reducer;
