import * as CryptoJS from "crypto-js";
import logo from "../../assets/img/logo.svg";
import rightAngle from "../../assets/img/right-angle.svg";
import leftAngle from "../../assets/img/left-angle.svg";
import { Button, Card, Image, Spin } from "antd";
import { currentYear, decrypt } from "../helper/common-function";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  actionAcceptInvitation,
  actionCheckAuthStatus,
  actionRejectInvitation,
} from "../../store/services/authService";
import { useDispatch, useSelector } from "react-redux";
import { CIPHER_KEY } from "../../config/web-config";

const AcceptInvitation = () => {
  const [searchParams] = useSearchParams();
  const secretKey = searchParams.get("secret_key");

  const {
    acceptInvitationLoader,
    checkAuthStatusLoader,
    checkAuthStatusError,
    rejectInvitationLoader,
  } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const descrytedData = decrypt(secretKey);
  const senderEmailNew = descrytedData;

  const [receiver, sender, id] = senderEmailNew.split(",");

  useEffect(() => {
    const CheckAuthStatus = async () => {
      await dispatch(
        actionCheckAuthStatus({
          token: secretKey,
          navigate,
          senderEmail: sender,
        })
      );
    };
    CheckAuthStatus();
  }, [secretKey, dispatch, navigate, sender]);

  const handleAcceptInvitation = () => {
    if (checkAuthStatusError == "USER_NOT_FOUND") {
      navigate &&
        navigate("/accept-invitation-create-account", {
          state: { user_token: secretKey, senderEmail: sender, receiver },
        });
    } else {
      dispatch(
        actionAcceptInvitation({
          request: secretKey,
          navigate,
          senderEmail: sender,
        })
      );
    }
  };

  const handleRejectInvitation = () => {
    dispatch(actionRejectInvitation({ request: secretKey }));
  };

  return (
    <Spin spinning={checkAuthStatusLoader}>
      <div className="register-container min-h-screen  relative flex flex-col justify-center  items-center pt-6 w-full h-full">
        <img
          src={rightAngle}
          className="absolute z-0 right-0 bottom-0 md:block hidden"
          alt="right-angle"
        />
        <img
          src={leftAngle}
          className="absolute z-0 left-0 top-0 md:block hidden"
          alt="left-angle"
        />

        <div>
          <div className="mx-auto text-center lg:pb-8 pb-4">
            <Image
              className="text-center"
              preview={false}
              height={50}
              src={logo}
              alt="Logo"
            />
          </div>
          <Card className="relative my-auto shadow 2xl:max-w-2xl md:max-w-[61%]  w-[95%] mx-auto border border-[#D1D1D1] rounded-[20px] ">
            <div className="mx-auto lg:w-[120px] lg:h-[120px] w-[100px] h-[100px] flex justify-center items-center text-5xl  bg-[#C08D0A] rounded-full text-white">
              {sender && sender.slice(0, 2).toUpperCase()}
            </div>
            <p className="lg:text-[15px] text-center sm:text-[15px] text-xs whitespace-normal leading-6 text-[#303030] mt-2">
              Invited by
            </p>
            <p className="lg:text-[27px] text-center font-semibold text-2xl text-gray-600 mt-1">
              {sender}
            </p>
            <div className="lg:mt-10 mt-5">
              <p className="lg:text-[20px] text-center font-semibold text-base my-2">
                Join Our Organization
              </p>
              <p className="lg:text-[16px] text-center text-[15px] lg:mx-8 mx-4 lg:my-3 my-2 text-[#303030]">
                We invite you to join our organization, Create a CRM account for
                the email address {receiver} to accept the invitation
              </p>
            </div>
            <div className="flex gap-4 items-center justify-center lg:w-[80%] w-[90%] mx-auto mt-4 lg:mt-8">
              <div className="w-[60%]">
                {" "}
                <Button
                  loading={acceptInvitationLoader}
                  type="primary"
                  className="filled-s-button  md:text-base text-sm  border-none rounded-[10px] min-h-[45px]"
                  block
                  htmlType="submit"
                  onClick={handleAcceptInvitation}
                >
                  {checkAuthStatusError == "USER_NOT_FOUND"
                    ? "Sign up & Accept"
                    : "Accept"}
                </Button>
              </div>
              <div className="w-[40%]">
                <Button
                  loading={rejectInvitationLoader}
                  onClick={handleRejectInvitation}
                  className="outline-button-border md:text-base text-sm  min-h-[45px]"
                  block
                >
                  Reject
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <footer className="lg:text-base  h-[30%] py-10 pb-5  text-center md:text-sm text-sm whitespace-nowrap  ">
          © {currentYear}, CRM Harbor Pvt. Ltd. All Rights Reserved.
        </footer>
      </div>
    </Spin>
  );
};

export default AcceptInvitation;
