import React from "react";
import { Button, Flex, Image } from "antd"; // Replace 'your-ui-library' with your actual library
import plusIcon from "../../assets/img/add-button.svg";
import { Link } from "react-router-dom";

const PageTitle = ({
  image,
  icon,
  title,
  buttonText,
  buttonLink,
  rightIcon = true,
  handleClick,
  type = "link",
}) => {
  return (
    <Flex gap="middle" justify="space-between" className="mb-4">
      <div className="text-brand flex items-center gap-x-3">
        {icon ? (
          icon
        ) : (
          <Image
            src={image}
            alt="image"
            height={20}
            width={20}
            preview={false}
          />
        )}
        <h1 className="lg:text-lg text-base  font-popinsRegular">{title}</h1>
      </div>

      {rightIcon &&
        (type === "btn" ? (
          <Button
            onClick={handleClick}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            {buttonText}
          </Button>
        ) : (
          <Link to={buttonLink}>
            <Button
              className="root-btn text-white hover:text-white py-5 flex-center radius"
              icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
            >
              {buttonText}
            </Button>
          </Link>
        ))}
    </Flex>
  );
};

export default PageTitle;
