import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";
import { handleGetResponse } from "./commonService";

export const actionGetBeatPlanning = createAsyncThunk(
  "actionGetBeatPlanning",
  async (
    { offset = 0, search = "", orderByColumn = "", order = "" } = {},
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/beat-planning?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionCreateBeatPlanning = createAsyncThunk(
  "actionCreateBeatPlanning",
  async ({ req, setIsModalOpen }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/beat-planning`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setIsModalOpen && setIsModalOpen(false);
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionUpdateBeatPlanning = createAsyncThunk(
  "actionUpdateBeatPlanning",
  async ({ id, req, setIsModalOpen }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.patch(`${BASE_URL}/beat-planning/${id}`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setIsModalOpen && setIsModalOpen();

        dispatch(actionGetBeatPlanning());
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetAllBeatPlanning = createAsyncThunk(
  "actionGetAllBeatPlanning",
  async () => {
    return handleGetResponse(`beat-planning?getall=YES`);
  }
);
export const actionGetBeatPlanningDetail = createAsyncThunk(
  "actionGetBeatPlanningDetail",
  async (id) => {
    return handleGetResponse(`beat-planning/${id}`);
  }
);

// Clear all Event Attendee Data
export const actionClearAllBeatPlanning = createAsyncThunk(
  "actionClearAllBeatPlanning",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

export const actionDeleteBeatPlanning = createAsyncThunk(
  "actionDeleteBeatPlanning",
  async ({ id, handleCloseDeleteModal }, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`${BASE_URL}/beat-planning/${id}`);
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseDeleteModal && handleCloseDeleteModal();
        return id;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
export const actionChangeBeatPlanningStatus = createAsyncThunk(
  "actionChangeBeatPlanningStatus",
  async ({ id, status: newStatus }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/beat-planning/status/${id}`, {
        status: newStatus,
      });
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        return { id, newStatus };
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
