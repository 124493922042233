import {
  HighlightOutlined,
  LoadingOutlined,
  UserOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Editor } from "@tinymce/tinymce-react";
import {
  Avatar,
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Image,
  Input,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import {
  FaChevronDown,
  FaChevronRight,
  FaCircleCheck,
  FaDownload,
} from "react-icons/fa6";
import { FiLink } from "react-icons/fi";
import { LuPlus } from "react-icons/lu";
import { MdDeleteOutline, MdOutlineFileDownload } from "react-icons/md";
import { toast } from "sonner";
import calendar from "../../assets/img/beat-calendar.svg";
import clock from "../../assets/img/clock.svg";
import expandIconGrey from "../../assets/img/expandIconGrey.svg";
import dowloadIcon from "../../assets/img/gray-download.svg";
import sortableIcon from "../../assets/img/sortableIcon.svg";
import { BASE_URL_UPLOAD } from "../../config/web-config";
import {
  extractFilename,
  extractFileType,
  getFullName,
  renderFileIcon,
} from "../helper/common-function";
import { actionGetCompanyAllUsers } from "../../store/services/usersService";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddTask,
  actionUpdateTask,
} from "../../store/services/taskService";
import { actionDownloadFile } from "../../store/services/eventService";
import { actionGetTaskStatusMaster } from "../../store/services/generalMasterService";
import axios from "axios";

const { Text } = Typography;

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateTask = React.memo(
  ({ updateId, setUpdateId, isModalOpen, setIsModalOpen }) => {
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(null);
    const [title, setTitle] = useState("");
    const [subtasks, setSubtasks] = useState([]);
    const [imageLoader, setImageLoader] = useState(false);
    const [description, setDescription] = useState("");
    const [isEditing, setIsEditing] = useState(undefined);
    const [selectedAvatars, setSelectedAvatars] = useState([]);
    const [isAddTask, setIsAddTask] = useState(false);
    const [priority, setPriority] = useState("Normal");
    const [dueDate, setDueDate] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [errors, setErrors] = useState({});
    const [isReminder, setIsReminder] = useState(false);
    const [reminderDate, setReminderDate] = useState("");
    const [reminderTime, setReminderTime] = useState("");
    const [attatchment, setAttatchment] = useState([]);

    const { getCompanyAllUserLoader, companyAllUsers } = useSelector(
      (state) => state.users
    );

    const { addTaskLoader, updateTaskLoader, getTaskDetail } = useSelector(
      (state) => state.tasks
    );
    const { taskStatusMasterData } = useSelector(
      (state) => state.generalMaster
    );

    const defaultStatus =
      taskStatusMasterData && taskStatusMasterData.length > 0
        ? taskStatusMasterData[0].task_status_id
        : null;

    const [taskStatus, setTaskStatus] = useState(defaultStatus);

    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    useEffect(() => {
      dispatch(actionGetCompanyAllUsers());
      dispatch(actionGetTaskStatusMaster());
      defaultStatus && setTaskStatus(defaultStatus);
    }, [dispatch]);

    const handleChangeInputValue = (e) => {
      setInputValue(e.target.value);
    };

    const handleChangeImage = (info) => {
      if (info.file.status === "uploading") {
        // setImageLoader(true);
        // return;
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, () => {
          setImageLoader(false);
          setAttatchment((prev) => [
            {
              file_name: extractFilename(info.file.response.url),
              url: info.file.response.url,
              size: info.file.response.size,
              upload_id: info.file.response.upload_id,
              file_type: extractFileType(info.file.response.url),
            },
            ...(Array.isArray(prev) ? prev : []),
          ]);
          toast.success("Uploaded Successfully", 5);
        });
      } else if (info.file.status === "error") {
        setImageLoader(false);
        toast.error(`${info.file.name} file upload failed.`);
      }
    };

    const [uploading, setUploading] = useState(false);

    const handleFileChange = async (e) => {
      const files = Array.from(e.target.files);
      if (files.length > 0) {
        await handleUpload(files);
      }
    };

    const handleUpload = async (files) => {
      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));

      try {
        setUploading(true);
        const response = await axios.post(
          `${BASE_URL_UPLOAD}/upload-multiple`,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Upload successful:", response.data);
        const newFiles = response.data.data;
        setAttatchment((prevFiles) => [
          ...prevFiles,
          ...newFiles.map((file) => ({
            ...file,
            file: files.find((f) => f.name === file.originalname),
          })),
        ]);
      } catch (error) {
        console.error("Upload failed:", error);
      } finally {
        setUploading(false);
      }
    };

    const handleBlur = () => setIsEditing(false);

    useEffect(() => {
      const {
        description,
        name,
        due_date,
        is_reminder,
        reminder_on_date,
        task_priority,
        task_status_master = {},
        reminder_on_time,
        task_assignee_user = [],
        sub_task = [],
        task_attechment = [],
      } = getTaskDetail;

      if (getTaskDetail && updateId) {
        setDescription(description);
        setTitle(name);
        setDueDate(due_date ? dayjs(due_date) : undefined);
        setIsReminder(is_reminder);
        setReminderDate(reminder_on_date ? dayjs(reminder_on_date) : undefined);
        setPriority(task_priority);
        setTaskStatus(
          task_status_master?.task_status_id
            ? task_status_master?.task_status_id
            : undefined
        );
        setReminderTime(
          reminder_on_time ? dayjs(reminder_on_time, "HH:mm") : undefined
        );
        setSelectedAvatars(
          task_assignee_user?.map((user) => user.assigned_comany_users.id) || []
        );

        setSubtasks(
          (sub_task &&
            sub_task.length > 0 &&
            sub_task?.map((sub) => ({
              key: sub.sub_task_id,
              id: sub.sub_task_id,
              sub_task_status: sub.sub_task_status,
              sub_task: sub.sub_task,
            }))) ||
            []
        );

        setAttatchment(
          task_attechment?.map((att) => ({
            key: att.upload_id,
            file_name: extractFilename(att?.upload?.url),
            url: att?.upload?.url,
            size: att?.upload?.size,
            upload_id: att?.upload?.upload_id,
            file_type: extractFileType(att?.upload?.url),
          })) || []
        );
      }
    }, [updateId, getTaskDetail]);

    const handleToggleComplete = (id) => {
      setSubtasks(
        subtasks &&
          subtasks.length > 0 &&
          subtasks.map((subtask) =>
            subtask.id === id
              ? {
                  ...subtask,
                  sub_task_status:
                    subtask.sub_task_status === "Pending"
                      ? "Completed"
                      : "Pending",
                }
              : subtask
          )
      );
    };

    const handleAddSubtask = () => {
      if (inputValue.trim() !== "") {
        const newTask = {
          id: `${subtasks && subtasks.length + 1}`,
          sub_task: inputValue.trim(),
          sub_task_status: "Pending",
        };
        setSubtasks([...subtasks, newTask]);
        setInputValue("");
        setIsAddTask(false);
        setIsEditing(undefined);
      }
    };

    const handleEditSubtask = (id, newText) => {
      setSubtasks((prev) =>
        prev.map((subtask) =>
          subtask.id === id ? { ...subtask, sub_task: newText } : subtask
        )
      );
      setIsEditing(undefined);
    };

    const handleTextChange = (subtask, newText) => {
      if (newText.trim() !== "") {
        handleEditSubtask(subtask.id, newText);
      }
    };

    const handleDeleteSubtask = (id) => {
      setSubtasks((prevSubtasks) =>
        prevSubtasks.filter((subtask) => subtask.id !== id)
      );
    };

    const getTaskPos = (ID) => subtasks.findIndex((task) => task.id === ID);

    function handleDragEnd(event) {
      const { active, over } = event;

      if (active.id !== over.id) {
        setSubtasks((items) => {
          const oldIndex = getTaskPos(active.id);
          const newIndex = getTaskPos(over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }

    const subTaskitems = [
      {
        key: "1",
        label: (
          <div className="">
            <p className="text-lg font-popinsBold pb-1">
              Subtask({subtasks && subtasks.length})
            </p>
          </div>
        ),
        children: (
          <>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={subtasks}
                strategy={verticalListSortingStrategy}
              >
                <div className="my-2  max-h-[200px] overflow-y-auto">
                  {subtasks &&
                    subtasks.length > 0 &&
                    subtasks.map((subtask) => (
                      <SortableItem
                        inputValue={inputValue}
                        key={subtask.id}
                        subtask={subtask}
                        id={subtask.id}
                        isHovered={isHovered}
                        isEditing={isEditing}
                        setIsHovered={setIsHovered}
                        handleToggleComplete={handleToggleComplete}
                        handleTextChange={handleTextChange}
                        handleEditSubtask={handleEditSubtask}
                        handleDeleteSubtask={handleDeleteSubtask}
                        setIsEditing={setIsEditing}
                      />
                    ))}
                </div>
              </SortableContext>
            </DndContext>
            <div>
              {isAddTask ? (
                <div className="flex gap-x-2 add-task-input">
                  <img src={sortableIcon} alt="icon" className="opacity-0" />
                  <Input
                    onPressEnter={handleAddSubtask}
                    value={inputValue}
                    onChange={handleChangeInputValue}
                    onBlur={handleBlur}
                    placeholder="Add Task"
                    className="w-[80%] px-3 mt-2 py-2 border !border-[#5B5A69] rounded-lg"
                    autoFocus
                  />
                </div>
              ) : (
                <div
                  className="flex gap-x-2 px-3 mt-2 h-[40px] w-[100%] items-center cursor-pointer"
                  onClick={() => setIsAddTask(!isAddTask)}
                >
                  <img src={sortableIcon} alt="icon" className="opacity-0" />
                  <LuPlus className="h-[20px] w-[20px]" color="#5B5A69" />
                  <p className="text-sm font-popinsRegular text-[#000]">
                    Add Subtask
                  </p>
                </div>
              )}
            </div>
          </>
        ),
      },
    ];

    const handleDeleteAttachment = (id) => {
      setAttatchment((prev) => prev.filter((p) => p.upload_id !== id));
    };

    const attatchmentitems = [
      {
        key: "1",
        label: (
          <div className="">
            <p className="text-lg font-popinsBold pb-1">
              Attachment({attatchment && attatchment.length})
            </p>
          </div>
        ),
        children: (
          <>
            <div className="my-2  max-h-[200px] overflow-y-auto overflow-x-hidden">
              {attatchment &&
                attatchment.length > 0 &&
                attatchment.map((att) => {
                  const sizeInBytes = att.size;
                  const sizeInKB = sizeInBytes / 1024;
                  const sizeInMB = sizeInBytes / (1024 * 1024);
                  const displaySize =
                    sizeInMB >= 1
                      ? `${sizeInMB.toFixed(2)} MB`
                      : `${sizeInKB.toFixed(2)} KB`;
                  return (
                    <div
                      key={att.upload_id}
                      className="py-3 px-4 border border-[#DDDAE9] my-2 radius flex items-center justify-between"
                    >
                      <div className="flex items-center file-show justify-center gap-x-4">
                        {renderFileIcon(att.url)}
                        <div className="flex flex-col gap-y-0">
                          <Typography.Paragraph
                            style={{ width: "85%" }}
                            ellipsis={{ rows: 1 }}
                            className="text-[14px] !mb-0"
                          >
                            {att.file_name}
                          </Typography.Paragraph>
                          <p className="text-[12px] text-muted">
                            {displaySize}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2 relative right-2">
                        <DownloadOutlined
                          color="#7C7C7C"
                          className="text-[#7C7C7C]"
                          onClick={() => dispatch(actionDownloadFile(att.url))}
                        />
                        <MdDeleteOutline
                          className="cursor-pointer"
                          size={18}
                          onClick={() => handleDeleteAttachment(att.upload_id)}
                          color="#ff4d4f"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>

            {/*    {imageLoader ? (
              <div className="flex gap-x-2 px-3 mt-2 h-[40px] w-[100%] items-center cursor-pointer">
                <LoadingOutlined />{" "}
                <p className="text-sm font-popinsRegular text-[#000]">
                  Uploading...
                </p>
              </div>
            ) : ( */}
            <>
              {/*    <Upload
                  onDownload={(info) =>
                    dispatch(actionDownloadFile(info.response.url))
                  }
                  multiple={true}
                  onRemove={(info) =>
                    handleDeleteAttachment(info.response.upload_id)
                  }
                  
                  id="att-upload"
                  accept=".png,.jpg,.jpeg,.pdf,.doc"
                  name="file"
                  listType="picture"
                  className="border-0  task-upload avatar-uploader"
                  showUploadList={false}
                 
                  openFileDialogOnClick={true}
                  headers={{
                    Authorization:
                      "Bearer " + localStorage.getItem("crmWebToken"),
                  }}
                >
                  <div className="flex gap-x-2 px-3 mt-2 h-[40px] w-[100%] items-center cursor-pointer">
                    <img src={sortableIcon} alt="icon" className="opacity-0" />
                    <FiLink className="h-[18px] w-[18px]" color="#5B5A69" />
                    <p className="text-sm font-popinsRegular text-[#000]">
                      Add Attachment
                    </p>
                  </div>
                </Upload> */}

              {uploading ? (
                <label className="flex gap-x-2 px-3 mt-2 h-[40px] w-[100%] items-center cursor-pointer">
                  <img src={sortableIcon} alt="icon" className="opacity-0" />
                  <LoadingOutlined height={18} width={18} color="#7C7C7C" />
                  <p className="text-sm font-popinsRegular text-[#000]">
                    Uploading...
                  </p>
                </label>
              ) : (
                <label
                  htmlFor="file-upload"
                  className="flex gap-x-2 px-3 mt-2 h-[40px] w-[100%] items-center cursor-pointer"
                >
                  <img src={sortableIcon} alt="icon" className="opacity-0" />
                  <FiLink className="h-[18px] w-[18px]" color="#5B5A69" />
                  <p className="text-sm font-popinsRegular text-[#000]">
                    Add Attachment
                  </p>
                </label>
              )}

              <input
                id="file-upload"
                type="file"
                multiple
                onChange={handleFileChange}
                className="hidden"
                accept=".jpeg,.jpg,.png,.gif,.pdf,.doc,.txt,.json,.yaml,.xml,.csv.xlsx,.doc,.svg,.log"
              />
            </>
          </>
        ),
      },
    ];

    const avatars = useMemo(() => {
      return (
        companyAllUsers &&
        companyAllUsers.length > 0 &&
        companyAllUsers.map((user) => ({
          label: user.profile_pic,
          value: user.id,
          name: getFullName(user.first_name, user.last_name),
        }))
      );
    }, [companyAllUsers]);

    const handleAvatarClick = (avatar) => {
      setSelectedAvatars((prevSelected) => {
        const selectedArray = Array.isArray(prevSelected) ? prevSelected : [];
        if (selectedArray.length > 0 && selectedArray.includes(avatar)) {
          return selectedArray.filter((i) => i !== avatar);
        } else {
          return [...selectedArray, avatar];
        }
      });
    };

    const checkValidation = () => {
      let errors = {};
      let isFormValid = true;

      if (title.trim() === "" || !title) {
        errors.name = "Please enter title!";
        isFormValid = false;
      }

      if (isReminder) {
        if (!reminderDate || !reminderTime) {
          errors.reminderVal = "Please Select Reminder date and time!";
          isFormValid = false;
        }
      }
      setErrors(errors);
      return isFormValid;
    };

    const resetForm = () => {
      setIsModalOpen(false);
      setUpdateId("");
      setInputValue("");
      setSubtasks([]);
      setErrors({});
      setTitle("");
      setDescription("");
      setIsEditing("");
      setIsAddTask(false);
      setPriority("Normal");
      setDueDate("");
      setIsReminder(false);
      setReminderDate("");
      setReminderTime("");
      setTaskStatus(defaultStatus);
    };

    useEffect(() => {
      if (!isModalOpen) {
        resetForm();
      }
    }, [isModalOpen]);

    const handleSubmit = () => {
      if (checkValidation()) {
        const req = {
          name: title,
          description,
          sub_tasks: subtasks,
          task_assignee_user: selectedAvatars,
          task_status_id: taskStatus ? taskStatus : null,
          task_priority: priority ? priority : null,
          task_attechment:
            attatchment && attatchment.length > 0
              ? attatchment.map((att) => att.upload_id)
              : [],
          is_reminder: isReminder,
          reminder_on_date: reminderDate
            ? dayjs(reminderDate).format("YYYY-MM-DD")
            : null,
          due_date: dueDate ? dayjs(dueDate).format("YYYY-MM-DD HH:mm") : null,
          reminder_on_time: reminderTime
            ? dayjs(reminderTime).format("HH:mm")
            : null,
        };
        updateId
          ? dispatch(
              actionUpdateTask({
                id: updateId,
                req,
                setUpdateId,
                setIsModalOpen,
              })
            )
          : dispatch(actionAddTask({ req, setIsModalOpen }));
      }
    };

    const disabledTime = () => {
      if (reminderDate && dueDate && reminderDate.isSame(dueDate, "day")) {
        const dueHour = dayjs(dueDate).hour();
        const dueMinute = dayjs(dueDate).minute();

        return {
          disabledHours: () => {
            // Disable hours after the due hour
            return Array.from({ length: 24 }, (_, i) =>
              i > dueHour ? i : -1
            ).filter((i) => i !== -1);
          },
          disabledMinutes: (selectedHour) => {
            if (selectedHour > dueHour) {
              return Array.from({ length: 60 }, (_, i) => i);
            } else if (selectedHour === dueHour) {
              return Array.from({ length: 60 }, (_, i) =>
                i > dueMinute ? i : -1
              ).filter((i) => i !== -1);
            }
            return [];
          },
        };
      }
      return {};
    };

    const statusData = useMemo(() => {
      return (
        taskStatusMasterData &&
        taskStatusMasterData.length > 0 &&
        taskStatusMasterData.map((task) => ({
          label: task.status,
          value: task.task_status_id,
        }))
      );
    }, [taskStatusMasterData]);

    return (
      <div className="max-h-screen overflow-auto no-scrollbar">
        <Row>
          <Col span={15} className="py-5">
            <div className="pr-10">
              <p className="text-xl font-popinsBold pb-4">Task Information</p>
              <div className="mb-4 mt-2">
                <label className="pb-4 tracking-wide font-popinsMedium">
                  Title <span style={{ color: "red" }}>*</span>
                </label>

                <Input
                  className="mt-2"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />

                {errors.name && (
                  <p className="text-red-500 tracking-wide py-2 pl-2">
                    {errors.name}
                  </p>
                )}
              </div>
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                initialValue={updateId && getTaskDetail?.description}
                init={{
                  selector: "textarea",
                  deprecation_warnings: false,
                  placeholder: "Enter Description",
                  height: 200,
                  plugins: "link lists",
                  toolbar:
                    "bold italic underline strikethrough | link | bullist numlist",
                  tinycomments_mode: "embedded",
                  tinycomments_author: "CRM HARBOR",
                  branding: false,
                  menubar: false,
                }}
                onEditorChange={(newContent) => setDescription(newContent)}
              />

              <div className="flex items-center gap-x-5 py-3 justify-end">
                <Button
                  onClick={resetForm}
                  type="button"
                  className="gray-button text-white hover:text-white min-h-[40px] min-w-[120px]"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  loading={addTaskLoader || updateTaskLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
              <div className="w-4/5 begin-scrollbar">
                <div className="mt-4">
                  <Collapse
                    className="custom-collapse"
                    items={subTaskitems}
                    ghost
                    defaultActiveKey={["1"]}
                    expandIcon={({ isActive }) =>
                      isActive ? <FaChevronDown /> : <FaChevronRight />
                    }
                  />
                </div>
                <div className="mt-4">
                  <Collapse
                    className="custom-collapse"
                    items={attatchmentitems}
                    ghost
                    defaultActiveKey={["1"]}
                    expandIcon={({ isActive }) =>
                      isActive ? <FaChevronDown /> : <FaChevronRight />
                    }
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col
            span={9}
            className="bg-[#F8F9FA]  overflow-x-hidden rounded-r rounded-[6px] py-5"
          >
            {updateId && (
              <>
                <Space direction="vertical" className="px-4">
                  <p className="text-muted text-base font-popinsMedium">
                    Created By
                  </p>

                  <div className="flex items-center gap-x-2">
                    <Avatar size="large" icon={<UserOutlined />} />
                    <p className="text-base font-popinsSemiBold">
                      {getTaskDetail?.created_by?.full_name}
                    </p>
                  </div>
                </Space>
                <Divider />
              </>
            )}
            <Space
              style={{ width: "100%" }}
              direction="vertical"
              className="px-4"
            >
              <p className="text-muted text-base font-popinsMedium">
                Assign to
              </p>

              <div className="flex items-center gap-x-2">
                {getCompanyAllUserLoader ? (
                  "Loading..."
                ) : avatars && avatars.length > 0 && avatars.length <= 15 ? (
                  <Avatar.Group
                    max={{
                      count: 6,
                    }}
                  >
                    {avatars.map((avatar) => (
                      <Tooltip key={avatar.value} title={avatar.name}>
                        <Avatar
                          key={avatar.value}
                          style={{
                            borderWidth: 2,
                            borderColor:
                              selectedAvatars &&
                              selectedAvatars.includes(avatar.value)
                                ? "#4567FF"
                                : "#ffffff",
                          }}
                          src={avatar.label}
                          size={50}
                          onClick={() => handleAvatarClick(avatar.value)}
                        />
                      </Tooltip>
                    ))}
                  </Avatar.Group>
                ) : (
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select Users"
                    value={selectedAvatars}
                    maxTagTextLength={10}
                    maxTagCount={3}
                    onChange={(values) => setSelectedAvatars(values)}
                    options={
                      companyAllUsers &&
                      companyAllUsers.length > 0 &&
                      companyAllUsers.map((user) => ({
                        label: (
                          <span>
                            <Avatar
                              src={user.profile_pic}
                              style={{
                                verticalAlign: "middle",
                              }}
                              size={"small"}
                              className="mr-2"
                            />

                            {getFullName(user.first_name, user.last_name)}
                          </span>
                        ),
                        value: user.id,
                      }))
                    }
                  />
                )}
              </div>
            </Space>
            <Divider />
            <Space direction="vertical" className="px-4">
              <p className="text-muted text-base font-popinsMedium">Due Date</p>
              <Space>
                <Image src={calendar} preview={false} alt="calendar" />
                <DatePicker
                  showTime
                  minDate={dayjs().add(1, "day")}
                  value={dueDate}
                  placeholder="Select Date and Time"
                  onChange={(val) => setDueDate(val)}
                  format="MMM D, YYYY, HH:mm"
                  suffixIcon={null}
                  variant="borderless"
                />
              </Space>
            </Space>
            <Divider />
            <div className="flex md:flex-row flex-col items-center px-4 justify-between">
              <div className="flex flex-1 items-center  w-full">
                <p className="text-muted text-base font-popinsMedium">
                  Priority
                </p>
                <Select
                  placeholder="priority"
                  value={priority}
                  style={{ width: 100 }}
                  defaultValue={"Normal"}
                  onChange={(val) => {
                    setPriority(val);
                  }}
                  options={[
                    {
                      label: "Low",
                      value: "Low",
                    },
                    {
                      label: "Normal",
                      value: "Normal",
                    },
                    {
                      label: "High",
                      value: "High",
                    },
                    {
                      label: "Highest",
                      value: "Highest",
                    },
                    {
                      label: "Lowest",
                      value: "Lowest",
                    },
                  ]}
                  variant="borderless"
                />
              </div>
              <div className="flex flex-1 items-center  w-full">
                <p className="text-muted text-base font-popinsMedium">Status</p>
                <Select
                  className="status-select"
                  labelInValue
                  placeholder="Status"
                  value={taskStatus}
                  style={{ flex: 1, minWidth: 0, width: 120 }}
                  onChange={(val) => {
                    setTaskStatus(val);
                  }}
                  options={statusData}
                  variant="borderless"
                />
              </div>
            </div>

            <Divider />
            <div className="flex items-start  gap-y-2 px-4 flex-col w-full">
              <div className="flex items-center gap-x-4">
                <p className="text-muted text-base font-popinsMedium">
                  Reminder
                </p>
                <Switch
                  checked={isReminder}
                  onChange={(checked) => {
                    setIsReminder(checked);
                    setReminderDate(null);
                    setReminderTime(null);
                    setErrors({ ...errors, reminderVal: "" });
                  }}
                />
              </div>

              {isReminder && (
                <div className="flex items-center gap-x-1">
                  <div className="flex items-center gap-x-2">
                    <p>On</p>{" "}
                    <Image preview={false} src={calendar} alt="calendar" />
                    <DatePicker
                      className="w-3/5"
                      value={reminderDate}
                      maxDate={dayjs(dueDate)}
                      onChange={(val) => setReminderDate(val)}
                      format="MMM D, YYYY,"
                      suffixIcon={null}
                      variant="borderless"
                    />
                  </div>
                  <div className="flex items-center gap-x-2">
                    <p>At</p> <Image preview={false} src={clock} alt="clock" />
                    <TimePicker
                      disabledTime={disabledTime}
                      showSecond={false}
                      value={reminderTime}
                      onChange={(val) => setReminderTime(val)}
                      suffixIcon={null}
                      format={"HH:mm"}
                      variant="borderless"
                    />
                  </div>
                </div>
              )}
              {errors.reminderVal && (
                <p className="text-red-500 tracking-wide py-2 pl-2">
                  {errors.reminderVal}
                </p>
              )}
            </div>
            <Divider />
            {updateId && (
              <>
                <div className="flex items-start justify-between px-4  w-full">
                  <div className="flex flex-col  gap-y-2">
                    <p className="font-popinsSemiBold text-base">Created</p>
                    <p className="text-muted text-base">
                      {dayjs(getTaskDetail?.created_at).format(
                        "MMM D, YYYY h:mm A"
                      )}
                    </p>
                  </div>
                  <div className="flexflex-col  gap-y-2">
                    <p className="font-popinsSemiBold text-base">Updated</p>
                    <p className="text-muted text-base">
                      {dayjs(getTaskDetail?.updated_at).format(
                        "MMM D, YYYY h:mm A"
                      )}
                    </p>
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
);

export default CreateTask;

function SortableItem({
  id,
  isEditing,
  subtask,
  isHovered,
  setIsHovered,
  handleToggleComplete,
  handleTextChange,
  handleEditSubtask,
  setIsEditing,
  inputValue,
  handleDeleteSubtask,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: transform
      ? CSS.Transform.toString({ ...transform, x: 0 })
      : undefined,
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <div
        className={`hover:bg-[#F4F6F8]  h-[40px] w-[100%] flex items-center justify-between hover:rounded-lg px-3 ${
          isHovered === subtask.id ? "flex items-center justify-between" : ""
        }`}
        onMouseEnter={() => setIsHovered(id)}
        onMouseLeave={() => setIsHovered(null)}
      >
        <div className="flex gap-x-2 items-center w-3/4">
          <div {...listeners}>
            <img
              src={sortableIcon}
              alt="icon"
              className={`${isHovered ? "opacity-100" : "opacity-0"}`}
            />
          </div>
          <FaCircleCheck
            className="h-[20px] w-[20px] cursor-pointer"
            color={
              subtask.sub_task_status === "Completed" ? "#008000" : "#C0C0C0"
            }
            onClick={() => handleToggleComplete(id)}
          />
          <Text
            editable={{
              triggerType: ["icon", "text"],
              editing: isEditing === subtask.id,
              icon: <HighlightOutlined className="hidden" />,
              onChange: (text) => handleTextChange(subtask, text),
              onEnd: (text) => {
                inputValue?.trim() !== "" && handleEditSubtask(id, text);
              },
            }}
            className={`text-sm w-4/5 edit-sub-task !min-h-0 ml-2 !mt-0 font-popinsRegular text-[#5B5A69] ${
              subtask.sub_task_status === "Completed" ? "line-through" : ""
            }`}
          >
            {subtask.sub_task}
          </Text>
        </div>
        <div
          className={`${
            isHovered === subtask.id ? "block" : "hidden"
          } flex gap-x-2`}
        >
          {!isEditing && (
            <img
              onClick={() => setIsEditing(subtask.id)}
              src={expandIconGrey}
              alt="icon"
              className="cursor-pointer"
            />
          )}
          <MdDeleteOutline
            size={18}
            onClick={() => handleDeleteSubtask(id)}
            color="#7C7C7C"
          />
        </div>
      </div>
    </div>
  );
}
