import { Image, Table, Tag } from "antd";
import React, { useState } from "react";
import sort from "../../assets/img/sort.svg";
import sales from "../../assets/img/sales.svg";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";

const MySalesOrder = ({}) => {
  const initialColumns = [
    {
      title: "Action",
      key: "1",
      dataIndex: "action",
      sorter: (a, b) => a.action - b.action,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Print Status",
      dataIndex: "status",
      key: "2",
      sorter: (a, b) => a.status - b.status,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Invoice No.",
      key: "3",
      dataIndex: "invoice_num",
      sorter: (a, b) => a.invoice_num - b.invoice_num,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Erp Invoice No.",
      dataIndex: "erp_invoice_num",
      key: "4",
      sorter: (a, b) => a.erp_invoice_num - b.erp_invoice_num,
      sortIcon: () => <SortIcon />,
      hidden: true,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "5",
      sorter: (a, b) => a.invoice_date - b.invoice_date,
      sortIcon: () => <SortIcon />,
      render: (_, { invoice_date }) => (
        <Tag
          className="border font-popins border-pending text-pending px-3 py-[5px] text-xs"
          key={invoice_date}
        >
          {invoice_date}
        </Tag>
      ),
    },
    {
      title: "Customer Name",
      key: "customer_name",
      key: "6",
      sortIcon: () => <SortIcon />,
      dataIndex: "customer_name",
      sorter: (a, b) => a.customer_name - b.customer_name,
    },
  ];

  const [columns, setColumns] = useState(initialColumns);
  const defaultCheckedList = columns.map((item) => item.key);

  
  const [search, setSearch] = useState("");
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleFilter = () => {
    console.log("Filter button clicked!");
  };

  const handleSearch = (e) => {
    console.log("Search button clicked!");
    setSearch(e.target.value);
  };

  console.log("Search : ", search);

  const handleRefresh = () => {
    console.log("Refresh button clicked!");
  };

  const handleDownload = () => {
    console.log("Download button clicked!");
  };

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  const data = [];
  for (let i = 1; i <= 100; i++) {
    data.push({
      key: i,
      action: "0012468521",
      status: "15 Jul 2010",
      invoice_num: `SO-112121212`,
      erp_invoice_num: "Pending for ASM Approval",
      invoice_date: "Pending",
      customer_name: "Test SuperStockist 2",
    });
  }

  return (
    <section className="main-section">
     {/*  <PageTitle
        image={sales}
        title="Sales Orders"
        buttonText="Raise new SO"
        buttonLink="/"
      />

      <CustomInputWithButtons
        setColumns={setColumns}
        handleFilter={handleFilter}
        handleRefresh={handleRefresh}
        handleDownload={handleDownload}
        columns={columns}
        setCheckedList={setCheckedList}
        checkedList={checkedList}
        handleSearch={handleSearch}
      />

      <Table
        bordered
        rowSelection={true}
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={{
          showSizeChanger: false,
          responsive: true,
        }}
        columns={newColumns}
        dataSource={checkedList.length <= 0 ? [] : data}
      /> */}
      <h2>Sales Orders</h2>
    </section>
  );
};

export default MySalesOrder;
