// CommonPhoneInput.js
import React, { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import { isPhoneValid } from "../helper/common-function";

const PhoneInputComponent = ({
  setIsValid,
  isValid,
  value,
  setValue,
  onChange,
}) => {
  const [errors, setErrors] = useState("Please Enter Mobile no!");

  useEffect(() => {
    setIsValid(true);
  }, []);

  const handlePhoneChange = (val, data) => {
    let countryCode = "";
    let phoneNumber = "";
    let error = "";
    let formValid = true;

    if (data && data.inputValue) {
      const match = data.inputValue.match(/^\+\d+/);
      countryCode = match ? match[0] : "";
      phoneNumber = data.inputValue.slice(countryCode.length).trimStart();
    }

    if (!val) {
      error = "Please Enter Mobile no!";
      formValid = false;
    } else if (val && val.length < 4) {
      error = "Please Enter Mobile no!";
      formValid = false;
    } else if (value && value.phone && value.phone.length > 15) {
      error = "Phone number must be 15 digits or less!";
      formValid = false;
    }

    setValue((prevState) => ({
      ...prevState,
      fullPhoneNumber: val,
      phone: phoneNumber,
      iso: data?.country.iso2.toUpperCase(),
    }));

    setErrors(error);
    onChange(formValid);
  };

  return (
    <>
      <PhoneInput
        forceDialCode={true}
        // disableDialCodeAndPrefix={true}
        disableFormatting={true}
        placeholder="Enter Phone Number"
        defaultCountry="in"
        className={`w-full  ${!isValid ? "custom-err-border" : "mb-3.5"}`}
        value={value}
        onChange={handlePhoneChange}
      />
      {
        <div className={`flex flex-nowrap ${!isValid ? "block " : "hidden"}`}>
          <div
            id="normal_login_password_help"
            className="ant-form-item-explain text-[14px] ant-form-item-explain-connected css-dev-only-do-not-override-g9yw9c"
            role="alert"
          >
            <div className="ant-form-item-explain-error text-error">
              {errors}
            </div>
          </div>
          {/* <div className="w-0 h-[24px]"></div> */}
        </div>
      }
    </>
  );
};

export default PhoneInputComponent;
