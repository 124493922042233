import React from "react";
import { Popover, Image } from "antd";
import plusSvg from "../../assets/img/plus.svg";

const AddPopover = ({
  placement = "bottomRight",
  content,
  trigger = "click",
  isOpen,
  onOpenChange,
  showModal,
  text = "Add New",
}) => {
  return (
    <Popover
      placement={placement}
      content={content}
      trigger={trigger}
      open={isOpen}
      onOpenChange={(visible) => {
        if (!visible) {
          onOpenChange(false);
        }
      }}
    >
      <div
        className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
        onClick={showModal}
      >
        <Image
          src={plusSvg}
          alt={"Add Icon"}
          height={20}
          width={20}
          preview={false}
        />
        <h1 className="text-[14px] font-popinsRegular ml-1">{text}</h1>
      </div>
    </Popover>
  );
};

export default AddPopover;
