import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetCities,
  actionGetCountries,
  actionGetStates,
} from "../services/locationService";
import {
  actionAddEventCategoryMaster,
  actionAddEventTypeMaster,
  actionDeleteEventCategoryMaster,
  actionDeleteEventTypeMaster,
  actionEditEventCategoryMaster,
  actionEditEventTypeMaster,
  actionGetEventCategoryMaster,
  actionGetEventTypeMaster,
  actionGetSingleEventCategoryMaster,
  actionGetSingleEventTypeMaster,
  actionGetEventOnlinePlatformMaster,
  actionGetSingleEventPlatformMaster,
  actionAddEventOnlinePlatformMaster,
  actionDeleteEventOnlinePlatformMaster,
  actionEditEventOnlinePlatformMaster,
  actionGetAttendeeStatusMaster,
  actionGetSingleAttendeeStatusMaster,
  actionAddAttendeeStatusMaster,
  actionDeleteAttendeeStatusMaster,
  actionEditAttendeeStatusMaster,
  actionGetAttendeeTypeMaster,
  actionGetSingleAttendeeTypeMaster,
  actionAddAttendeeTypeMaster,
  actionDeleteAttendeeTypeMaster,
  actionEditAttendeeTypeMaster,
  actionGetExhibitionCategoryMaster,
  actionGetSingleExhibitionCategoryMaster,
  actionAddExhibitionCategoryMaster,
  actionDeleteExhibitionCategoryMaster,
  actionEditExhibitionCategoryMaster,
  actionGetEventProductMaster,
  actionGetSingleEventProductMaster,
  actionAddEventProductMaster,
  actionDeleteEventProductMaster,
  actionEditEventProductMaster,
  actionGetBusinessPartner,
  actionAddBusinessPartner,
  actionGetAllBusinessPartner,
  actionDeleteBusinessPartner,
  actionGetSingleBusinessPartner,
  actionUpdateBusinessPartner,
} from "../services/masterDataService";

const masterDataSlice = createSlice({
  name: "location",
  initialState: {
    getEventTypeLoader: false,
    addEventTypeLoader: false,
    getSingleEventTypeLoader: false,
    getSingleEventTypeData: "",
    deleteEventTypeLoader: {},
    updateEventTypeLoader: {},
    eventTypeData: [],

    // Event Category
    eventCategoryData: [],
    getEventCategoryLoader: false,
    getSingleEventCategoryLoader: false,
    addEventCategoryLoader: false,
    deleteEventCategoryLoader: {},
    updateEventCategoryLoader: {},
    getSingleEventCategoryData: "",

    //Event Online platform
    eventOnlinePlatformData: [],
    getEventOnlinePlatformLoader: false,
    getSingleEventOnlinePlatformLoader: false,
    addEventOnlinePlatformLoader: false,
    deleteEventOnlinePlatformLoader: {},
    updateEventOnlinePlatformLoader: {},
    getSingleEventOnlinePlatformData: "",

    //Attendee Status
    attendeeStatusData: [],
    getAttendeeStatusLoader: false,
    getSingleAttendeeStatusLoader: false,
    addAttendeeStatusLoader: false,
    deleteAttendeeStatusLoader: {},
    updateAttendeeStatusLoader: {},
    getSingleAttendeeStatus: "",

    //Attendee Type
    attendeeTypeData: [],
    getAttendeeTypeLoader: false,
    getSingleAttendeeTypeLoader: false,
    addAttendeeTypeLoader: false,
    deleteAttendeeTypeLoader: {},
    updateAttendeeTypeLoader: {},
    getSingleAttendeeType: "",

    //exhibition category
    exhibitionCategoryData: [],
    getExhibitionCategoryLoader: false,
    getSingleExhibitionCategoryLoader: false,
    addExhibitionCategoryLoader: false,
    deleteExhibitionCategoryLoader: {},
    updateExhibitionCategoryLoader: {},
    getSingleExhibitionCategory: "",

    //Event product
    eventProductData: [],
    getEventProductLoader: false,
    getSingleEventProductLoader: false,
    addEventProductLoader: false,
    deleteEventProductLoader: {},
    updateEventProductLoader: {},
    getSingleEventProduct: "",

    getBusinessPartnerLoader: false,
    getBusinessPartnerDetailLoader: false,
    getAllBusinessPartnerLoader: false,
    deleteBusinessPartnerLoader: false,
    addBusinessPartnerLoader: false,
    updateBusinessPartnerLoader: false,
    businessPartnerDetail: "",
    businessPartnersCount: 0,
    businessPartnerData: [],
    allBusinessPartnerData: [],
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // get event types State
      .addCase(actionGetEventTypeMaster.pending, (state) => {
        state.getEventTypeLoader = true;
      })
      .addCase(actionGetEventTypeMaster.fulfilled, (state, action) => {
        state.eventTypeData = action.payload;
        state.getEventTypeLoader = false;
      })
      .addCase(actionGetEventTypeMaster.rejected, (state) => {
        state.getEventTypeLoader = false;
      })

      // get single event types State
      .addCase(actionGetSingleEventTypeMaster.pending, (state) => {
        state.getSingleEventTypeLoader = true;
      })
      .addCase(actionGetSingleEventTypeMaster.fulfilled, (state, action) => {
        state.getSingleEventTypeData = action.payload.data;
        state.getSingleEventTypeLoader = false;
      })
      .addCase(actionGetSingleEventTypeMaster.rejected, (state) => {
        state.getSingleEventTypeLoader = false;
      })

      // add event types State
      .addCase(actionAddEventTypeMaster.pending, (state) => {
        state.addEventTypeLoader = true;
      })
      .addCase(actionAddEventTypeMaster.fulfilled, (state, action) => {
        // state.eventTypeData = [...action.payload, ...state.eventTypeData];
        state.addEventTypeLoader = false;
        if (action.payload) {
          if (!state.eventTypeData || !Array.isArray(state.eventTypeData)) {
            state.eventTypeData = [action.payload];
          } else {
            state.eventTypeData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddEventTypeMaster.rejected, (state) => {
        state.addEventTypeLoader = false;
      })

      // delete event types State
      .addCase(actionDeleteEventTypeMaster.pending, (state, action) => {
        state.deleteEventTypeLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteEventTypeMaster.fulfilled, (state, action) => {
        state.eventTypeData = state.eventTypeData.filter(
          (type) => type.event_type_master_id !== action.payload
        );
        state.deleteEventTypeLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteEventTypeMaster.rejected, (state, action) => {
        state.deleteEventTypeLoader[action.meta.arg.id] = false;
      })

      // Update event types State
      .addCase(actionEditEventTypeMaster.pending, (state, action) => {
        state.updateEventTypeLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditEventTypeMaster.fulfilled, (state, action) => {
        state.updateEventTypeLoader[action.meta.arg.id] = false;
        state.eventTypeData = state.eventTypeData.map((type) =>
          type.event_type_master_id === action.payload.event_type_master_id
            ? {
                ...type,
                event_type: action.payload.event_type,
                description: action.payload.description,
              }
            : type
        );
      })
      .addCase(actionEditEventTypeMaster.rejected, (state, action) => {
        state.updateEventTypeLoader[action.meta.arg.id] = false;
      })

      //EVENT CATEGORY
      // Event Category States
      .addCase(actionGetEventCategoryMaster.pending, (state) => {
        state.getEventCategoryLoader = true;
      })
      .addCase(actionGetEventCategoryMaster.fulfilled, (state, action) => {
        state.eventCategoryData = action.payload.data;
        state.getEventCategoryLoader = false;
      })
      .addCase(actionGetEventCategoryMaster.rejected, (state) => {
        state.getEventCategoryLoader = false;
      })

      // get single event category State
      .addCase(actionGetSingleEventCategoryMaster.pending, (state) => {
        state.getSingleEventCategoryLoader = true;
      })
      .addCase(
        actionGetSingleEventCategoryMaster.fulfilled,
        (state, action) => {
          state.getSingleEventCategoryData = action.payload.data;
          state.getSingleEventCategoryLoader = false;
        }
      )
      .addCase(actionGetSingleEventCategoryMaster.rejected, (state) => {
        state.getSingleEventCategoryLoader = false;
      })

      // add event category State
      .addCase(actionAddEventCategoryMaster.pending, (state) => {
        state.addEventCategoryLoader = true;
      })
      .addCase(actionAddEventCategoryMaster.fulfilled, (state, action) => {
        // state.eventCategoryData = [action.payload, ...state.eventCategoryData]
        state.addEventCategoryLoader = false;
        if (action.payload) {
          if (!state.eventCategoryData || !Array.isArray(state.eventCategoryData)) {
            state.eventCategoryData = [action.payload];
          } else {
            state.eventCategoryData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddEventCategoryMaster.rejected, (state) => {
        state.addEventCategoryLoader = false;
      })

      // delete event category State
      .addCase(actionDeleteEventCategoryMaster.pending, (state, action) => {
        state.deleteEventCategoryLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteEventCategoryMaster.fulfilled, (state, action) => {
        state.eventCategoryData = state.eventCategoryData.filter(
          (category) => category.event_category_master_id !== action.payload
        );
        state.deleteEventCategoryLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteEventCategoryMaster.rejected, (state, action) => {
        state.deleteEventCategoryLoader[action.meta.arg.id] = false;
      })

      //update category
      .addCase(actionEditEventCategoryMaster.pending, (state, action) => {
        state.updateEventCategoryLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditEventCategoryMaster.fulfilled, (state, action) => {
        state.updateEventCategoryLoader[action.meta.arg.id] = false;
        state.eventCategoryData = state.eventCategoryData.map((category) =>
          category.event_category_master_id ===
          action.payload.event_category_master_id
            ? {
                ...category,
                event_category: action.payload.event_category,
                category_description: action.payload.category_description,
              }
            : category
        );
      })
      .addCase(actionEditEventCategoryMaster.rejected, (state, action) => {
        state.updateEventCategoryLoader[action.meta.arg.id] = false;
      })

      //EVENT ONLINE PLATFORM
      // get event online platform State
      .addCase(actionGetEventOnlinePlatformMaster.pending, (state) => {
        state.getEventOnlinePlatformLoader = true;
      })
      .addCase(
        actionGetEventOnlinePlatformMaster.fulfilled,
        (state, action) => {
          state.eventOnlinePlatformData = action.payload.data;
          state.getEventOnlinePlatformLoader = false;
        }
      )
      .addCase(actionGetEventOnlinePlatformMaster.rejected, (state) => {
        state.getEventOnlinePlatformLoader = false;
      })

      // get single event online platform State
      .addCase(actionGetSingleEventPlatformMaster.pending, (state) => {
        state.getSingleEventOnlinePlatformLoader = true;
      })
      .addCase(
        actionGetSingleEventPlatformMaster.fulfilled,
        (state, action) => {
          state.getSingleEventOnlinePlatformData = action.payload.data;
          state.getSingleEventOnlinePlatformLoader = false;
        }
      )
      .addCase(actionGetSingleEventPlatformMaster.rejected, (state) => {
        state.getSingleEventOnlinePlatformLoader = false;
      })

      // add event onlineplateform
      .addCase(actionAddEventOnlinePlatformMaster.pending, (state) => {
        state.addEventOnlinePlatformLoader = true;
      })
      .addCase(
        actionAddEventOnlinePlatformMaster.fulfilled,
        (state, action) => {
          state.addEventOnlinePlatformLoader = false;
          if (action.payload) {
            if (!state.eventOnlinePlatformData || !Array.isArray(state.eventOnlinePlatformData)) {
              state.eventOnlinePlatformData = [action.payload];
            } else {
              state.eventOnlinePlatformData.unshift(action.payload);
            }
          }
        }
      )
      .addCase(actionAddEventOnlinePlatformMaster.rejected, (state) => {
        state.addEventOnlinePlatformLoader = false;
      })

      // delete event online platform
      .addCase(
        actionDeleteEventOnlinePlatformMaster.pending,
        (state, action) => {
          state.deleteEventOnlinePlatformLoader[action.meta.arg.id] = true;
        }
      )
      .addCase(
        actionDeleteEventOnlinePlatformMaster.fulfilled,
        (state, action) => {
          state.eventOnlinePlatformData = state.eventOnlinePlatformData.filter(
            (type) => type.event_online_platform_master_id !== action.payload
          );
          state.deleteEventOnlinePlatformLoader[action.meta.arg.id] = false;
        }
      )
      .addCase(
        actionDeleteEventOnlinePlatformMaster.rejected,
        (state, action) => {
          state.deleteEventOnlinePlatformLoader[action.meta.arg.id] = false;
        }
      )

      //update onlineplatform
      .addCase(actionEditEventOnlinePlatformMaster.pending, (state, action) => {
        state.updateEventOnlinePlatformLoader[action.meta.arg.id] = true;
      })
      .addCase(
        actionEditEventOnlinePlatformMaster.fulfilled,
        (state, action) => {
          const { id } = action.meta.arg;
          const { platform_name, event_online_platform_master_id } =
            action.payload;

          state.updateEventOnlinePlatformLoader[id] = false;
          state.eventOnlinePlatformData = state.eventOnlinePlatformData.map(
            (onlinePlatform) =>
              onlinePlatform.event_online_platform_master_id ===
              event_online_platform_master_id
                ? { ...onlinePlatform, platform_name }
                : onlinePlatform
          );
        }
      )
      .addCase(
        actionEditEventOnlinePlatformMaster.rejected,
        (state, action) => {
          state.updateEventOnlinePlatformLoader[action.meta.arg.id] = false;
        }
      )

      //ATTENDEE STATUS
      // get Attendee status
      .addCase(actionGetAttendeeStatusMaster.pending, (state) => {
        state.getAttendeeStatusLoader = true;
      })
      .addCase(actionGetAttendeeStatusMaster.fulfilled, (state, action) => {
        state.attendeeStatusData = action.payload;
        state.getAttendeeStatusLoader = false;
      })
      .addCase(actionGetAttendeeStatusMaster.rejected, (state) => {
        state.getAttendeeStatusLoader = false;
      })

      // get single attendee status
      .addCase(actionGetSingleAttendeeStatusMaster.pending, (state) => {
        state.getSingleAttendeeStatusLoader = true;
      })
      .addCase(
        actionGetSingleAttendeeStatusMaster.fulfilled,
        (state, action) => {
          state.getSingleAttendeeStatus = action.payload.data;
          state.getSingleAttendeeStatusLoader = false;
        }
      )
      .addCase(actionGetSingleAttendeeStatusMaster.rejected, (state) => {
        state.getSingleAttendeeStatusLoader = false;
      })

      // add Attendee status
      .addCase(actionAddAttendeeStatusMaster.pending, (state) => {
        state.addAttendeeStatusLoader = true;
      })
      .addCase(actionAddAttendeeStatusMaster.fulfilled, (state, action) => {
        // state.eventOnlinePlatformData = [action.payload, ...state.eventOnlinePlatformData];
        state.addAttendeeStatusLoader = false;
        if (action.payload) {
          if (!state.attendeeStatusData || !Array.isArray(state.attendeeStatusData)) {
            state.attendeeStatusData = [action.payload];
          } else {
            state.attendeeStatusData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddAttendeeStatusMaster.rejected, (state) => {
        state.addAttendeeStatusLoader = false;
      })

      // delete Attendee status
      .addCase(actionDeleteAttendeeStatusMaster.pending, (state, action) => {
        state.deleteAttendeeStatusLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteAttendeeStatusMaster.fulfilled, (state, action) => {
        state.attendeeStatusData = state.attendeeStatusData.filter(
          (type) => type.event_attendee_status_master_id !== action.payload
        );
        state.deleteAttendeeStatusLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteAttendeeStatusMaster.rejected, (state, action) => {
        state.deleteAttendeeStatusLoader[action.meta.arg.id] = false;
      })

      //update Attendee status
      .addCase(actionEditAttendeeStatusMaster.pending, (state, action) => {
        state.updateAttendeeStatusLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditAttendeeStatusMaster.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        const { event_attendee_status, event_attendee_status_master_id } =
          action.payload;

        state.updateAttendeeStatusLoader[id] = false;
        state.attendeeStatusData = state.attendeeStatusData.map(
          (attendeeStatus) =>
            attendeeStatus.event_attendee_status_master_id ===
            event_attendee_status_master_id
              ? { ...attendeeStatus, event_attendee_status }
              : attendeeStatus
        );
      })
      .addCase(actionEditAttendeeStatusMaster.rejected, (state, action) => {
        state.updateAttendeeStatusLoader[action.meta.arg.id] = false;
      })

      //ATTTENDE TYPE
      //get atttendee type
      .addCase(actionGetAttendeeTypeMaster.pending, (state) => {
        state.getAttendeeTypeLoader = true;
      })
      .addCase(actionGetAttendeeTypeMaster.fulfilled, (state, action) => {
        state.attendeeTypeData = action.payload;
        state.getAttendeeTypeLoader = false;
      })
      .addCase(actionGetAttendeeTypeMaster.rejected, (state) => {
        state.getAttendeeTypeLoader = false;
      })

      // get single attendee status
      .addCase(actionGetSingleAttendeeTypeMaster.pending, (state) => {
        state.getSingleAttendeeTypeLoader = true;
      })
      .addCase(actionGetSingleAttendeeTypeMaster.fulfilled, (state, action) => {
        state.getSingleAttendeeType = action.payload.data;
        state.getSingleAttendeeTypeLoader = false;
      })
      .addCase(actionGetSingleAttendeeTypeMaster.rejected, (state) => {
        state.getSingleAttendeeTypeLoader = false;
      })

      // add Attendee type
      .addCase(actionAddAttendeeTypeMaster.pending, (state) => {
        state.addAttendeeTypeLoader = true;
      })
      .addCase(actionAddAttendeeTypeMaster.fulfilled, (state, action) => {
        // state.eventOnlinePlatformData = [action.payload, ...state.eventOnlinePlatformData];
        state.addAttendeeTypeLoader = false;
        if (action.payload) {
          if (!state.attendeeTypeData || !Array.isArray(state.attendeeTypeData)) {
            state.attendeeTypeData = [action.payload];
          } else {
            state.attendeeTypeData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddAttendeeTypeMaster.rejected, (state) => {
        state.addAttendeeTypeLoader = false;
      })

      // delete Attendee type
      .addCase(actionDeleteAttendeeTypeMaster.pending, (state, action) => {
        state.deleteAttendeeTypeLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteAttendeeTypeMaster.fulfilled, (state, action) => {
        state.attendeeTypeData = state.attendeeTypeData.filter(
          (type) => type.event_attendee_type_master_id !== action.payload
        );
        state.deleteAttendeeTypeLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteAttendeeTypeMaster.rejected, (state, action) => {
        state.deleteAttendeeTypeLoader[action.meta.arg.id] = false;
      })

      //update Attendee type
      .addCase(actionEditAttendeeTypeMaster.pending, (state, action) => {
        state.updateAttendeeTypeLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditAttendeeTypeMaster.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        const { event_attendee_type, event_attendee_type_master_id } =
          action.payload;

        state.updateAttendeeTypeLoader[id] = false;
        state.attendeeTypeData = state.attendeeTypeData.map((attendeeType) =>
          attendeeType.event_attendee_type_master_id ===
          event_attendee_type_master_id
            ? { ...attendeeType, event_attendee_type }
            : attendeeType
        );
      })
      .addCase(actionEditAttendeeTypeMaster.rejected, (state, action) => {
        state.updateAttendeeTypeLoader[action.meta.arg.id] = false;
      })

      //EXHIBITON CATEGORY
      // get exhibition category
      .addCase(actionGetExhibitionCategoryMaster.pending, (state) => {
        state.getExhibitionCategoryLoader = true;
      })
      .addCase(actionGetExhibitionCategoryMaster.fulfilled, (state, action) => {
        state.exhibitionCategoryData = action.payload;
        state.getExhibitionCategoryLoader = false;
      })
      .addCase(actionGetExhibitionCategoryMaster.rejected, (state) => {
        state.getExhibitionCategoryLoader = false;
      })

      // get single Exhibition category
      .addCase(actionGetSingleExhibitionCategoryMaster.pending, (state) => {
        state.getSingleExhibitionCategoryLoader = true;
      })
      .addCase(
        actionGetSingleExhibitionCategoryMaster.fulfilled,
        (state, action) => {
          state.getSingleExhibitionCategory = action.payload.data;
          state.getSingleExhibitionCategoryLoader = false;
        }
      )
      .addCase(actionGetSingleExhibitionCategoryMaster.rejected, (state) => {
        state.getSingleExhibitionCategoryLoader = false;
      })

      // add exhibition category
      .addCase(actionAddExhibitionCategoryMaster.pending, (state) => {
        state.addExhibitionCategoryLoader = true;
      })
      .addCase(actionAddExhibitionCategoryMaster.fulfilled, (state, action) => {
        // state.eventOnlinePlatformData = [action.payload, ...state.eventOnlinePlatformData];
        state.addExhibitionCategoryLoader = false;
        if (action.payload) {
          if (!state.exhibitionCategoryData || !Array.isArray(state.exhibitionCategoryData)) {
            state.exhibitionCategoryData = [action.payload];
          } else {
            state.exhibitionCategoryData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddExhibitionCategoryMaster.rejected, (state) => {
        state.addExhibitionCategoryLoader = false;
      })

      // delete exhibition category
      .addCase(
        actionDeleteExhibitionCategoryMaster.pending,
        (state, action) => {
          state.deleteExhibitionCategoryLoader[action.meta.arg.id] = true;
        }
      )
      .addCase(
        actionDeleteExhibitionCategoryMaster.fulfilled,
        (state, action) => {
          state.exhibitionCategoryData = state.exhibitionCategoryData.filter(
            (type) => type.event_exhibitor_category_master_id !== action.payload
          );
          state.deleteExhibitionCategoryLoader[action.meta.arg.id] = false;
        }
      )
      .addCase(
        actionDeleteExhibitionCategoryMaster.rejected,
        (state, action) => {
          state.deleteExhibitionCategoryLoader[action.meta.arg.id] = false;
        }
      )

      //update exhibition category
      .addCase(actionEditExhibitionCategoryMaster.pending, (state, action) => {
        state.updateExhibitionCategoryLoader[action.meta.arg.id] = true;
      })
      .addCase(
        actionEditExhibitionCategoryMaster.fulfilled,
        (state, action) => {
          const { id } = action.meta.arg;
          const { exhibitor_category, event_exhibitor_category_master_id } =
            action.payload;

          state.updateExhibitionCategoryLoader[id] = false;
          state.exhibitionCategoryData = state.exhibitionCategoryData.map(
            (exhibitionCategory) =>
              exhibitionCategory.event_exhibitor_category_master_id ===
              event_exhibitor_category_master_id
                ? { ...exhibitionCategory, exhibitor_category }
                : exhibitionCategory
          );
        }
      )
      .addCase(actionEditExhibitionCategoryMaster.rejected, (state, action) => {
        state.updateExhibitionCategoryLoader[action.meta.arg.id] = false;
      })

      //EVENT_PRODUCT
      // get event product
      .addCase(actionGetEventProductMaster.pending, (state) => {
        state.getEventProductLoader = true;
      })
      .addCase(actionGetEventProductMaster.fulfilled, (state, action) => {
        state.eventProductData = action.payload;
        state.getEventProductLoader = false;
      })
      .addCase(actionGetEventProductMaster.rejected, (state) => {
        state.getEventProductLoader = false;
      })

      // get single event product
      .addCase(actionGetSingleEventProductMaster.pending, (state) => {
        state.getSingleEventProductLoader = true;
      })
      .addCase(actionGetSingleEventProductMaster.fulfilled, (state, action) => {
        state.getSingleEventProduct = action.payload.data;
        state.getSingleEventProductLoader = false;
      })
      .addCase(actionGetSingleEventProductMaster.rejected, (state) => {
        state.getSingleEventProductLoader = false;
      })

      // add Event product
      .addCase(actionAddEventProductMaster.pending, (state) => {
        state.addEventProductLoader = true;
      })
      .addCase(actionAddEventProductMaster.fulfilled, (state, action) => {
        state.addEventProductLoader = false;
        if (action.payload) {
          if (!state.eventProductData || !Array.isArray(state.eventProductData)) {
            state.eventProductData = [action.payload];
          } else {
            state.eventProductData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddEventProductMaster.rejected, (state) => {
        state.addEventProductLoader = false;
      })

      // delete event product
      .addCase(actionDeleteEventProductMaster.pending, (state, action) => {
        state.deleteEventProductLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteEventProductMaster.fulfilled, (state, action) => {
        state.eventProductData = state.eventProductData.filter(
          (type) => type.event_product_master_id !== action.payload
        );
        state.deleteEventProductLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteEventProductMaster.rejected, (state, action) => {
        state.deleteEventProductLoader[action.meta.arg.id] = false;
      })

      //update event product
      .addCase(actionEditEventProductMaster.pending, (state, action) => {
        state.updateEventProductLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditEventProductMaster.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        const { product_name, event_product_master_id } = action.payload;

        state.updateEventProductLoader[id] = false;
        state.eventProductData = state.eventProductData.map((eventProducts) =>
          eventProducts.event_product_master_id === event_product_master_id
            ? { ...eventProducts, product_name }
            : eventProducts
        );
      })
      .addCase(actionEditEventProductMaster.rejected, (state, action) => {
        state.updateEventProductLoader[action.meta.arg.id] = false;
      })

      //get business partner
      .addCase(actionGetBusinessPartner.pending, (state, action) => {
        state.getBusinessPartnerLoader = true;
      })
      .addCase(actionGetBusinessPartner.fulfilled, (state, action) => {
        state.getBusinessPartnerLoader = false;
        state.businessPartnerData = action.payload.data;
        state.businessPartnersCount = action.payload.total_records;
      })
      .addCase(actionGetBusinessPartner.rejected, (state, action) => {
        state.getBusinessPartnerLoader = false;
      })

      // add business partner
      .addCase(actionAddBusinessPartner.pending, (state, action) => {
        state.addBusinessPartnerLoader = true;
      })
      .addCase(actionAddBusinessPartner.fulfilled, (state, action) => {
        state.addBusinessPartnerLoader = false;
        if (action.payload) {
          if (
            !state.allBusinessPartnerData ||
            !Array.isArray(state.allBusinessPartnerData)
          ) {
            state.allBusinessPartnerData = [action.payload];
          } else {
            state.allBusinessPartnerData = [
              action.payload,
              ...state.allBusinessPartnerData,
            ];
          }
        }
      })
      .addCase(actionAddBusinessPartner.rejected, (state, action) => {
        state.addBusinessPartnerLoader = false;
      })

      // add business partner
      .addCase(actionUpdateBusinessPartner.pending, (state, action) => {
        state.updateBusinessPartnerLoader = true;
      })
      .addCase(actionUpdateBusinessPartner.fulfilled, (state, action) => {
        state.updateBusinessPartnerLoader = false;
      })
      .addCase(actionUpdateBusinessPartner.rejected, (state, action) => {
        state.updateBusinessPartnerLoader = false;
      })

      // add business partner
      .addCase(actionGetSingleBusinessPartner.pending, (state, action) => {
        state.getBusinessPartnerDetailLoader = true;
      })
      .addCase(actionGetSingleBusinessPartner.fulfilled, (state, action) => {
        state.getBusinessPartnerDetailLoader = false;
        state.businessPartnerDetail = action.payload.data;
      })
      .addCase(actionGetSingleBusinessPartner.rejected, (state, action) => {
        state.getBusinessPartnerDetailLoader = false;
      })

      // get all  business partner
      .addCase(actionGetAllBusinessPartner.pending, (state, action) => {
        state.getAllBusinessPartnerLoader = true;
      })
      .addCase(actionGetAllBusinessPartner.fulfilled, (state, action) => {
        state.getAllBusinessPartnerLoader = false;
        state.allBusinessPartnerData = action.payload.data;
      })
      .addCase(actionGetAllBusinessPartner.rejected, (state, action) => {
        state.getAllBusinessPartnerLoader = false;
      })

      // delete  business partner
      .addCase(actionDeleteBusinessPartner.pending, (state, action) => {
        state.deleteBusinessPartnerLoader = true;
      })
      .addCase(actionDeleteBusinessPartner.fulfilled, (state, action) => {
        state.deleteBusinessPartnerLoader = false;
        if (action.payload) {
          state.businessPartnerData = state.businessPartnerData.filter(
            (b) => b.business_partner_id !== action.payload
          );
          state.businessPartnersCount = state.businessPartnersCount - 1;
        }
      })
      .addCase(actionDeleteBusinessPartner.rejected, (state, action) => {
        state.deleteBusinessPartnerLoader = false;
      });
  },
});
export default masterDataSlice.reducer;
