import React, { useState } from "react";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import salesOrder from "../../assets/img/salesOrder.svg";
import { Table, Dropdown, Popconfirm } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { MdDoNotDisturbAlt } from "react-icons/md";

const SalesOrders = () => {
  const [search, setSearch] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleBlurAndPressEnter = (e) => {
    setSearch(e.target.value);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const invoiceStatuses = [
    "Pending",
    "Completed",
    "Cancelled",
    "Partially Invoiced",
  ];
  const paymentStatuses = ["Collected", "Partially Collected", "Pending"];
  const erpInvoiceNos = [
    "Pending for ASM Approval",
    "Pending for RSM Approval",
    "Pending for NSM Approval",
    "Pending for ZSM Approval",
  ];

  const getStatusClass = (status) => {
    switch (status) {
      case "Pending":
        return "text-[#C69619]";
      case "Completed":
        return "text-[#15A02B]";
      case "Cancelled":
        return "text-[#DD1B1B]";
      case "Partially Invoiced":
        return "text-[#7C7C7C]";
      default:
        return "text-gray-800";
    }
  };
  const getPaymentStatusClass = (status) => {
    switch (status) {
      case "Collected":
        return "text-[#DD1B1B]";
      case "Partially Collected":
        return "text-blue-800";
      case "Pending":
        return "text-[#C69619]";
      default:
        return "text-gray-800";
    }
  };

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: 200,
    },
    {
      title: "Print Status",
      dataIndex: "printStatus",
      key: "printStatus",
      width: 150,
    },
    {
      title: "Invoice No.",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      width: 200,
    },
    {
      title: "Erp Invoice No.",
      dataIndex: "erpInvoiceNo",
      key: "erpInvoiceNo",
      width: 250,
    },
    {
      title: "Invoice Status",
      dataIndex: "invoiceStatus",
      key: "invoiceStatus",
      width: 250,
      render: (text) => {
        const statusClass = getStatusClass(text);
        return text ? (
          <span
            className={`${statusClass} border py-1.5 rounded-[5px] px-2.5 text-sm`}
          >
            {text}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Customer Beat",
      dataIndex: "customerBeat",
      key: "customerBeat",
      width: 150,
    },
    {
      title: "Salesman ERP Id",
      dataIndex: "salesmanErpId",
      key: "salesmanErpId",
      width: 150,
    },
    {
      title: "Salesman Name",
      dataIndex: "salesmanName",
      key: "salesmanName",
      width: 200,
    },
    {
      title: "SO No.",
      dataIndex: "soNo",
      key: "soNo",
      width: 200,
    },
    {
      title: "SO Creation Date",
      dataIndex: "soCreationDate",
      key: "soCreationDate",
      width: 200,
    },
    {
      title: "SO Amount",
      dataIndex: "soAmount",
      key: "soAmount",
      width: 200,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 250,
      render: (text) => {
        const paymentClass = getPaymentStatusClass(text);
        return text ? (
          <span
            className={`${paymentClass} border py-1.5 rounded-[5px] px-2.5 text-sm`}
          >
            {text}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 200,
    },
    {
      title: "Remaining Collection",
      dataIndex: "remainingCollection",
      key: "remainingCollection",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Deactivate</span>,
                  key: "Deactivate",
                  icon: <MdDoNotDisturbAlt />,
                },
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  // onClick: () => {
                  //   setIsModalOpen(true);
                  //   setExhibitorData(data);
                  //   dispatch(actionGetExhibitionCategoryMaster());
                  // },
                },
                {
                  label: <span>Add User</span>,
                  key: "Add User",
                  icon: <PlusCircleOutlined />,
                },

                // {
                //   label: <span>View</span>,
                //   key: "View",
                //   icon: <EyeOutlined />,
                //   onClick: () =>
                //     navigate("/update-user", { state: { userId: data.id } }),
                // },
                // {
                //   label: (
                //     <Popconfirm
                //       okButtonProps={
                //         {
                //           // loading: deleteExhibitorLoader,
                //         }
                //       }
                //       placement="topLeft"
                //       title={`Are you sure to delete this exhibitor?`}
                //       onConfirm={() => {
                //         // handleOk
                //         //   dispatch(
                //         //     actionDeleteEventExhibitor({
                //         //       id: data.event_exhibitor_id,
                //         //       event_id: eventId,
                //         //     })
                //         //   );
                //       }}
                //       okText="Yes"
                //       cancelText="No"
                //     >
                //       <div className="flex items-center">
                //         <DeleteOutlined
                //           style={{ fontSize: 12, color: "#ff4d4f" }}
                //         />
                //         <span style={{ marginLeft: 8, color: "#ff4d4f" }}>
                //           Delete
                //         </span>
                //       </div>
                //     </Popconfirm>
                //   ),
                //   key: "Delete",
                // },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 5; i++) {
    data.push({
      key: i,
      customerName: `Test SuperStockist ${i}`,
      printStatus: "15 Jul 2010", // Static date for simplicity
      invoiceNo: `SO-11079456-00${i}`,
      erpInvoiceNo: erpInvoiceNos[i % erpInvoiceNos.length],
      invoiceStatus: invoiceStatuses[i % invoiceStatuses.length],
      customerBeat: `${1}Test Super`,
      salesmanErpId: `ERP-${1000 + i}`, // Static Salesman ERP Id
      salesmanName: `Salesman Name ${i}`, // Static Salesman Name
      soNo: `SO-${2000 + i}`, // Static SO No.
      soCreationDate: "10 Aug 2024", // Static SO Creation Date
      soAmount: `8569${i}`, // Static SO Creation Date
      paymentStatus: paymentStatuses[i % paymentStatuses.length],
      invoiceDate: `December 2, 2018`,
      remainingCollection: `22,400${i}`,
    });
  }
  return (
    <section className="main-wrapper">
      <PageTitle
        image={salesOrder}
        title="Sales Orders"
        buttonText="Create New SO"
        //   buttonLink="/create-lead"
      />

      <CustomInputWithButtons
        // handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
        // onClickDownloadMenu={onClickDownloadMenu}
      />

      <div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          pagination={false}
          bordered
          dataSource={data}
          // virtual
          scroll={{ x: "max-content" }}
        />
      </div>
    </section>
  );
};

export default SalesOrders;
