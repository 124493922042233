import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deleteEvent from "../../../assets/img/deleteevent.svg";
import generalmaster from "../../../assets/img/generalmaster.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import opportunitystage from "../../../assets/img/opportunitystage.svg";
import editEvent from "../../../assets/img/editevent.svg";
import SortIcon from "../../common/SortIcon";
import {
  actionAddOpportunityStageMaster,
  actionDeleteOpportunityStageMaster,
  actionEditOpportunityStageMaster,
  actionGetOpportunityStageMaster,
  actionGetSingleOpportunityStageMaster,
} from "../../../store/services/generalMasterService";
import { isValidSearchString } from "../../helper/common-function";

const OpportunityStage = () => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    getOpportunityStageLoader,
    opportunityStageData,
    addOpportunityStageLoader,
    deleteOpportunityStageLoader,
    updateOpportunityStageLoader,
    opportunityStageDetail,
  } = useSelector((state) => state.generalMaster);
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [eventTypeData, setEventTypeData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditing = (record) =>
    record?.opportunity_stage_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetOpportunityStageMaster({ search }));
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? <Input /> : <Input variant="borderless" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Stage" ? true : false,
                message: `${title} is required.`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(
      actionGetSingleOpportunityStageMaster(record.opportunity_stage_master_id)
    );
    setEdited(true);
  };


  useEffect(() => {
    if (opportunityStageDetail && edited) {
      editableForm.setFieldsValue({
        opportunity_stage: opportunityStageDetail.opportunity_stage,
      });
      setEditingKey(opportunityStageDetail.opportunity_stage_master_id);
    }
  }, [opportunityStageDetail]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { opportunity_stage } = row;
      const req = { opportunity_stage };
      dispatch(
        actionEditOpportunityStageMaster({ id, req, setEditingKey, setEdited })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: 100,
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Stage",
      dataIndex: "opportunity_stage",
      key: "2",
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="-ml-0.5">{text || "-"}</span>,
    },
    {
      title: "Action",
      key: "4",
      width: 200,
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateOpportunityStageLoader[
                  record.opportunity_stage_master_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.opportunity_stage_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editEvent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading: deleteOpportunityStageLoader,
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteOpportunityStageMaster({
                      id: record.opportunity_stage_master_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deleteEvent} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "event_type" || col.dataIndex === "description"
            ? "text"
            : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAddEventType = (values) => {
    dispatch(actionAddOpportunityStageMaster({ values, handleCancel }));
  };

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetOpportunityStageMaster({
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetOpportunityStageMaster({
          search: e.target.value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetOpportunityStageMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">
          Opportunity Stage Master
        </h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Add Opportunity Stage
          </Button>
          <Modal
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            open={isModalOpen}
          >
            <div className="flex">
              <img src={opportunitystage} alt="" />
              <h1 className="text-[#6883FD] text-[20px]  ml-[8px] mt-[5px] font-medium">
                Opportuanity Stage Master
              </h1>
            </div>
            <Form
              form={form}
              onFinish={handleAddEventType}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="opportunity_stage"
                label="Opportunity Stage"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input opportunity stage!",
                  },
                ]}
              >
                <Input
                  allowClear
                  style={{ background: "white" }}
                  placeholder="Enter Opportunity Stage"
                  className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addOpportunityStageLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="pt-[25px]">
        <Input
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          onBlur={handleBlurAndPressEnter}
          onPressEnter={handleBlurAndPressEnter}
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
        />
      </div>

      <div className="pt-5 pb-[75px]">
        <Form form={editableForm} component={false}>
          <Table
            onChange={handleSortChange}
            scroll={{ y: 500 }}
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={getOpportunityStageLoader}
            bordered
            dataSource={opportunityStageData}
            rowKey="opportunity_stage_master_id"
            columns={mergedColumns}
            rowClassName="editable-row"
          />
        </Form>
      </div>
    </div>
  );
};

export default OpportunityStage;
