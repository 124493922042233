import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Card,
  Checkbox,
  Col,
  Image,
  Layout,
  Menu,
  Radio,
  Row,
  Spin,
  Switch,
  Button,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetSingleRoleProfile,
  actionAssignRoleProfilePermission,
} from "../../../store/services/settingService";
import { formatString } from "../../helper/common-function";
import CustomIconText from "../../common/CustomIconText";
import purplePermission from "../../../assets/img/purplePermission.svg";
import leftArrowPrimary from "../../../assets/img/leftArrowPrimary.svg";

const ModulePermissions1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { roleProfileId } = location.state || {};
  const {
    roleProfileDetail,
    getSingleRoleProfileLoader,
    assignRoleProfilePermissionLoader,
  } = useSelector((state) => state.setting);

  const [selectedKey, setSelectedKey] = useState("");
  const [switchStates, setSwitchStates] = useState({});
  const [globalPermissions, setGlobalPermissions] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false); // Track unsaved changes

  useEffect(() => {
    if (roleProfileId) {
      dispatch(actionGetSingleRoleProfile(roleProfileId));
    }
  }, [roleProfileId, dispatch]);

  const SidebarData = useMemo(() => {
    const data = [];
    roleProfileDetail?.access_rights?.forEach((item) => {
      data.push({
        label: formatString(item.resource_category_name),
        key: item.resource_category_id,
      });
    });
    return data;
  }, [roleProfileDetail]);

  useEffect(() => {
    if (roleProfileDetail && roleProfileDetail.access_rights.length > 0) {
      setSelectedKey(roleProfileDetail.access_rights[0].resource_category_id);

      const initialSwitchStates = {};
      const initialGlobalPermissions = {};
      roleProfileDetail.access_rights.forEach((section) => {
        if (section.resources && section.resources.length > 0) {
          section.resources.forEach((data) => {
            initialSwitchStates[data.resources_id] = determineSwitchState(
              data.permissions
            );
            initialGlobalPermissions[data.resources_id] = initializePermissions(
              data.permissions
            );
          });
        }
      });
      setSwitchStates(initialSwitchStates);
      setGlobalPermissions(initialGlobalPermissions);
    }
  }, [roleProfileDetail]);

  const determineSwitchState = (permissions) => {
    return permissions.some((perm) => perm.is_selected);
  };

  const initializePermissions = (permissions) => {
    const initialSelected = {};
    const initialChecked = {};
    permissions.forEach((perm) => {
      if (!initialChecked[perm.actions]) {
        initialChecked[perm.actions] = false;
      }
      if (perm.is_selected) {
        initialChecked[perm.actions] = true;
        initialSelected[perm.actions] = perm.possessions;
      }
    });
    return { selected: initialSelected, checked: initialChecked };
  };

  const handleSwitchChange = useCallback((resourcesId, checked) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [resourcesId]: checked,
    }));

    if (checked) {
      setGlobalPermissions((prevState) => ({
        ...prevState,
        [resourcesId]: {
          selected: {
            create: "own",
            read: "own",
            update: "own",
            delete: "own",
          },
          checked: { create: true, read: true, update: true, delete: true },
        },
      }));
    } else {
      setGlobalPermissions((prevState) => ({
        ...prevState,
        [resourcesId]: {
          selected: { create: "", read: "", update: "", delete: "" },
          checked: { create: false, read: false, update: false, delete: false },
        },
      }));
    }

    setUnsavedChanges(true); // Mark changes as unsaved
  }, []);

  const handlePossessionChange = (resourcesId, action, possession) => {
    setGlobalPermissions((prevState) => ({
      ...prevState,
      [resourcesId]: {
        ...prevState[resourcesId],
        selected: {
          ...prevState[resourcesId]?.selected,
          [action]: possession,
        },
      },
    }));

    setUnsavedChanges(true); // Mark changes as unsaved
  };

  const handleCheckboxChange = useCallback((resourcesId, action, checked) => {
    setGlobalPermissions((prevState) => {
      const updatedPermissions = {
        ...prevState,
        [resourcesId]: {
          ...prevState[resourcesId],
          checked: {
            ...prevState[resourcesId]?.checked,
            [action]: checked,
          },
          selected: {
            ...prevState[resourcesId]?.selected,
            [action]: checked ? "own" : "",
          },
        },
      };

      // Automatically set read checkbox based on create, update, delete
      if (
        checked &&
        (action === "create" || action === "update" || action === "delete")
      ) {
        updatedPermissions[resourcesId] = {
          ...updatedPermissions[resourcesId],
          checked: {
            ...updatedPermissions[resourcesId]?.checked,
            read: true,
          },
          selected: {
            ...updatedPermissions[resourcesId]?.selected,
            read: "own",
          },
        };
      } else if (!checked && action === "read") {
        updatedPermissions[resourcesId] = {
          ...updatedPermissions[resourcesId],
          checked: {
            create: false,
            update: false,
            delete: false,
            read: false,
          },
          selected: {
            read: "",
            create: "",
            update: "",
            delete: "",
          },
        };
      }

      // Check if all actions are false, then turn off the switch
      const allFalse = Object.keys(
        updatedPermissions[resourcesId]?.checked
      ).every((key) => !updatedPermissions[resourcesId]?.checked[key]);

      setSwitchStates((prevState) => ({
        ...prevState,
        [resourcesId]: !allFalse,
      }));

      return updatedPermissions;
    });

    setUnsavedChanges(true); // Mark changes as unsaved
  }, []);

  const handleSavePermissions = async () => {
    const updatedRoleProfileDetail = JSON.parse(
      JSON.stringify(roleProfileDetail)
    );
    const permissionsArray = [];

    await updatedRoleProfileDetail.access_rights.forEach((section) => {
      if (section.resources) {
        section.resources.forEach((resource) => {
          const permissions = resource.permissions;
          const globalPermission = globalPermissions[resource.resources_id];

          permissions.forEach((perm) => {
            if (
              globalPermission.checked[perm.actions] &&
              globalPermission.selected[perm.actions] === perm.possessions
            ) {
              perm.is_selected = true;
            } else {
              perm.is_selected = false;
            }

            permissionsArray.push({
              permission_id: perm.permissions_id,
              is_selected: perm.is_selected,
            });
          });
        });
      }
    });

    const req = {
      role_profile_id: roleProfileId,
      permission: permissionsArray,
    };
    await dispatch(
      actionAssignRoleProfilePermission({ req, setUnsavedChanges })
    );
  };

  return (
    <Spin spinning={getSingleRoleProfileLoader}>
      <section className="main-section">
        <div className="flex justify-between">
          <div
            onClick={() => navigate(-1)}
            className="text-brand cursor-pointer flex items-center gap-x-3"
          >
            <Image
              src={leftArrowPrimary}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base font-popinsSemiBold">
              {formatString(roleProfileDetail?.name)}
            </h1>
          </div>
          <Button
            className="outline-button-brand  min-h-[35px] min-w-[120px]"
            loading={assignRoleProfilePermissionLoader}
            onClick={handleSavePermissions}
            disabled={!unsavedChanges || assignRoleProfilePermissionLoader}
          >
            Save Permissions
          </Button>
          {/* <CustomIconText title={"Permissions"} image={purplePermission} /> */}
        </div>
        <Card className="mt-2 permission-card">
          <div className="h-full flex">
            <div className="border-r border-bColor xl:w-[25%] w-[20%] 2xl:min-h-[750px] h-auto">
              <h1 className="lg:text-lg text-base font-popinsSemiBold pl-7 pt-7">
                Module Permissions
              </h1>
              <Layout className="inner-permission-layout ">
                <Layout.Sider
                  className={`transition-all pb-6 py-3 side-menu duration-1000 fixed overflow-auto ease-in`}
                  style={{
                    background: "#fff",
                  }}
                >
                  <Menu
                    onSelect={({ key }) => setSelectedKey(parseInt(key))}
                    className="pb-5 border-0"
                    theme={"light"}
                    mode="inline"
                    selectedKeys={[selectedKey.toString()]}
                    items={SidebarData}
                  />
                </Layout.Sider>
              </Layout>
            </div>

            <div className="px-10 xl:w-[75%] w-[80%] pt-7">
              {roleProfileDetail &&
                roleProfileDetail.access_rights.map((section) => (
                  <div
                    key={section.resource_category_id}
                    style={{
                      display:
                        selectedKey === section.resource_category_id
                          ? "block"
                          : "none",
                    }}
                  >
                    <h1 className="lg:text-lg text-base font-popinsSemiBold">
                      {formatString(section.resource_category_name)} Permissions
                    </h1>

                    {section.resources &&
                      section.resources.length > 0 &&
                      section.resources.map((data, index) => (
                        <Row
                          justify={"space-between"}
                          gutter={24}
                          key={index}
                          className="flex max-w-full py-7 border-b border-bColor"
                        >
                          <Col span={10} className="flex justify-between">
                            <h1 className="lg:text-base text-base">
                              {formatString(data.resources_name)}
                            </h1>
                            <Switch
                              checked={switchStates[data.resources_id]}
                              onChange={(checked) =>
                                handleSwitchChange(data.resources_id, checked)
                              }
                            />
                          </Col>
                          <Col span={2} />
                          <Col span={12} className="flex justify-between">
                            <PermissionGroup
                              data={data}
                              switchState={switchStates}
                              globalPermissions={globalPermissions}
                              onPossessionChange={(action, possession) =>
                                handlePossessionChange(
                                  data.resources_id,
                                  action,
                                  possession
                                )
                              }
                              onCheckboxChange={(action, checked) =>
                                handleCheckboxChange(
                                  data.resources_id,
                                  action,
                                  checked
                                )
                              }
                            />
                          </Col>
                        </Row>
                      ))}
                  </div>
                ))}
            </div>
          </div>
        </Card>
      </section>
    </Spin>
  );
};

const PermissionGroup = ({
  data,
  switchState,
  globalPermissions,
  onPossessionChange,
  onCheckboxChange,
}) => {
  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [disabled, setDisabled] = useState(false); // State to manage checkbox disable state

  useEffect(() => {
    if (data && Array.isArray(data.permissions)) {
      const grouped = data.permissions.reduce((acc, perm) => {
        if (!acc[perm.actions]) {
          acc[perm.actions] = { any: null, own: null };
        }
        acc[perm.actions][perm.possessions] = perm;
        return acc;
      }, {});
      setGroupedPermissions(grouped);

      // Determine if checkboxes should be disabled based on switch state
      const switchOn = switchState[data.resources_id];
      setDisabled(!switchOn);
    }
  }, [data, switchState]);

  return (
    <Row className="mb-2">
      {Object.keys(groupedPermissions).map((action, index) => (
        <Fragment key={index}>
          <Col span={16}>
            <Checkbox
              className="custom-checkbox"
              checked={globalPermissions[data.resources_id]?.checked[action]}
              onChange={(e) => onCheckboxChange(action, e.target.checked)}
              disabled={disabled} // Disable checkbox based on state
            >
              {action.charAt(0).toUpperCase() + action.slice(1)}
            </Checkbox>
          </Col>
          <Col span={8}>
            <Radio.Group
              className="flex justify-between"
              value={globalPermissions[data.resources_id]?.selected[action]}
              onChange={(e) => onPossessionChange(action, e.target.value)}
              disabled={
                !globalPermissions[data.resources_id]?.checked[action] ||
                disabled
              } // Disable radio group based on state
            >
              <Radio className="text-base" value="any">
                Any
              </Radio>
              <Radio className="text-base" value="own">
                Own
              </Radio>
            </Radio.Group>
          </Col>
        </Fragment>
      ))}
    </Row>
  );
};

export default ModulePermissions1;
