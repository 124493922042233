import { Avatar, Button, Card, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import addUser from "../../assets/img/user-add.svg";
import CustomIconText from "../common/CustomIconText";
import {
  actionGetRoles,
  actionGetAllRoleProfile,
} from "../../store/services/settingService";
import {
  actionAddUser,
  actionClearUsersAllData,
  actionGetCompanyAllUsers,
} from "../../store/services/usersService";

const CreateUser = () => {
  const [form] = Form.useForm();

  const [isSaveAndAddNew, setIsSaveAndAddNew] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getRolesLoader, roles, getAllRoleProfileLoader, roleAllProfile } =
    useSelector((state) => state.setting);
  const { addUserLoader, getCompanyAllUserLoader, companyAllUsers } =
    useSelector((state) => state.users);

  useEffect(() => {
    dispatch(actionGetRoles());
    dispatch(actionGetAllRoleProfile());
    dispatch(actionGetCompanyAllUsers());
  }, [dispatch]);

  const onFinish = (values) => {
    const req = [
      {
        email: values.email,
        role_id: values.role_id,
        role_profile_id: values.role_profile_id,
        reports_to_id: values.reports_to_id,
      },
    ];
    if (isSaveAndAddNew) {
      dispatch(actionAddUser({ req, form }));
    } else {
      dispatch(actionAddUser({ req, navigate }));
    }
  };

  const roleData = [];
  const roleProfileData = [];
  const reportsToData = [];

  roles &&
    roles.map((data) =>
      roleData.push({
        label: data.name,
        value: data.id,
      })
    );
  roleAllProfile &&
    roleAllProfile.map((data) =>
      roleProfileData.push({
        label: data.name,
        value: data.id,
      })
    );
  companyAllUsers &&
    companyAllUsers.map((data) =>
      reportsToData.push({
        label: (
          <span>
            <Avatar
              style={{
                verticalAlign: "middle",
              }}
              className="mr-2"
              src={data.profile_pic}
            />
            {/*  {data.first_name && data.last_name
                ? data.first_name.slice(0, 1).toUpperCase() +
                  data.last_name.slice(0, 1).toUpperCase()
                : data.first_name.slice(0, 2).toUpperCase()} */}
            {`${data.first_name ? data.first_name : ""} ${
              data.last_name ? data.last_name : ""
            }`}
          </span>
        ),
        value: data.id,
      })
    );

  return (
    <section className="main-section">
      <div className="mb-4">
        <CustomIconText image={addUser} title="Add User" backArrow={true} />
      </div>
      <Card className="main-card mt-5 w-full">
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please Input Your email!",
                  },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Role"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select Role!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Role"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={roleData}
                  disabled={getRolesLoader}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Role Profile"
                name="role_profile_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select Role Profile!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Role Profile"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={roleProfileData}
                  disabled={getAllRoleProfileLoader}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Report To"
                name="reports_to_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select Report To!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Report To"
                  // optionFilterProp="children"
                  /*  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  } */
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.props?.children[1] ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={reportsToData}
                  disabled={getCompanyAllUserLoader}
                />
              </Form.Item>
            </Col>

            <Col
              span={24}
              className="flex items-center my-4 justify-center gap-x-5"
            >
              <Form.Item>
                <Button
                  onClick={async () => {
                    await dispatch(actionClearUsersAllData());
                    navigate(-1);
                  }}
                  type="button"
                  className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                  htmlType="button"
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={addUserLoader && !isSaveAndAddNew}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                  onClick={() => setIsSaveAndAddNew(false)}
                  disabled={addUserLoader && !isSaveAndAddNew}
                >
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={addUserLoader && isSaveAndAddNew}
                  type="primary"
                  className="outline-button-brand  min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                  onClick={() => setIsSaveAndAddNew(true)}
                  disabled={addUserLoader && isSaveAndAddNew}
                >
                  Save & Add New
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </section>
  );
};

export default CreateUser;
