import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Table,
  Typography,
  Select,
  Popover,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import plusSvg from "../../../assets/img/plus.svg";
import {
  actionAddUnitConversionMaster,
  actionDeleteUnitConversionMaster,
  actionEditUnitConversionMaster,
  actionGetSingleUnitConversionMaster,
  actionGetUnitConversionMaster,
} from "../../../store/services/generalMasterService";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import {
  actionAddUnitMaster,
  actionGetUnitMaster,
} from "../../../store/services/productMasterService";

const AddressType = () => {
  // const [editableForm] = Form.useForm();
  const [fromUnitMasterModalForm] = Form.useForm();
  const [toUnitMasterModalForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    unitConversionMasterData,
    getSingleUnitConversionMasterData,
    getSingleUnitConversionMasterLoader,
    getUnitConversionLoader,
    addUnitConversionMasterLoader,
    deleteUnitConversionMasterLoader,
    updateUnitConversionMasterLoader,
  } = useSelector((state) => state.generalMaster);
  const { addUnitLoader, unitMasterData } = useSelector(
    (state) => state.productMaster
  );

  console.log({ getSingleUnitConversionMasterData });

  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [select, setSelect] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unitConversionData, setunitConversionData] = useState(null);
  const [isFromUnitModalOpen, setIsFromUnitModalOpen] = useState(false);
  const [isToUnitModalOpen, setIsToUnitModalOpen] = useState(false);

  const isEditing = (record) => record.unit_conversion_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetUnitConversionMaster({ search }));
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetUnitConversionMaster({
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetUnitConversionMaster({
          search: value,
        })
      );
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(actionGetUnitMaster());
  };

  useEffect(() => {
    if (unitConversionData) {
      dispatch(
        actionGetSingleUnitConversionMaster(
          unitConversionData.unit_conversion_master_id
        )
      );
    }
  }, [dispatch, unitConversionData]);

  useEffect(() => {
    if (unitConversionData && getSingleUnitConversionMasterData) {
      form.setFieldsValue({
        from_unit_id:
          getSingleUnitConversionMasterData.from_unit_id?.unit_master_id,
        to_unit_id:
          getSingleUnitConversionMasterData.to_unit_id?.unit_master_id,
        conversion_factor: getSingleUnitConversionMasterData.conversion_factor,
      });
    }
  }, [dispatch, getSingleUnitConversionMasterData]);

  const fromUnit =
    unitMasterData &&
    unitMasterData.length > 0 &&
    unitMasterData.map((data) => ({
      label: data.code,
      value: data.unit_master_id,
    }));

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  //from unit
  const showFromUnitModalOpen = () => {
    setIsFromUnitModalOpen(true);
  };

  const cancelFromUnitModal = () => {
    setIsFromUnitModalOpen(false);
    fromUnitMasterModalForm.resetFields();
  };

  const handleAddUnitMaster = (values) => {
    dispatch(
      actionAddUnitMaster({ values, handleCancel: cancelFromUnitModal })
    );
  };

  //to unit
  const showToUnitModalOpen = () => {
    setIsToUnitModalOpen(true);
  };

  const cancelToUnitModal = () => {
    setIsToUnitModalOpen(false);
    toUnitMasterModalForm.resetFields();
  };

  const handleToUnitMaster = (values) => {
    dispatch(actionAddUnitMaster({ values, handleCancel: cancelToUnitModal }));
  };

  const onFinish = (values) => {
    unitConversionData
      ? dispatch(
          actionEditUnitConversionMaster({
            id: getSingleUnitConversionMasterData.unit_conversion_master_id,
            req: values,
            handleCancel,
          })
        )
      : dispatch(
          actionAddUnitConversionMaster({
            values,
            handleCancel,
          })
        );
  };

  const fromUnitPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={fromUnitMasterModalForm}
          onFinish={handleAddUnitMaster}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="code"
            label="Unit Code"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input unit code!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Unit"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelFromUnitModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addUnitLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const toUnitPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={toUnitMasterModalForm}
          onFinish={handleToUnitMaster}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="code"
            label="Unit Code"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input unit code!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Unit"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelToUnitModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addUnitLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: "12%",
      // editable: false,
      render: (text, record, index) => index + 1,
    },

    {
      title: "From Unit",
      dataIndex: "from_unit_id",
      key: "2",
      width: "22%",
      // editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (text ? text.code : text),
    },
    {
      title: "To Unit",
      dataIndex: "to_unit_id",
      key: "2",
      width: "22%",
      // editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (text ? text.code : text),
    },
    {
      title: "Conversion Factor",
      dataIndex: "conversion_factor",
      key: "2",
      width: "22%",
      // editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "4",
      dataIndex: "operation",
      render: (_, record) => {
        const getSingleUnitConversionMasterData = isEditing(record);
        return (
          <div className="flex items-center">
            {getSingleUnitConversionMasterData ? (
              <div className="flex items-center"></div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    showModal();
                    setunitConversionData(record);
                    dispatch(actionGetUnitConversionMaster());
                  }}
                  // onClick={() => edit(record)}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading:
                    deleteUnitConversionMasterLoader[
                      record.unit_conversion_master_id
                    ],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteUnitConversionMaster({
                      id: record.unit_conversion_master_id,
                      // setEditingKey,
                    })
                  )
                }
              >
                <img src={deletevent} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetUnitConversionMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">
          Unit Conversion
        </h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Add Unit Conversion
          </Button>
          <Modal
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={600}
            title=""
            open={isModalOpen}
          >
            <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
              Unit Conversion
            </h1>
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Popover
                    open={isFromUnitModalOpen}
                    placement="bottomRight"
                    content={fromUnitPopover}
                    trigger="click"
                    onClick={showFromUnitModalOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setIsFromUnitModalOpen(false);
                        fromUnitMasterModalForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showFromUnitModalOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add From Unit
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    name="from_unit_id"
                    label="From Unit"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please select from unit!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Beat Grade"
                      optionFilterProp="children"
                      className=""
                      // loading={getBeatGradeMasterLoader}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={fromUnit}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Popover
                    open={isToUnitModalOpen}
                    placement="bottomRight"
                    content={toUnitPopover}
                    trigger="click"
                    onClick={showToUnitModalOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setIsToUnitModalOpen(false);
                        toUnitMasterModalForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showToUnitModalOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add To Unit
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    name="to_unit_id"
                    label="To Unit"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please select to unit!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select To Unit"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={fromUnit} // You'll need a similar `toUnit` data like `fromUnit` mapped from `unitMasterData`
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="conversion_factor"
                label="Conversion Factor"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input conversion factor!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Conversion Factor"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={
                        addUnitConversionMasterLoader ||
                        updateUnitConversionMasterLoader
                      }
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      {unitConversionData ? "Save" : "Submit"}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="pt-[25px]">
        <Input
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          onBlur={handleBlurAndPressEnter}
          onPressEnter={handleBlurAndPressEnter}
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
        />
      </div>

      <div className="pt-5 pb-[75px]">
        <Form component={false}>
          <Table
            onChange={handleSortChange}
            scroll={{ y: 500 }}
            pagination={false}
            // components={{
            //   body: {
            //     cell: EditableCell,
            //   },
            // }}
            loading={getUnitConversionLoader}
            bordered
            dataSource={unitConversionMasterData}
            rowKey="unit_conversion_master_id"
            columns={columns}
            // rowClassName="editable-row"
          />
        </Form>
      </div>
    </div>
  );
};

export default AddressType;
