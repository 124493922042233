import React, { useEffect, useState } from "react";
import { Menu, Layout, Row, Col } from "antd";
import Product from "./Product";
import ProductType from "./ProductType";
import ProductCategory from "./ProductCategory";
import ProductSubCategory from "./ProductSubCategory";
import HsnSacCode from "./HsnSacCode";
import Unit from "./Unit";
import { useLocation } from "react-router-dom";

const ProductMasters = () => {
  const [selectItem, setSelectItem] = useState("product");
  const location = useLocation();
  const { type } = location.state || {};

  useEffect(() => {
    if (type) {
      setSelectItem(type);
    }
  }, [type]);

  const items = [
    {
      label: <h1 className=" text-[15px]">Material</h1>,
      key: "product",
    },
    {
      label: <h1 className="text-[15px]">Material Type</h1>,
      key: "product-type",
    },
    {
      label: <h1 className="text-[15px]">HSN/SAC Code</h1>,
      key: "hsn-sac-code",
    },
    {
      label: <h1 className="text-[15px]">Material Category</h1>,
      key: "product-category",
    },
    {
      label: <h1 className="text-[15px]">Material Sub Category</h1>,
      key: "product-sub-category",
    },
    {
      label: <h1 className="text-[15px]">Unit</h1>,
      key: "unit",
    },
  ];
  const showEventpage = () => {
    switch (selectItem) {
      case "product":
        return <Product />;

      case "product-type":
        return <ProductType />;

      case "hsn-sac-code":
        return <HsnSacCode />;

      case "product-category":
        return <ProductCategory />;

      case "product-sub-category":
        return <ProductSubCategory />;

      case "unit":
        return <Unit />;

      default:
        return;
    }
  };

  return (
    <div className="bg-white border border-full border-[#DDDAE9] h-full">
      {/* EventMaster */}
      <Row className="flex bg-white">
        <Col span={5} className=" border-bColor border-r">
          <Layout className="inner-permission-layout">
            <h1 className="pl-[27px] text-[20px] font-medium pt-[14px]">
              Product Masters
            </h1>
            <div className="master-menu">
              <Menu
                onSelect={({ key }) => setSelectItem(key)}
                className="pt-2 pb-4 product-master-menus"
                theme={"light"}
                mode="inline"
                selectedKeys={[selectItem]}
                items={items}
              />
            </div>
          </Layout>
        </Col>
        <Col span={19} className="px-[20px]">
          {showEventpage()}
        </Col>
      </Row>
    </div>
  );
};

export default ProductMasters;
