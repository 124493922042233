import beatmaster from "../../assets/img/beatmaster.svg";
import {
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import plusSvg from "../../assets/img/plus.svg";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BeatActiveIcon from "../../assets/img/beat-active-icon.svg";
import calendar from "../../assets/img/beat-calendar.svg";
import DetailActiveIcon from "../../assets/img/beat-detail-active-icon.svg";
import DetailIcon from "../../assets/img/beat-detail-icon.svg";
import EmployeeIcon from "../../assets/img/beat-employee.svg";
import BeatIcon from "../../assets/img/beat-icon.svg";
import {
  actionAddAreaMaster,
  actionAddBeatGradeMaster,
  actionAddBeatMaster,
  actionAddZoneMaster,
  actionGetAreaMaster,
  actionGetBeatGradeMaster,
  actionGetBeatMaster,
  actionGetZoneMaster,
} from "../../store/services/generalMasterService";
import {
  actionCreateBeatPlanning,
  actionUpdateBeatPlanning,
} from "../../store/services/salesService";
import { actionGetRoles } from "../../store/services/settingService";
import { actionGetCompanyAllUsers } from "../../store/services/usersService";
import { getFullName } from "../helper/common-function";

const RecurrenceTypes = {
  DOES_NOT_REPEAT: "Does not repeat",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  YEARLY: "yearly ",
};

const RecurringEndTypes = {
  NEVER: "never",
  DATE: "date",
  OCCURRENCES: "occurrences",
};

const SearchInput = ({ value, onChange, placeholder, handleBlurAndEnter }) => (
  <Input
    className="ml-2  w-1/3"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onPressEnter={handleBlurAndEnter}
    prefix={<SearchOutlined className="text-[#7C7C7C]" />}
  />
);

const EmployeeSelector = ({ userSelected, setUserSelected }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [role, setRole] = useState(undefined);

  const regex = /^[a-zA-Z0-9 @.-]*$/;

  const { getCompanyAllUserLoader, companyAllUsers, companyAllUsersCount } =
    useSelector((state) => state.users);

  const { roles, getRolesLoader } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(actionGetCompanyAllUsers({ role, search }));
  }, [dispatch]);

  const applyFilters = (searchTerm, selectedRole) => {
    dispatch(
      actionGetCompanyAllUsers({ search: searchTerm, role: selectedRole })
    );
  };

  useEffect(() => {
    dispatch(actionGetRoles());
  }, [dispatch]);

  const roleOptions = useMemo(
    () =>
      roles && roles.length > 0
        ? roles.map((compUser) => ({
            label: compUser.name,
            value: compUser.id,
          }))
        : [],
    [roles]
  );

  const handleBlurAndEnter = (e) => {
    const value = e.target.value.trim();
    if (regex.test(value)) {
      if (search) {
        applyFilters(e.target.value, role);
      }
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (regex.test(value)) {
      setSearch(value);
      applyFilters(value, role);
    } else {
      if (value === "") {
        applyFilters("", role);
      }
    }
  };

  const onSelect = (val) => {
    setRole(val);
    applyFilters(search, val);
  };

  const handleUserCheckboxChange = (userId, checked) => {
    setUserSelected((prev) =>
      checked ? [...prev, userId] : prev.filter((id) => id !== userId)
    );
  };

  const handleSelectAllUsersChange = (e) => {
    setUserSelected((prev) => {
      if (e.target.checked && companyAllUsers?.length > 0) {
        return [
          ...new Set([...prev, ...companyAllUsers.map((user) => user.id)]),
        ];
      }
      return prev.filter(
        (id) =>
          !companyAllUsers?.length ||
          !companyAllUsers.some((user) => user.id === id)
      );
    });
  };

  const columns = [
    {
      title: (
        <Checkbox
          checked={
            companyAllUsers &&
            companyAllUsers.length > 0 &&
            companyAllUsers.every((user) => userSelected.includes(user.id))
          }
          indeterminate={
            companyAllUsers &&
            companyAllUsers.length > 0 &&
            companyAllUsers.some((user) => userSelected.includes(user.id)) &&
            companyAllUsers.some((user) => !userSelected.includes(user.id))
          }
          onChange={handleSelectAllUsersChange}
        />
      ),
      key: "checkbox",
      width: 50,
      render: (_, record) => (
        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorPrimary: "#6883fd",
                colorPrimaryHover: "#6883fd",
                colorPrimaryBorder: "#d9d9d9",
              },
            },
          }}
        >
          <Checkbox
            checked={userSelected.includes(record.id)}
            onChange={(e) =>
              handleUserCheckboxChange(record.id, e.target.checked)
            }
          />
        </ConfigProvider>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, data) => getFullName(data.first_name, data.last_name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Reports to",
      dataIndex: "reports_to",
      key: "reports_to",
      render: (text) => getFullName(text.first_name, text.last_name),
    },
  ];

  return (
    <>
      <div className="flex w-full items-center gap-2">
        <Select
          className="ml-2  w-1/3"
          showSearch
          placeholder="Search role"
          allowClear
          value={role}
          onClear={() => setRole(undefined)}
          onChange={onSelect}
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={roleOptions}
          disabled={getRolesLoader}
          loading={getRolesLoader}
        />
        <SearchInput
          handleBlurAndEnter={handleBlurAndEnter}
          value={search}
          onChange={handleSearch}
          placeholder="Type and press Enter to search"
        />

        <div
          className="text-[#4567FF] ml-auto tracking-wide  cursor-pointer text-sm"
          onClick={() => {
            if (search || role) {
              setSearch("");
              setRole(undefined);
              dispatch(actionGetCompanyAllUsers());
            }
          }}
        >
          Clear Filters
        </div>
        <br />
      </div>
      <div className="mt-8 mb-3 flex flex-col justify-between gap-y-2">
        <p className="text-end text-base text-brand">
          {userSelected && userSelected.length} User Selected
        </p>
        <Table
          rowKey={"id"}
          size="middle"
          scroll={{ y: 200 }}
          virtual
          columns={columns}
          dataSource={companyAllUsers}
          loading={getCompanyAllUserLoader}
          className=" rounded-md small-table"
          pagination={false}
        />
      </div>
    </>
  );
};

const BeatSelector = ({ setBeatSelected, beatSelected }) => {
  const regex = /^[a-zA-Z0-9 @.-]*$/;

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [zone, setZone] = useState(undefined);
  const [beatGrade, setBeatGrade] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isZoneModalOpen, setIsZoneModalOpen] = useState(false);
  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);
  const [isBeatGradeModalOpen, setIsBeatGradeModalOpen] = useState(false);
  const [beatForm] = Form.useForm();
  const [zoneMasterModalForm] = Form.useForm();
  const [areaMasterModalForm] = Form.useForm();
  const [beatGradeModalForm] = Form.useForm();

  const {
    getBeatMasterLoader,
    BeatMasterData,
    getBeatGradeMasterLoader,
    beatGradeMasterData,
    zoneMasterData,
    getAreaMasterLoader,
    getZoneMasterLoader,
    addZoneMasterLoader,
    addAreaMasterLoader,
    addBeatGradeMasterLoader,
    areaMasterData,

    addBeatMasterLoader,
  } = useSelector((state) => state.generalMaster);

  useEffect(() => {
    dispatch(actionGetBeatMaster());
    dispatch(actionGetBeatGradeMaster());
    dispatch(actionGetZoneMaster());
  }, [dispatch]);

  const handleBlurAndEnter = (e) => {
    const value = e.target.value.trim();
    if (regex.test(value)) {
      if (search) {
        dispatch(
          actionGetBeatMaster({
            grade: beatGrade,
            search: e.target.value,
            zone: zone,
          })
        );
      }
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (regex.test(value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetBeatMaster({
            grade: beatGrade,
            search: e.target.value,
            zone: zone,
          })
        );
      }
    }
  };

  const handleSelectZone = (val) => {
    setZone(val);
    dispatch(
      actionGetBeatMaster({
        grade: beatGrade,
        search: search,
        zone: val,
      })
    );
  };

  const handleSelectBeatGrade = (val) => {
    setBeatGrade(val);
    dispatch(
      actionGetBeatMaster({
        grade: val,
        search: search,
        zone: zone,
      })
    );
  };

  const handleBeatCheckboxChange = (ids, checked) => {
    setBeatSelected((prev) =>
      checked ? [...prev, ids] : prev.filter((id) => id !== ids)
    );
  };

  const handleSelectAllBeatsChange = (e) => {
    setBeatSelected((prev) => {
      if (e.target.checked && BeatMasterData?.length > 0) {
        return [
          ...new Set([
            ...prev,
            ...BeatMasterData.map((beat) => beat.beat_master_id),
          ]),
        ];
      }
      return prev.filter(
        (id) =>
          !BeatMasterData?.length ||
          !BeatMasterData.some((beat) => beat.beat_master_id === id)
      );
    });
  };

  const columns = [
    {
      title: (
        <Checkbox
          checked={
            BeatMasterData &&
            BeatMasterData.length > 0 &&
            BeatMasterData.every((beat) =>
              beatSelected.includes(beat.beat_master_id)
            )
          }
          indeterminate={
            BeatMasterData &&
            BeatMasterData.length > 0 &&
            BeatMasterData.some((beat) =>
              beatSelected.includes(beat.beat_master_id)
            ) &&
            BeatMasterData.some(
              (beat) => !beatSelected.includes(beat.beat_master_id)
            )
          }
          onChange={handleSelectAllBeatsChange}
        />
      ),
      key: "checkbox",
      width: 50,
      render: (_, record) => (
        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorPrimary: "#6883fd",
                colorPrimaryHover: "#6883fd",
                colorPrimaryBorder: "#d9d9d9",
              },
            },
          }}
        >
          <Checkbox
            checked={beatSelected.includes(record.beat_master_id)}
            onChange={(e) =>
              handleBeatCheckboxChange(record.beat_master_id, e.target.checked)
            }
          />
        </ConfigProvider>
      ),
    },
    {
      title: "Beat Name",
      dataIndex: "beat_name",
      key: "beat_name",
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setBeatSelected(newSelectedRowKeys);
  };

  const beatGradeOptions = useMemo(
    () =>
      beatGradeMasterData?.length > 0
        ? beatGradeMasterData.map((grade) => ({
            label: grade.beat_grade,
            value: grade.beat_grade_master_id,
          }))
        : [],
    [beatGradeMasterData]
  );

  const zoneOptions = useMemo(
    () =>
      zoneMasterData?.length > 0
        ? zoneMasterData.map((grade) => ({
            label: grade.zone_name,
            value: grade.zone_master_id,
          }))
        : [],
    [zoneMasterData]
  );

  const area = useMemo(
    () =>
      areaMasterData?.length > 0
        ? areaMasterData.map(({ area_name, area_master_id }) => ({
            label: area_name,
            value: area_master_id,
          }))
        : [],
    [areaMasterData]
  );

  const cancelZoneModal = () => {
    setIsZoneModalOpen(false);
    zoneMasterModalForm.resetFields();
  };

  const cancelAreaModal = () => {
    setIsAreaModalOpen(false);
    areaMasterModalForm.resetFields();
  };

  const cancelBeatGradeModal = () => {
    setIsBeatGradeModalOpen(false);
    beatGradeModalForm.resetFields();
  };

  const handleAddZone = useCallback(
    (values) => {
      dispatch(
        actionAddZoneMaster({
          values,
          handleCancel: cancelZoneModal,
          form: beatForm,
        })
      );
    },
    [dispatch, cancelZoneModal, beatForm]
  );

  // add area
  const handleAddArea = useCallback(
    (values) => {
      dispatch(
        actionAddAreaMaster({
          values,
          handleCancel: cancelAreaModal,
          form: beatForm,
        })
      );
    },
    [dispatch, cancelAreaModal, beatForm]
  );

  // add beat grade
  const handleAdd = useCallback(
    (values) => {
      dispatch(
        actionAddBeatGradeMaster({
          values,
          handleCancel: cancelBeatGradeModal,
          form: beatForm,
        })
      );
    },
    [dispatch, cancelBeatGradeModal, beatForm]
  );

  //zone
  const zoneMasterPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={zoneMasterModalForm}
          onFinish={handleAddZone}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="zone_name"
            label="Zone"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input zone!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Zone"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelZoneModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addZoneMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //area
  const areaMasterPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={areaMasterModalForm}
          onFinish={handleAddArea}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="area_name"
            label="Area"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input area!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Area"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelAreaModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addAreaMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //beat grade
  const beatGradePopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={beatGradeModalForm}
          onFinish={handleAdd}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="beat_grade"
            label="Beat Grade"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input beat grade!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Beat Grade"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelBeatGradeModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBeatGradeMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const handleCancel = () => {
    setIsModalOpen(false);
    beatForm.resetFields();
  };

  return (
    <>
      <div className="flex w-full items-center gap-2">
        <Select
          className="ml-2  flex-1"
          showSearch
          placeholder="Search zone"
          allowClear
          value={zone}
          onClear={() => setZone(undefined)}
          onChange={handleSelectZone}
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          loading={getZoneMasterLoader}
          disabled={getZoneMasterLoader}
          options={zoneOptions}
        />

        <Select
          className="ml-2  flex-1"
          showSearch
          placeholder="Search beat grade"
          allowClear
          value={beatGrade}
          onClear={() => setBeatGrade(undefined)}
          onChange={handleSelectBeatGrade}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          loading={getBeatGradeMasterLoader}
          disabled={getBeatGradeMasterLoader}
          options={beatGradeOptions}
        />

        <SearchInput
          handleBlurAndEnter={handleBlurAndEnter}
          value={search}
          onChange={handleSearch}
          placeholder="Search"
        />
        <div
          className="text-[#4567FF] ml-auto tracking-wide  cursor-pointer text-sm"
          onClick={() => {
            if (beatGrade || zone || search) {
              setSearch("");
              setZone(undefined);
              setBeatGrade(undefined);
              dispatch(actionGetBeatMaster());
            }
          }}
        >
          Clear Filters
        </div>
        <br />
      </div>
      <div className="mt-8 mb-3 flex flex-col justify-between gap-y-2">
        <div className="flex gap-x-2 mb-3 justify-between items-center">
          <p className="text-end text-base text-brand">
            {beatSelected && beatSelected?.length} Beat Selected
          </p>
          <div
            className="flex items-center cursor-pointer z-10 text-brand"
            onClick={() => {}}
          >
            <Image
              src={plusSvg}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1
              className="text-base font-popinsRegular ml-1"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                dispatch(actionGetAreaMaster());
              }}
            >
              Add Beat
            </h1>
          </div>
        </div>
        <Table
          scroll={{ y: 200 }}
          virtual
          size="middle"
          rowKey="beat_master_id"
          columns={columns}
          dataSource={BeatMasterData}
          loading={getBeatMasterLoader}
          className=" rounded-md small-table"
          pagination={false}
        />
      </div>

      <Modal
        centered
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={false}
        width={500}
        title=""
        open={isModalOpen}
      >
        <div className="flex items-center">
          <img src={beatmaster} alt="Beat Master" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
            Add Beat Master
          </h1>
        </div>
        <Form
          form={beatForm}
          onFinish={(values) => {
            dispatch(actionAddBeatMaster({ values, handleCancel }));
          }}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="beat_name"
            label="Beat Name"
            className="mb-[12px]"
            rules={[
              {
                required: true,
                message: "Please input beat name!",
              },
            ]}
          >
            <Input placeholder="Enter Beat name" className="rounded-[10px]" />
          </Form.Item>

          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Popover
                placement="bottomRight"
                content={beatGradePopover}
                trigger="click"
                open={isBeatGradeModalOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setIsBeatGradeModalOpen(false);
                    beatGradeModalForm.resetFields();
                  }
                }}
              >
                <div
                  className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                  onClick={() => setIsBeatGradeModalOpen(!isBeatGradeModalOpen)}
                >
                  <Image
                    src={plusSvg}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="text-[14px] font-popinsRegular ml-1">
                    Add Beat Grade
                  </h1>
                </div>
              </Popover>
              <Form.Item
                name="beat_grade_id"
                label="Beat Grade"
                className=""
                rules={[
                  { required: true, message: "Please select beat grade!" },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Beat Grade"
                  optionFilterProp="children"
                  className=""
                  loading={getBeatGradeMasterLoader}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={beatGradeOptions}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Popover
                placement="bottomRight"
                content={zoneMasterPopover}
                trigger="click"
                open={isZoneModalOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setIsZoneModalOpen(false);
                    zoneMasterModalForm.resetFields();
                  }
                }}
              >
                <div
                  className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                  onClick={() => setIsZoneModalOpen(!isZoneModalOpen)}
                >
                  <Image
                    src={plusSvg}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="text-[14px] font-popinsRegular ml-1">
                    Add Zone
                  </h1>
                </div>
              </Popover>
              <Form.Item
                name="zone_id"
                label="Zone"
                className=""
                rules={[{ required: true, message: "Please select zone!" }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Zone"
                  optionFilterProp="children"
                  className=""
                  loading={getZoneMasterLoader}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={zoneOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="city"
            label="City"
            className="mb-[12px]"
            rules={[
              {
                required: true,
                message: "Please input city!",
              },
            ]}
          >
            <Input placeholder="Enter City" className="rounded-[10px]" />
          </Form.Item>

          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Popover
                placement="bottomRight"
                content={areaMasterPopover}
                trigger="click"
                open={isAreaModalOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setIsAreaModalOpen(false);
                    areaMasterModalForm.resetFields();
                  }
                }}
              >
                <div
                  className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                  onClick={() => setIsAreaModalOpen(!isAreaModalOpen)}
                >
                  <Image
                    src={plusSvg}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="text-[14px] font-popinsRegular ml-1">
                    Add Area
                  </h1>
                </div>
              </Popover>
              <Form.Item
                name="area_id"
                label="Area"
                className=""
                rules={[{ required: true, message: "Please select area!" }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Area"
                  optionFilterProp="children"
                  className=""
                  loading={getAreaMasterLoader}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={area}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  loading={addBeatMasterLoader}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const DetailComp = ({
  userSelected,
  setBeatPlanId,
  beatSelected,
  setUserSelected,
  setBeatSelected,
  createModalOpen,
  form,
  setCreateModalOpen,
  id,
  data,
}) => {
  const daysOfWeek = [
    { label: "Monday", value: "M" },
    { label: "Tuesday", value: "T" },
    { label: "Wednesday", value: "W" },
    { label: "Thursday", value: "Th" },
    { label: "Friday", value: "F" },
    { label: "Saturday", value: "S" },
    { label: "Sunday", value: "Su" },
  ];

  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(dayjs());
  const [recurrenceType, setRecurrenceType] = useState(
    RecurrenceTypes.DOES_NOT_REPEAT
  );
  const [recurrenceDays, setRecurrenceDays] = useState([]);
  const [errors, setErrors] = useState({});
  const [recurringEndsType, setRecurringEndsType] = useState(
    RecurringEndTypes.NEVER
  );
  const [endDate, setEndDate] = useState(null);
  const [afterOccurrence, setAfterOccurrence] = useState(1);
  const [recurrenceInterval, setRecurrenceInterval] = useState(1);
  const [repeatUnit, setRepeatUnit] = useState("days");

  useEffect(() => {
    if (id && data) {
      form.setFieldsValue({
        title: data.title,
        start_date: dayjs(data.start_date, "YYYY-MM-DD"),
        recurring_type:
          data.recurring_type === "Does not repeat" ||
          data.recurring_type === "daily"
            ? data.recurring_type
            : "custom",
      });
      setStartDate(dayjs(data.start_date));
      setEndDate(
        data.recurring_ends_on_date
          ? dayjs(data.recurring_ends_on_date)
          : undefined
      );
      setRecurrenceDays(data.recurring_days);
      setRecurrenceInterval(data.recurring_interval);
      setRecurringEndsType(data.recurring_ends_on_type);
      setAfterOccurrence(
        data.recurring_ends_on_occurrences
          ? data.recurring_ends_on_occurrences
          : 1
      );

      if (
        data.recurring_type === "Does not repeat" ||
        data.recurring_type === "daily"
      ) {
        setRecurrenceType(data.recurring_type);
      } else {
        setRecurrenceType("custom");
      }

      if (data.recurring_type === "yearly") {
        setRepeatUnit("years");
      } else if (data.recurring_type === "monthly") {
        setRepeatUnit("months");
      } else if (data.recurring_type === "weekly") {
        setRepeatUnit("weeks");
      } else setRepeatUnit("days");
    }
  }, [id, data]);

  const handleChangeRecurringEndsType = (e) => {
    setRecurringEndsType(e.target.value);
    setErrors({ ...errors, date: "" });
  };

  const handleRecurrenceTypeChange = (value) => {
    setRecurrenceType(value);
    setRepeatUnit("days");
    setRecurrenceInterval(1);
    setRecurringEndsType(RecurringEndTypes.NEVER);
    setErrors({});
  };

  const handleRepeatValueChange = (newValue) => {
    setRecurrenceInterval(newValue);
  };

  const handleRepeatUnitChange = (newUnit) => {
    setRepeatUnit(newUnit);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const clearData = async () => {
    await setCreateModalOpen(false);
    setBeatPlanId("");
    form.resetFields();
    setStartDate(null);
    setRecurrenceInterval(1);
    setRecurrenceType(recurrenceType.DOES_NOT_REPEAT);
    setRecurrenceDays([]);
    setRecurringEndsType(RecurringEndTypes.NEVER);
    setEndDate(null);
    setAfterOccurrence(0);
    setUserSelected([]);
    setBeatSelected([]);
  };

  useEffect(() => {
    if (!createModalOpen) {
      clearData();
    }
  }, [createModalOpen]);

  const handleValidation = () => {
    let errors_list = {};
    let formValid = true;

    if (
      repeatUnit === "weeks" &&
      recurrenceDays &&
      recurrenceDays.length === 0
    ) {
      errors_list.days = "Please select atleast 1 day!";
      formValid = false;
    }

    if (recurringEndsType === "date" && (!endDate || endDate === null)) {
      errors_list.date = "Please select end date!";
      formValid = false;
    }

    setErrors(errors_list);
    return formValid;
  };

  const onFinish = (values) => {
    if (handleValidation()) {
      const req = {
        ...values,
        beat_planning_beat_master_ids: beatSelected,
        beat_planning_compnay_user_ids: userSelected,
        recurring_type:
          recurrenceType === "custom"
            ? repeatUnit === "weeks"
              ? "weekly"
              : repeatUnit === "months"
              ? "monthly"
              : "yearly"
            : recurrenceType,
        recurring_days: repeatUnit === "weeks" ? recurrenceDays : [],
        recurring_ends_on_date:
          recurrenceType === "Does not repeat"
            ? dayjs(startDate).format("YYYY-MM-DD")
            : recurringEndsType === "date"
            ? dayjs(endDate).format("YYYY-MM-DD")
            : "",
        start_date: values.start_date
          ? dayjs(values.start_date).format("YYYY-MM-DD")
          : "",
        recurring_interval: recurrenceInterval,
        recurring_ends_on_type:
          recurrenceType === "Does not repeat" ? "date" : recurringEndsType,
        recurring_ends_on_occurrences:
          recurringEndsType === "occurrences" ? afterOccurrence : 0,
      };

      id
        ? dispatch(
            actionUpdateBeatPlanning({
              req,
              setIsModalOpen: setCreateModalOpen,
              setBeatPlanId,
              id,
            })
          )
        : dispatch(
            actionCreateBeatPlanning({
              req,
              setIsModalOpen: setCreateModalOpen,
            })
          );
    }
  };

  const toggleDay = (day) => {
    if (recurrenceDays.includes(day)) {
      setRecurrenceDays(recurrenceDays.filter((d) => d !== day));
    } else {
      setRecurrenceDays([...recurrenceDays, day]);
    }
  };

  const recurrenceOptions = [
    {
      value: RecurrenceTypes.DOES_NOT_REPEAT,
      label: "Does not repeat",
    },
    { value: RecurrenceTypes.DAILY, label: "Daily" },
    { value: "custom", label: "Custom...." },
  ];

  const RepeatInput = ({
    recurrenceInterval,
    repeatUnit,
    onRepeatValueChange,
    onRepeatUnitChange,
    id,
  }) => {
    const onNumberChange = (value) => {
      const newNumber = parseInt(value || 1, 10);
      if (!Number.isNaN(newNumber)) {
        onRepeatValueChange(newNumber);
      }
    };

    const onSelectChange = (value) => {
      onRepeatUnitChange(value);
    };

    return (
      <Space id={id} size={"middle"} className="ml-2 flex repeat-unit">
        <InputNumber
          min={1}
          value={recurrenceInterval}
          onChange={onNumberChange}
        />
        <Select
          className="repeat-unit-select"
          value={repeatUnit}
          style={{ margin: "0 8px" }}
          onSelect={onSelectChange}
        >
          <Select.Option value="days">days</Select.Option>
          <Select.Option value="weeks">weeks</Select.Option>
          <Select.Option value="months">months</Select.Option>
          <Select.Option value="years">years</Select.Option>
        </Select>
      </Space>
    );
  };

  return (
    <Form
      className="py-2"
      layout="vertical"
      form={form}
      initialValues={{
        start_date: dayjs(),
        recurring_type: "Does not repeat",
        recurring_ends_on_type: "never",
      }}
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Row gutter={[16, 8]}>
        <Col span={10}>
          <Form.Item
            name="title"
            label="Beat Planning Name"
            layout="vertical"
            className="mb-2"
            rules={[
              {
                required: true,
                message: "Please enter beat planning name!",
              },
            ]}
          >
            <Input placeholder="Enter beat name" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={14} />
        <Col span={10}>
          <Form.Item
            className="mb-2"
            name="start_date"
            label="Start Date"
            layout="vertical"
            rules={[
              {
                required: true,
                message: "Please select start date!",
              },
            ]}
          >
            <DatePicker
              minDate={dayjs(new Date())}
              suffixIcon={<Image src={calendar} height={15} alt="calendar" />}
              style={{ width: "100%" }}
              onChange={handleStartDateChange}
              value={startDate}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <p className="opacity-0 py-[5px]">Label</p>
          <Form.Item
            className="mb-2"
            name="recurring_type"
            rules={[
              {
                required: true,
                message: "Please select recurring type!",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select recurrence type"
              allowClear
              onClear={() => setRecurrenceType(undefined)}
              value={recurrenceType}
              onChange={handleRecurrenceTypeChange}
              options={recurrenceOptions}
              disabled={false}
            />
          </Form.Item>
        </Col>

        {recurrenceType === "custom" && (
          <Col span={20}>
            <RepeatInput
              recurrenceInterval={recurrenceInterval}
              repeatUnit={repeatUnit}
              onRepeatValueChange={handleRepeatValueChange}
              onRepeatUnitChange={handleRepeatUnitChange}
            />
          </Col>
        )}

        {repeatUnit === "weeks" && (
          <Col span={14}>
            <div className="repeat-days flex-items-center gap-x-2 mt-2 ml-2">
              <span className="pr-5 font-popinsMedium">Repeat on</span>
              <Space>
                {daysOfWeek.map((day, index) => (
                  <div
                    key={index}
                    className={`${
                      recurrenceDays.includes(day.value)
                        ? "bg-brand text-white"
                        : "bg-[#F2F2F2] text-black"
                    }  cursor-pointer rounded-full  flex items-center justify-center h-7 w-7`}
                    onClick={() => toggleDay(day.value)}
                  >
                    {day.value}
                  </div>
                ))}
              </Space>
            </div>

            {errors.days && (
              <div className="ant-form-item-explain-error !pl-2.5 text-error">
                {errors.days}
              </div>
            )}
          </Col>
        )}

        <Col
          span={24}
          className={`${recurrenceType === "Does not repeat" ? "hidden" : ""} `}
        >
          <Space size={"middle"} className="ml-2 mt-4" id="repeat-unit">
            <span className="font-popinsMedium">Ends</span>

            <Radio.Group
              onChange={handleChangeRecurringEndsType}
              value={recurringEndsType}
            >
              <Radio value={RecurringEndTypes.NEVER}>Never</Radio>
              <Radio value={RecurringEndTypes.DATE}>
                <div className="flex items-center flex-col">
                  <div className="flex items-center">
                    On
                    <DatePicker
                      suffixIcon={false}
                      minDate={dayjs(new Date(startDate))}
                      disabled={recurringEndsType === "date" ? false : true}
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      style={{ marginLeft: 8, width: "100%" }}
                    />
                  </div>
                  {errors.date && (
                    <div className="ant-form-item-explain-error absolute bottom-[-32px] ml-6 !pl-2.5 text-error">
                      {errors.date}
                    </div>
                  )}
                </div>
              </Radio>
              <Radio value={RecurringEndTypes.OCCURRENCES}>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="occurrences-input-wrapper"
                >
                  <span className="mr-3">After</span>

                  <div
                    style={{
                      display: "flex",
                      marginBottom: 0,
                      alignItems: "center",
                    }}
                  >
                    <InputNumber
                      className="!border-none !bg-white  focus:!border-none focus:!shadow-none focus-within:!shadow-none indent-3 focus:!bg-white"
                      disabled={
                        recurringEndsType === "occurrences" ? false : true
                      }
                      min={1}
                      suffix="occurrences"
                      controls={false}
                      value={afterOccurrence}
                      onChange={(e) => setAfterOccurrence(e)}
                      // formatter={(value) => `${value} occurrences`}
                      // parser={(value) => value.replace(" occurrences", "")}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </Radio>
            </Radio.Group>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

const CreateBeatPlanning = ({
  id,
  data,
  setCreateModalOpen,
  setBeatPlanId,
  createModalOpen,
}) => {
  const [form] = Form.useForm();
  const { addBeatPlanningLoader, updateBeatPlanningLoader } = useSelector(
    (state) => state.sales
  );
  const [current, setCurrent] = useState(0);
  const [userSelected, setUserSelected] = useState([]);
  const [beatSelected, setBeatSelected] = useState([]);

  const steps = [
    {
      title: "Employee",
      icon: <Image preview={false} src={EmployeeIcon} alt="employee icon" />,
      content: (
        <EmployeeSelector
          userSelected={userSelected}
          setUserSelected={setUserSelected}
        />
      ),
    },
    {
      title: "Select Beat",
      icon: (
        <Image
          preview={false}
          src={current === 1 || current === 2 ? BeatActiveIcon : BeatIcon}
          alt="beat icon"
        />
      ),
      content: (
        <BeatSelector
          setBeatSelected={setBeatSelected}
          beatSelected={beatSelected}
        />
      ),
    },
    {
      title: "Details",

      icon: (
        <Image
          preview={false}
          src={current === 2 ? DetailActiveIcon : DetailIcon}
          alt="employee icon"
        />
      ),
      content: (
        <DetailComp
          data={data}
          setBeatPlanId={setBeatPlanId}
          id={id}
          setCreateModalOpen={setCreateModalOpen}
          createModalOpen={createModalOpen}
          userSelected={userSelected}
          setUserSelected={setUserSelected}
          beatSelected={beatSelected}
          setBeatSelected={setBeatSelected}
          form={form}
        />
      ),
    },
  ];

  useEffect(() => {
    if (id && data) {
      data.beat_planning_company_users &&
        setUserSelected(
          data.beat_planning_company_users &&
            data.beat_planning_company_users.length > 0 &&
            data.beat_planning_company_users.map((com) => com.id)
        );
      data.beat_planning_beats &&
        setBeatSelected(
          data.beat_planning_beats &&
            data.beat_planning_beats.length > 0 &&
            data.beat_planning_beats.map((com) => com.beat_master_id)
        );
    }
  }, [id, data]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  const handleFinish = () => {
    form.submit();
  };

  return (
    <div className="px-4 py-7 beat-planning-steps">
      <Steps
        responsive={true}
        labelPlacement="vertical"
        current={current}
        items={items}
      />
      <div className="mt-4">{steps[current].content}</div>
      <div className="flex items-center mt-5 justify-between">
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            className="ml-auto root-button min-w-[100px] min-h-[40px]"
            icon={<LeftOutlined />}
            iconPosition="start"
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            className="root-btn next-btn min-w-[100px]  py-5 ml-auto flex-center radius"
            type="primary"
            icon={<RightOutlined />}
            iconPosition="end"
            onClick={() => next()}
            disabled={
              (current === 0 && userSelected && userSelected.length === 0) ||
              (current === 1 && beatSelected && beatSelected.length === 0)
            }
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            loading={addBeatPlanningLoader || updateBeatPlanningLoader}
            type="primary"
            className="root-btn min-w-[100px] py-5 ml-auto flex-center radius"
            onClick={() => {
              handleFinish();
            }}
          >
            Finish
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateBeatPlanning;
