import { createSlice } from "@reduxjs/toolkit";
import {
  actionChangeBeatPlanningStatus,
  actionClearAllBeatPlanning,
  actionCreateBeatPlanning,
  actionDeleteBeatPlanning,
  actionGetAllBeatPlanning,
  actionGetBeatPlanning,
  actionGetBeatPlanningDetail,
  actionUpdateBeatPlanning,
} from "../services/salesService";

const salesSlice = createSlice({
  name: "setting",
  initialState: {
    getBeatPlanningLoader: false,
    getAllBeatPlanningLoader: false,
    addBeatPlanningLoader: false,
    deleteBeatPlanningLoader: false,
    getBeatPlanningDetailLoader: false,
    updateBeatPlanningLoader: false,
    changeStatusBeatPlanningLoader: false,
    beatPlanData: [],
    beatPlanningDetail: {},
    allBeatPlanData: "",
    beatPlansCounts: 0,
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // get beat planning State
      .addCase(actionGetBeatPlanning.pending, (state) => {
        state.getBeatPlanningLoader = true;
      })
      .addCase(actionGetBeatPlanning.fulfilled, (state, action) => {
        state.getBeatPlanningLoader = false;
        state.beatPlanData = action.payload.data;
        state.beatPlansCounts = action.payload.total_records;
      })
      .addCase(actionGetBeatPlanning.rejected, (state) => {
        state.getBeatPlanningLoader = false;
      })

      // get all beat planning
      .addCase(actionGetAllBeatPlanning.pending, (state) => {
        state.getAllBeatPlanningLoader = true;
      })
      .addCase(actionGetAllBeatPlanning.fulfilled, (state, action) => {
        state.getAllBeatPlanningLoader = false;
        state.allBeatPlanData = action.payload.data;
      })
      .addCase(actionGetAllBeatPlanning.rejected, (state) => {
        state.getAllBeatPlanningLoader = false;
      })

      .addCase(actionGetBeatPlanningDetail.pending, (state) => {
        state.getBeatPlanningDetailLoader = true;
      })
      .addCase(actionGetBeatPlanningDetail.fulfilled, (state, action) => {
        state.getBeatPlanningDetailLoader = false;
        state.beatPlanningDetail = action.payload.data;
      })
      .addCase(actionGetBeatPlanningDetail.rejected, (state) => {
        state.getBeatPlanningDetailLoader = false;
      })

      // Clear All beat planning Data
      .addCase(actionClearAllBeatPlanning.fulfilled, (state, action) => {
        state.allBeatPlanData = action.payload;
      })

      // add beat planning state
      .addCase(actionCreateBeatPlanning.pending, (state) => {
        state.addBeatPlanningLoader = true;
      })
      .addCase(actionCreateBeatPlanning.fulfilled, (state, action) => {
        state.addBeatPlanningLoader = false;
        if (action.payload) {
          if (!state.beatPlanData || !Array.isArray(state.beatPlanData)) {
            state.beatPlanData = [action.payload];
          } else {
            state.beatPlanData.unshift(action.payload);
          }
          state.beatPlansCounts = state.beatPlansCounts + 1;
        }
      })
      .addCase(actionCreateBeatPlanning.rejected, (state) => {
        state.addBeatPlanningLoader = false;
      })

      .addCase(actionUpdateBeatPlanning.pending, (state) => {
        state.updateBeatPlanningLoader = true;
      })
      .addCase(actionUpdateBeatPlanning.fulfilled, (state, action) => {
        state.updateBeatPlanningLoader = false;
      })
      .addCase(actionUpdateBeatPlanning.rejected, (state) => {
        state.updateBeatPlanningLoader = false;
      })

      .addCase(actionChangeBeatPlanningStatus.pending, (state) => {
        state.changeStatusBeatPlanningLoader = true;
      })
      .addCase(actionChangeBeatPlanningStatus.fulfilled, (state, action) => {
        state.changeStatusBeatPlanningLoader = false;

        state.beatPlanData = state.beatPlanData.map((beat) =>
          beat.beat_planning_id === action.payload.id
            ? { ...beat, status: action.payload.newStatus }
            : beat
        );
      })
      .addCase(actionChangeBeatPlanningStatus.rejected, (state) => {
        state.changeStatusBeatPlanningLoader = false;
      });
  },
});

export default salesSlice.reducer;
