import { Button, Form, Image, Input } from "antd";
import React, { useEffect } from "react";
import { HiOutlineLockClosed } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/img/back.svg";
import { actionResetPassword } from "../../store/services/authService";

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetStatus = useSelector((state) => state.auth);
  const token = useLocation();
  const tokenSubmit = token.search.split("=")[1];

  useEffect(() => {
    if (!tokenSubmit) {
      navigate("/login");
    }
  }, [tokenSubmit, navigate]);

  const onFinish = (values) => {
    const request = {
      password: values.password,
      token: tokenSubmit,
    };
    dispatch(actionResetPassword({ request, navigate }));
  };

  return (
    <div className="register-container ">
      <div className="title mb-4 pt-20 flex flex-col justify-center items-center gap-y-2.5">
        <p className="xl:text-[30px] lg:text-[26px] md:text-2xl text-xl font-semibold text-secondary font-popins">
          Reset your Password
        </p>
        <p className="lg:text-[19px] text-center text-base text-gray-600">
          Enter the email address you used to Register with.
        </p>
      </div>
      <Form
        name="normal_login"
        className="login-form w-full pt-3"
        onFinish={onFinish}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value.length < 8) {
                    return Promise.reject(
                      "Please enter a valid 8-digit password!"
                    );
                  }

                  // You can add additional conditions if needed

                  return Promise.resolve();
                },
              }),
          ]}
        >
          <Input.Password
            placeholder="Password"
            className="indent-5"
            prefix={
              <HiOutlineLockClosed
                color="#4567ff"
                className="h-[18px] w-[18px] site-form-item-icon font-bold"
              />
            }
            type={"text"}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className="filled-s-button mt-3 md:text-base text-sm  border-none rounded-[10px]  min-h-[45px]"
            block
            htmlType="submit"
            loading={resetStatus?.resetPasswordLoader}
          >
            Save
          </Button>
        </Form.Item>
        <Form.Item className=" text-center">
          <Link
            to="/login"
            className="flex items-center justify-center gap-x-2 "
          >
            <Image
              src={back}
              alt="back"
              preview={false}
              className="text-primary/90 "
            />
            <p className="text-primary hover:text-primary/90 md:text-[18px] text-[15px]">
              Back to sign in
            </p>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ResetPassword;
