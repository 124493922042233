import { createSlice } from "@reduxjs/toolkit";
import {
  actionContinueToLogin,
  actionContinueWithGoogle,
  actionLogin,
  actionLoginOTP,
  actionRegister,
  actionVerifyOTPEmail,
  actionVerifyOTPMobile,
  actionVerifyOtpLogin,
  actionForgotPassword,
  actionResetPassword,
  actionRegisterCompany,
  authMe,
  actionAddBusinessHours,
  actionChangeMobile,
  actionCreateAccount,
  actionGetTimezones,
  actionInviteUser,
  actionClearInviteMessages,
  actionAcceptInvitation,
  actionCheckAuthStatus,
  actionRejectInvitation,
} from "../services/authService";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: "",
    timezones: "",
    userLoader: false,
    registerLoader: false,
    loginLoader: false,
    loginOtpLoader: false,
    verifyEmailOtpLoader: false,
    verifyPhoneOtpLoader: false,
    verifyLoginLoader: false,
    forgotPasswordLoader: false,
    continueLoader: false,
    googleLoader: false,
    registerCompanyLoader: false,
    addCompanyLoader: false,
    checkAuthStatusError: "",
    resetPasswordLoader: false,
    changeMobileLoader: false,
    createAccountLoader: false,
    timezoneLoader: false,
    inviteSuccesses: [],
    InviteErrors: [],
    acceptInvitationLoader: false,
    rejectInvitationLoader: false,
    checkAuthStatusLoader: false,
    currentCompanyUser: {},
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // Register State
      .addCase(actionRegister.pending, (state) => {
        state.registerLoader = true;
      })
      .addCase(actionRegister.fulfilled, (state, action) => {
        // state.user = action.payload;
        state.registerLoader = false;
      })
      .addCase(actionRegister.rejected, (state) => {
        state.registerLoader = false;
      })

      // Auth me State
      .addCase(authMe.pending, (state) => {
        state.userLoader = true;
      })
      .addCase(authMe.fulfilled, (state, action) => {
        state.user = action.payload;
        state.userLoader = false;
        state.currentCompanyUser =
          action.payload.company_users &&
          action.payload.company_users.length > 0
            ? action.payload.company_users.find(
                (company) => company.status === "ACTIVE"
              )
            : null;
      })
      .addCase(authMe.rejected, (state) => {
        state.userLoader = false;
      })

      // Login State
      .addCase(actionLogin.pending, (state) => {
        state.loginLoader = true;
      })
      .addCase(actionLogin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loginLoader = false;
      })
      .addCase(actionLogin.rejected, (state) => {
        state.loginLoader = false;
      })

      // Login with OTP State
      .addCase(actionLoginOTP.pending, (state) => {
        state.loginOtpLoader = true;
      })
      .addCase(actionLoginOTP.fulfilled, (state, action) => {
        state.loginOtpLoader = false;
      })
      .addCase(actionLoginOTP.rejected, (state) => {
        state.loginOtpLoader = false;
      })

      // Login OTP Via Email State
      .addCase(actionVerifyOTPEmail.pending, (state) => {
        state.verifyEmailOtpLoader = true;
      })
      .addCase(actionVerifyOTPEmail.fulfilled, (state, action) => {
        state.verifyEmailOtpLoader = false;
      })
      .addCase(actionVerifyOTPEmail.rejected, (state) => {
        state.verifyEmailOtpLoader = false;
      })

      // Login OTP Mobile State
      .addCase(actionVerifyOTPMobile.pending, (state) => {
        state.verifyPhoneOtpLoader = true;
      })
      .addCase(actionVerifyOTPMobile.fulfilled, (state, action) => {
        state.verifyPhoneOtpLoader = false;
      })
      .addCase(actionVerifyOTPMobile.rejected, (state) => {
        state.verifyPhoneOtpLoader = false;
      })
      // Verify  OTP via Login  State
      .addCase(actionVerifyOtpLogin.pending, (state) => {
        state.verifyLoginLoader = true;
      })
      .addCase(actionVerifyOtpLogin.fulfilled, (state, action) => {
        state.verifyLoginLoader = false;
      })
      .addCase(actionVerifyOtpLogin.rejected, (state) => {
        state.verifyLoginLoader = false;
      })

      // Continue To Api State
      .addCase(actionContinueToLogin.pending, (state) => {
        state.continueLoader = true;
      })
      .addCase(actionContinueToLogin.fulfilled, (state, action) => {
        state.continueLoader = false;
      })
      .addCase(actionContinueToLogin.rejected, (state) => {
        state.continueLoader = false;
      })

      //forgot password
      .addCase(actionForgotPassword.pending, (state) => {
        state.forgotPasswordLoader = true;
      })
      .addCase(actionForgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoader = false;
      })
      .addCase(actionForgotPassword.rejected, (state) => {
        state.forgotPasswordLoader = false;
      })

      //resetpassword
      .addCase(actionResetPassword.pending, (state) => {
        state.resetPasswordLoader = true;
      })
      .addCase(actionResetPassword.fulfilled, (state, action) => {
        state.resetPasswordLoader = false;
      })
      .addCase(actionResetPassword.rejected, (state) => {
        state.resetPasswordLoader = false;
      })

      //change-mobile
      .addCase(actionChangeMobile.pending, (state) => {
        state.changeMobileLoader = true;
      })
      .addCase(actionChangeMobile.fulfilled, (state, action) => {
        state.changeMobileLoader = false;
      })
      .addCase(actionChangeMobile.rejected, (state) => {
        state.changeMobileLoader = false;
      })

      // Continue To Google Api State
      .addCase(actionContinueWithGoogle.pending, (state) => {
        state.googleLoader = true;
      })
      .addCase(actionContinueWithGoogle.fulfilled, (state, action) => {
        state.googleLoader = false;
      })
      .addCase(actionContinueWithGoogle.rejected, (state) => {
        state.googleLoader = false;
      })

      // Continue To Google Api State
      .addCase(actionRegisterCompany.pending, (state) => {
        state.registerCompanyLoader = true;
      })
      .addCase(actionRegisterCompany.fulfilled, (state, action) => {
        state.registerCompanyLoader = false;
      })
      .addCase(actionRegisterCompany.rejected, (state) => {
        state.registerCompanyLoader = false;
      })

      // Add Company
      .addCase(actionAddBusinessHours.pending, (state) => {
        state.addCompanyLoader = true;
      })
      .addCase(actionAddBusinessHours.fulfilled, (state, action) => {
        state.addCompanyLoader = false;
      })
      .addCase(actionAddBusinessHours.rejected, (state) => {
        state.addCompanyLoader = false;
      })
      // Create Account State
      .addCase(actionCreateAccount.pending, (state) => {
        state.createAccountLoader = true;
      })
      .addCase(actionCreateAccount.fulfilled, (state, action) => {
        state.createAccountLoader = false;
      })
      .addCase(actionCreateAccount.rejected, (state) => {
        state.createAccountLoader = false;
      })

      .addCase(actionGetTimezones.pending, (state) => {
        state.timezoneLoader = true;
      })
      .addCase(actionGetTimezones.fulfilled, (state, action) => {
        state.timezoneLoader = false;
        state.timezones = action.payload.data;
      })
      .addCase(actionGetTimezones.rejected, (state) => {
        state.timezoneLoader = false;
      })

      // Invite User
      .addCase(actionInviteUser.pending, (state) => {
        state.inviteUserLoader = true;
      })
      .addCase(actionInviteUser.fulfilled, (state, action) => {
        state.inviteUserLoader = false;
        state.inviteSuccesses = action.payload.successes || [];
        state.InviteErrors = action.payload.errors || [];
      })
      .addCase(actionInviteUser.rejected, (state, action) => {
        state.inviteUserLoader = false;
      })
      .addCase(actionClearInviteMessages.fulfilled, (state, action) => {
        state.inviteSuccesses = [];
        state.InviteErrors = [];
      })

      //check auth status before accept invitation
      .addCase(actionCheckAuthStatus.pending, (state, action) => {
        state.checkAuthStatusLoader = true;
      })
      .addCase(actionCheckAuthStatus.fulfilled, (state, action) => {
        state.checkAuthStatusLoader = false;
        state.checkAuthStatusError = action.payload;
      })
      .addCase(actionCheckAuthStatus.rejected, (state, action) => {
        state.checkAuthStatusLoader = false;
        state.checkAuthStatusError = action.payload;
      })

      //accept invitation
      .addCase(actionAcceptInvitation.pending, (state) => {
        state.acceptInvitationLoader = true;
      })
      .addCase(actionAcceptInvitation.fulfilled, (state, action) => {
        state.acceptInvitationLoader = false;
      })
      .addCase(actionAcceptInvitation.rejected, (state) => {
        state.acceptInvitationLoader = false;
      })

      //reject invitation
      .addCase(actionRejectInvitation.pending, (state) => {
        state.rejectInvitationLoader = true;
      })
      .addCase(actionRejectInvitation.fulfilled, (state, action) => {
        state.rejectInvitationLoader = false;
      })
      .addCase(actionRejectInvitation.rejected, (state) => {
        state.rejectInvitationLoader = false;
      });
  },
});

export default authSlice.reducer;
